import styled from 'styled-components';
import { WizardBody, WizardItem } from '@jotforminc/wizard-shared';

export const ScWizardItem = styled(WizardItem)`
  &.jfWizard-item {
    margin: 30px 0 0;

    .jfWizard-header-subtitle{
      display: flex;
      justify-content: center;
      font-size: 18px;
      line-height: 26px;
      margin-top: 22px;
    }

    &.jfWizard-gutter.withMaxWidth{
      width: 100%;
      max-width: unset;
    }

    & .createFlowModal-products, 
    & .isEmbeddedMainStep{
      width: 100%;
      max-width: 830px;
      margin: auto;
    }

    @media screen and (max-height: 620px) {
      margin: 0;
    }

    &#createFlowModal-wrapper {

      &+ .jfWizard-close{
        z-index: 999;
      }

      @media screen and (max-width: 880px) {
        width: calc(100% - 60px);
        margin: 0 30px;
      }

      & > .jfWizard-body{
        gap: 10px;
        margin: 50px 0 0;
      }

      & .jfWizard-header{
        max-width: 830px;
        white-space: wrap;
        margin-left: auto;
        margin-right: auto;
      }

      &.isHidden {
        margin: 0;
        & > .jfWizard-header,
        .createFlowModal-products {
          display: none;
          margin: 0;
        }

        & + .jfWizard-close{
          display: none;
        }

        & > .jfWizard-body{
          margin: 0;
         
          .jfWizard-header-text{
            display: block;

            &.jfWizard-header-subtitle{
              display: flex;
              justify-content: center;
              font-size: 18px;
              line-height: 24px;
              margin-top: 22px;
            }
          }
        }
      }
    }
  }
`;

export const ScWizardBody = styled(WizardBody)`
  .productItem {
    &.form .productIconWrapper.isSelected { background: #FF6100; border-color: #FF6100;}
    &.app .productIconWrapper.isSelected { background: #9C4DD3; border-color: #9C4DD3;}
    &.e-signature .productIconWrapper.isSelected { background: #7BB60F; border-color: #7BB60F;}
    &.table .productIconWrapper.isSelected { background: #049E38; border-color: #049E38;}
    &.approval .productIconWrapper.isSelected { background: #007862; border-color: #007862;}
    &.report .productIconWrapper.isSelected { background: #5369AB; border-color:#5369AB;}

    &-list {
      &.createFlowModal-products{
        @media screen and (max-width: 880px) {
          flex-wrap: wrap;
          max-width: 408px !important;
          justify-content: space-between !important;
        }

        @media screen and (max-width: 468px) {
          gap: 8px;
          justify-content: center !important;
        }
      }
      & > li {
        flex-basis: auto;
        
        @media screen and (max-width: 880px) {
          flex-basis: calc((100% - 32px) / 3) !important;
          max-width: 125px;
        }

        @media screen and (max-width: 468px) {
          flex-basis: calc((100% - 16px) / 3) !important;

          .productItem{
            width: 100%;
          }
        }
      }
    } 

    .productIconWrapper{
      background-color: #E3E5F5;
      border-color: #E3E5F5;
    }

    &:hover, &:focus{
      &.form .productIconWrapper,
      &.app .productIconWrapper,
      &.e-signature .productIconWrapper,
      &.table .productIconWrapper,
      &.approval .productIconWrapper,
      &.report .productIconWrapper{
        border-color: #09F;
      }
    }

    &-list{
      display: flex;
      gap: 16px;
      align-items: flex-start;
      justify-content: center;
    }
  }

  .productContentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    line-height: 26px;

    @media screen and (max-width: 468px) {
      align-items: flex-start;
      text-align: left;
    }
  }

  .productIconWrapper {
    width: 125px;
    height: 116px;
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
    background-color: #DADEF3;
    border-radius: 8px;
    border: 2px solid #C8CEED;
    padding: 14px 12px;
    gap: 8px;

    @media screen and (max-width: 468px) {
      width:100%
    }

    &.isSelected .productName {
      color: #FFFFFF;
      font-weight: 700;
    }
  }

  .productIcon {
    max-width: 40px;
    width: 10vw;

    @media screen and (max-width: 768px) {
      width: 80px;
    }

    @media screen and (max-width: 468px) {
      width: 60px;
    }
  }

  .productName {
    font-size: 18px;
    text-align: center;
    color: #343C6A;
    font-family: Circular;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    @media screen and (max-width: 468px) {
      font-size: 3.7vw;
    }
  }

  #createFlowModal-MainStep {
    width: 100%;
    max-width: 100%;
    min-height: 500px;
    margin: 0;
    transition: none;
    
    & > *:not(.isCreateNewReportWizard) {
      overflow: hidden;
    }

    .jfWizard{
      width: 100%;
      scrollbar-width: none;

      &-list-item-icon-wrapper {
        @media (min-width: 880px) {
          padding-top: 62%;
        }
      }
    }

    .jfWizard:not(.isEmbeddedMainStep){
      .newDocWizard-main{
        .jfWizard-list-item[aria-label="Use template"],
        .jfWizard-list-item[aria-label="Upload Document"]{
          .jfWizard-list-item-icon-wrapper{
            padding: 102% 5% 5% !important;
          }
        }
      }
    }

    .isEmbeddedMainStep {
      position: relative;
      margin: 0;
      min-height: unset;
      transition: none;
      justify-content: flex-start;
      align-items: flex-start;

      .jfWizard-item:not(.isImportForm),.jfWizard-gutter.withMaxWidth.isMain{
        width: 100%;
        min-height: unset;
        max-width: 830px;
        transition: none;
        flex: 0 0 auto;

        .jfWizard-body {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          flex-grow: 0;
          margin: 40px 0 0;
          justify-content: center;
          width: 100%;
          max-width: unset;
          transition: none;
          max-height: 67vh;
          min-height: auto;
          overflow-y: scroll;
          overflow-x: hidden;
          scrollbar-width: none;
          overscroll-behavior: auto;
        
          .jfWizard-list{
            gap: 20px;
            width: 100%;
            max-width: unset;
            justify-content: flex-start;
            transition: none;
            flex-wrap: nowrap;

            @media (max-width: 880px) {
              display: flex;
              gap: 16px;
              flex-direction: row;
              flex-wrap: wrap;
            }

            &-item{
              border-radius: 6px;
              align-items: center;
              display: flex;
              flex-direction: row;
              background-color: var(--core-navy-50, #E3E5F5);
              margin: 0;
              max-width: unset;
              transition: none;
              min-height: 191px;
              position: relative;
              transform: none;

              &:hover, &:focus{
                border-color: #09F;
              }

              & .jfWizard-list-item-wrapper {
                flex-basis: auto !important;
                height: 100%;


                & .animationArea{
                  top: 0 !important;
                  left: 0 !important;
                }
              }
            }

            &-item-wrapper {
              border-radius: 6px;
              align-items: center;
              display: flex;
              flex-direction: row;
              background-color: var(--core-navy-50, #E3E5F5);
              margin: 0;
              max-width: unset;
              transition: none;
              min-height: 191px;
              position: relative;
              transform: none;

              &.js-forCreateWithAi {
                background: #E6C9FB;

                & .jfWizard-list-item-subtext{
                  color: white;
                }
              }

              @media (max-width: 880px) {
                flex-basis: calc((100% - 16px) / 2);
                flex-wrap: wrap;
                padding: 0;
              }

              &:hover, &:focus{
                .jfWizard-list-item{
                  border-color: #09F;
                }
              }

              .jfWizard-list-item{
                border-radius: 6px;
                position: static;
                max-width: unset;
                width: 100%;
                height: 100%;
                padding: 20px;
                gap: 16px;
                margin: 0;
                transition: none;
                background-color: transparent !important;
                flex-direction: column !important;
                align-items: flex-start !important;
                border: 2.5px solid transparent;

                &.isLoading{
                  .jfWizard-item-loader{
                    border-radius: 6px;
                  }
                }

                &:hover, &:focus, &.active, &.isLoading{
                  transform: unset;

                  .jfWizard-list-item-text{
                    color: inherit;
                  }

                  & .jfWizard-list-item-icon{
                    border: unset;
                    box-shadow: unset;
                  }
                }
                
                &-icon-wrapper{
                  flex-grow: 0;
                  padding: 30px 0;
                  align-items: flex-start;
                  justify-content: flex-start;
                  transition: none;
                  position: static;

                  & > .jfWizard-list-item-icon {
                      top: 8px;
                      right: 8px;

                      @media (max-width: 880px) {
                        top: 16px;
                        right: 16px;
                      }
                    }
                  
                  .jfWizard-list-item-icon{
                    background-color: unset;
                    align-items: flex-start;
                    justify-content: flex-start;
                    padding: 0;
                    transition: none;
                    border: none;
                    top: 16px;
                    left: 16px;
                    background-image: none;

                    & > span {
                      top: -8px;
                      right: 24px;

                      @media (max-width: 880px) {
                        top: 0;
                        right: 32px;
                      }
                    }

                    &-svg{
                      transition: none;
                      min-width: 60px;
                      min-height: 60px;
                      width: 60px;
                      height: 60px;
                    }
                  }
                }

                &-text-wrapper{
                  flex-grow: 0;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  gap: 8px;

                  .jfWizard-list-item-text, .jfWizard-list-item-subtext{
                    text-align: start;
                    margin: 0;
                  }
                }
              }

              .jfWizard-list-item[data-test-id="cw-start-from-scratch"]{
                & .jfWizard-list-item-icon-svg{
                  min-height: 48px;
                  min-width: 48px;
                  height: 48px;
                  width: 48px;
                }
              }
            }
          }
          .jfWizard-list{
            &.forReportTypes,
            &.forSheetTypes{
              & .jfWizard-list-item-icon-svg{
                min-height: 60px;
                min-width: 90px;
                height: 60px;
                width: 90px;
              }
            }
          }
        }
      }

      .jfWizard-close {
        display: none;
      }
    }
  }

  .moreThanFourItem > .jfWizard-body > .jfWizard-list{
    flex-wrap: wrap !important;

    & > .jfWizard-list-item{
      flex-basis: calc((100% - 40px) / 3) !important;

      @media (max-width: 880px) {
        flex-basis: calc((100% - 32px) / 3) !important;
        flex-wrap: wrap;
        padding: 0;
      }

      @media (max-width: 768px) {
        flex-basis: calc((100% - 16px) / 2) !important;
      }
    }

    .jfWizard-list-item-wrapper{
      flex-basis: calc((100% - 40px) / 3) !important;

      @media (max-width: 880px) {
        flex-basis: calc((100% - 32px) / 3) !important;
        flex-wrap: wrap;
        padding: 0;
      }

      @media (max-width: 768px) {
        flex-basis: calc((100% - 16px) / 2) !important;
      }
    }
  }

  .moreThanSevenItem .jfWizard-body .jfWizard-list{
    flex-wrap: wrap !important;

    & > .jfWizard-list-item{
      flex-basis: calc((100% - 60px) / 4) !important;

      @media (min-width: 880px) {
        min-height: 225px;
      }

      @media (max-width: 880px) {
        flex-basis: calc((100% - 32px) / 3) !important;
        flex-wrap: wrap;
        padding: 0;
      }

      @media (max-width: 768px) {
        flex-basis: calc((100% - 16px) / 2) !important;
        height: 191px;
      }

      @media (max-width: 468px) {
        height: 215px;
      }
    }

    .jfWizard-list-item-wrapper{
      flex-basis: calc((100% - 60px) / 4) !important;

      @media (min-width: 880px) {
        min-height: 225px;
      }

      @media (max-width: 880px) {
        flex-basis: calc((100% - 32px) / 3) !important;
        flex-wrap: wrap;
        padding: 0;
      }

      @media (max-width: 768px) {
        flex-basis: calc((100% - 16px) / 2) !important;
        height: 191px;
      }

      @media (max-width: 468px) {
        height: 215px;
      }
    }
  }
`;
