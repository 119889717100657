import React, { useState } from 'react';
import {
  shape, string, func, bool, arrayOf
} from 'prop-types';
import qs from 'querystring';
import { t } from '@jotforminc/translation';
import { handleCustomNavigation } from '@jotforminc/utils';
import { canCreateTeam } from '@jotforminc/enterprise-components';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { IconChevronRight } from '@jotforminc/svg-icons';

import classNames from 'classnames';
import {
  appConfigPropsTypes, getDefaultNavItems
} from './navigationConstants';
import NavigationItem from './NavigationItem';
import TeamSection from './TeamSection';

const hasNewBadge = () => {
  return false;
};

// eslint-disable-next-line complexity
const Navigation = ({
  form = {},
  forms = [],
  appID = '',
  appList = [],
  formIDs = [],
  summary = {},
  hideIfEmpty = false,
  showBackToMyForms = true,
  visibleNavigationApps = [],
  backToMyFormsLink = '/myforms',
  backToMyFormsText = 'Go Back to My Forms',
  onMyformsClick = f => f,
  logDiscoverabilityActions = f => f,
  toggleNavigation = f => f,
  isAppPickerSideModeActive = false,
  teamID = '',
  teams = [],
  openCreateTeamModal = () => {},
  showTeamSection = false,
  isTeamPage = false,
  isInListings = false,
  isTeamCreationDisabled = false,
  isTeamBrowsingDisabled = false,
  createForm = f => f,
  user,
  appConfig,
  appName: currentAppName
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingApp, setLoadingApp] = useState('');

  const isNotFormOwner = form?.owner && user?.username && form.owner !== user.username;
  const appConfigs = { ...getDefaultNavItems(true), [currentAppName]: appConfig };
  const apps = appList.indexOf(currentAppName) !== -1 || currentAppName === 'myTeams' ? appList : [...appList, currentAppName];
  const hideBackButton = window.JOTFORM_ENV === 'ENTERPRISE' && visibleNavigationApps.length > 0 && visibleNavigationApps.indexOf('toMyForms') === -1;
  const userAccountType = user?.accountType || user?.account_type;
  const hideTeamSection = teams.length === 0 && !canCreateTeam(isTeamCreationDisabled, userAccountType);
  const isJotformAdmin = !isEnterprise() && (userAccountType === 'ADMIN' || userAccountType?.name === 'ADMIN');
  const isTeamSectionVisible = showTeamSection && !hideTeamSection;
  const isHideBetaEnabled = user?.hideBetaBadges === '1';
  const noRedirectedApps = ['myTeams', 'myWorkspace'];
  const preventWorkspaceRedirect = !isEnterprise() && user && !!user.allowMixedListing && !isJotformAdmin;

  if (!teamID && isNotFormOwner && currentAppName !== 'portal') {
    // For now, don't show app builder on navigation for subusers
    const portalIndex = apps.indexOf('portal');
    if (portalIndex !== -1) {
      apps.splice(portalIndex, 1);
    }
  }

  if (isHideBetaEnabled) {
    const myPagesIndex = apps.indexOf('myPages');
    if (myPagesIndex !== -1) {
      apps.splice(myPagesIndex, 1);
    }
  }

  const setLoadingState = app => state => {
    setLoading(state);
    setLoadingApp(state ? app : '');
  };

  const handleBackToMyFormsClick = e => {
    const parameters = qs.stringify({ ...(preventWorkspaceRedirect ? { preventWorkspaceRedirect: true } : {}) });
    e.preventDefault();
    if (onMyformsClick) {
      onMyformsClick({ queryParams: parameters });
    } else {
      const hasParameter = backToMyFormsLink && backToMyFormsLink.indexOf('?') > -1 ? '&' : '?';
      if (backToMyFormsLink.includes('myagents') || window.location.pathname.includes('/agent/build')) {
        handleCustomNavigation(`${backToMyFormsLink}${parameters ? `${hasParameter}${parameters}` : ''}`, '_blank', true);
      } else {
        handleCustomNavigation(`${backToMyFormsLink}${parameters ? `${hasParameter}${parameters}` : ''}`, '_blank');
      }
    }

    logDiscoverabilityActions({
      action: 'click',
      target: 'goBackToMyForms'
    });
  };

  return (
    <div
      className={classNames('jNewHeader-appBox', { isShort: apps.length < 3, withTeamSection: isTeamSectionVisible })}
      data-app-count={apps.length}
    >
      <ul className="jNewHeader-appBoxContent" role="region" aria-label={t('Product Selector')}>
        {apps.map(app => {
          return (
            <NavigationItem
              app={app}
              key={app}
              user={user}
              appID={appID}
              forms={forms}
              formIDs={formIDs}
              disabled={loading}
              summary={summary[app]}
              activeFormID={form.id}
              agentID={form.aiAgentProps?.agent_id}
              branding21={true}
              hideIfEmpty={hideIfEmpty}
              appConfig={appConfigs[app]}
              currentAppName={currentAppName}
              title={t((teamID && appConfigs[app].titleForTeamContext) || appConfigs[app].title)}
              explanation={isAppPickerSideModeActive ? t(appConfigs[app].explanation) : null}
              newBadge={hasNewBadge()}
              loading={loadingApp === appConfigs[app].title}
              setLoading={setLoadingState(appConfigs[app].title)}
              logDiscoverabilityActions={logDiscoverabilityActions}
              toggleNavigation={toggleNavigation}
              redirectionParameters={{
                ...(isInListings && teamID && { selectedFolder: teamID }),
                ...(app === 'portal' && teamID && { teamID }),
                ...(preventWorkspaceRedirect && isInListings && noRedirectedApps.indexOf(app) === -1 && { preventWorkspaceRedirect: true })
              }}
              createForm={createForm}
            />
          );
        })}
        { !hideBackButton && showBackToMyForms && (
        <a
          target="_blank"
          className="toMyForms"
          href={backToMyFormsLink}
          onClick={handleBackToMyFormsClick}
          data-testid="hc-back-to-my-forms"
        >
          {t(backToMyFormsText)}
          <div className="appIcon">
            <IconChevronRight />
          </div>
        </a>
        ) }
      </ul>
      {isTeamSectionVisible
      && (
      <TeamSection
        user={user}
        openCreateTeamModal={openCreateTeamModal}
        teams={teams}
        currentTeamID={teamID}
        isTeamPage={isTeamPage}
        isTeamCreationDisabled={isTeamCreationDisabled}
        isTeamBrowsingDisabled={isTeamBrowsingDisabled}
      />
      )}
    </div>
  );
};

Navigation.propTypes = {
  form: shape({
    id: string,
    owner: string
  }),
  forms: arrayOf(shape({})),
  user: shape({
    username: string,
    account_type: shape({
      name: string
    })
  }).isRequired,
  appID: string,
  hideIfEmpty: bool,
  summary: shape({}),
  showBackToMyForms: bool,
  backToMyFormsLink: string,
  backToMyFormsText: string,
  formIDs: arrayOf(string),
  appName: string.isRequired,
  appList: arrayOf(string),
  visibleNavigationApps: arrayOf(string),
  appConfig: appConfigPropsTypes.isRequired,
  onMyformsClick: func,
  logDiscoverabilityActions: func,
  toggleNavigation: func,
  isAppPickerSideModeActive: bool,
  teamID: string,
  teams: arrayOf(shape({})),
  openCreateTeamModal: func,
  showTeamSection: bool,
  isTeamPage: bool,
  isInListings: bool,
  isTeamCreationDisabled: bool,
  isTeamBrowsingDisabled: bool,
  createForm: func
};

export default Navigation;
