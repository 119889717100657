import { createRoot } from 'react-dom/client';
import { Wizard } from '@jotforminc/wizard-shared';
import React from 'react';
import MixAssetModal from '.';

const root = document.getElementById('create-asset-modal-container');

export const openMixAssetModal = ({
  isMobileDevice,
  isSideBar,
  userFormCountLimit,
  user,
  hasUserForms,
  isMixPageUser,
  teamPermissions,
  folderID,
  isAssetsFolderActive,
  userPermissions,
  isTeamAssetCreation
}) => {
  const rootNode = createRoot(root);

  return new Promise(resolve => {
    const handleClose = () => {
      resolve({ closed: true });
      rootNode.unmount();
    };

    const handleProductSelect = productType => {
      resolve({ closed: false, productType, callbackFunc: () => {} });
    };

    rootNode.render(<Wizard
      usePortal
      targetContainer="#create-asset-modal-container"
      Navigation={{ MixAssetModal }}
      DefaultView='MixAssetModal'
      isMobileDevice={isMobileDevice}
      isSideBar={isSideBar}
      userFormCountLimit={userFormCountLimit}
      initialVisibilityState={true}
      onHide={handleClose}
      user={user}
      teamPermissions={teamPermissions}
      userPermissions={userPermissions}
      isTeamAssetCreation={isTeamAssetCreation}
      selectedFolder={folderID}
      isAssetsFolderActive={isAssetsFolderActive}
      hasUserForms={hasUserForms}
      onProductSelect={handleProductSelect}
      isMixPageUser={isMixPageUser}
    />);
  });
};
