import {
  call, delay, select, spawn, all
} from 'redux-saga/effects';

import * as API from '../../api';
import { SELECTORS } from '../../store/selectors';
import { ACTION_TYPES } from '../../store/actionTypes';
import { registerUniqueAction } from '../utils';
import {
  ASSET_TYPES, FEATURE_LIST, FOLDER_TYPES, LISTING_TYPES
} from '../../constants';

import { openCreateNewBoardModal } from '../../wizards/CreateNewBoardModal';
import WatchmanRecorder from '../../utils/WatchmanRecorder';
import { getAssetTypeFromListingType } from '../../utils';
import { handleFetchAllForms } from './form';

const apiAction = new API.CommonActions('board');

export function* handleCreateWizard({
  toFolder, teamProperties, folderID: folderId, isSideBar
}) {
  const [
    user,
    assetFilter,
    currentPage,
    isAllAssetFilterSelected
  ] = yield all([
    select(SELECTORS.getUser),
    select(SELECTORS.getAssetFilter),
    select(SELECTORS.getCurrentPage),
    select(SELECTORS.getIsAllAssetFilterTypesSelected)
  ]);
  const isAssetsFolderActive = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT));
  const teamID = toFolder ? teamProperties?.id : yield select(SELECTORS.getCurrentTeamID);
  const folderID = toFolder ? folderId : yield select(SELECTORS.getSelectedFolderIDForCreation);

  try {
    let forms;
    if (teamID) {
      forms = yield call(API.fetchTeamAssets, {
        selectedFolder: teamID,
        type: getAssetTypeFromListingType(LISTING_TYPES.FORM),
        disableJotFormNormalize: false,
        isAllAssetFilterSelected,
        currentPage,
        assetFilter,
        folderType: FOLDER_TYPES.TEAM,
        skipV2: true
      });
    } else {
      forms = yield call(handleFetchAllForms);
    }

    yield call(openCreateNewBoardModal, {
      user,
      creationLogger: actionEvent => {
        WatchmanRecorder.trackEvent('click', `create-board-button${isSideBar ? '-sideBar' : ''}-${actionEvent}-board-created`, 'boards', true);
      },
      teamID,
      ...(isAssetsFolderActive ? { folderID } : {}),
      forms: forms.filter(form => !['DELETED', 'PURGED'].includes(form.status))
    });
  } catch (e) {
    console.log('error:', e);
  }
}

export function* handleBoardFavorite({ id }) {
  yield delay(500);
  const sheet = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.BOARD));
  const { favorite } = sheet;
  if (!sheet) {
    return false;
  }
  const newItem = { favorite: favorite };
  yield call(apiAction.updateItem, id, newItem);
}

export function* rootBoardFlow() {
  yield spawn(registerUniqueAction, ACTION_TYPES.CREATE_WIZARD, handleCreateWizard);
  yield spawn(registerUniqueAction, ACTION_TYPES.SET_FAVORITE, handleBoardFavorite);
}
// use registerUniqueAction for listBased sagas, otherwise they will be duplicated because of handleFolderSelect function in main/folder.js file
