import { t } from '@jotforminc/translation';
import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Hooks } from '@jotforminc/uikit';
import Tracking from '@jotforminc/tracking';
import {
  ALL_TEXTS, ASSET_IDENTIFIER, eduBdmFlowAutoVisibleLcStKey,
  eduBdmFlowInterctedLcStKey, userPropType,
  GA_SUFFIXES, createGaAttributes, renderModal
} from '@jotforminc/enterprise-promotions-utils';
import { useActionManager } from '../../../hooks';
import { EducationBdmFlowModal } from '../../Modals';
import '../../../styles/educationBdmFlowBanner.scss';

const EducationBdmFlowBanner = ({ user }) => {
  const toggleRef = useRef();
  const bannerRef = useRef();
  const autoVisibleRef = useRef();
  const TIME_TO_BANNER_VISIBLE = 4_000;
  const { AB_TEST: { EDUCATION_BDM_FLOW_BANNER: target } } = ASSET_IDENTIFIER;
  const redDotInitialVal = !(window.localStorage.getItem(eduBdmFlowInterctedLcStKey) === '1');

  const [isRedDotVisible, setIsRedDotVisible] = useState(redDotInitialVal);
  const [isBannerVisible, setIsBannerVisible] = Hooks.useClickOutsideState(false, [toggleRef, bannerRef]);

  const [actionsLoaded, logAbTestAction] = useActionManager(user, 'epEducationBdmFlow');

  const gaAttributes = createGaAttributes(target);
  const gaAttributesSc = createGaAttributes(target, GA_SUFFIXES.SCHEDULE);
  const gaAttributesNn = createGaAttributes(target, GA_SUFFIXES.NOT_NOW);

  useEffect(() => {
    const className = 'education-bdm-banner-visible';
    const bodyEl = document.querySelector('body');
    if (!bodyEl.classList.contains(className)) {
      bodyEl.classList.add(className);
    }
    return () => {
      bodyEl.classList.remove(className);
    };
  }, []);

  useEffect(() => {
    logAbTestAction({ action: 'seen', target });
    Tracking.enableFS({ educationBdmFlow_bool: true });
  }, [actionsLoaded]);

  useEffect(() => {
    const isAutoVisibleSeen = window.localStorage.getItem(eduBdmFlowAutoVisibleLcStKey);

    const timer = setTimeout(() => {
      if (isBannerVisible || isAutoVisibleSeen) return;
      setIsBannerVisible(true);
      autoVisibleRef.current = true;
      window.localStorage.setItem(eduBdmFlowAutoVisibleLcStKey, '1');
    }, TIME_TO_BANNER_VISIBLE);

    return () => {
      clearTimeout(timer);
    };
  }, [isBannerVisible]);

  useEffect(() => {
    if (isRedDotVisible) return;
    window.localStorage.setItem(eduBdmFlowInterctedLcStKey, '1');
  }, [isRedDotVisible]);

  const handleToggleClick = () => {
    setIsRedDotVisible(false);
    setIsBannerVisible(visible => !visible);
    logAbTestAction({ action: 'click', target: `toggleButton-${target}` });
  };

  const handleSchedule = () => {
    setIsRedDotVisible(false);
    renderModal({ Modal: EducationBdmFlowModal, logAbTestAction, user });
    logAbTestAction({ action: 'click', target: `scheduleButton-${target}` });
  };

  const handleNotNowClick = () => {
    setIsBannerVisible(false);
    setIsRedDotVisible(false);
    logAbTestAction({ action: 'click', target: `notNowButton-${target}` });
  };

  const bannerClasses = cx({
    'banner-wrapper': true,
    show: isBannerVisible
  });

  return (
    <div className='us-education-banner' {...gaAttributes}>
      {/* toggle */}
      <div className='initial-cta-wrapper'>
        <button
          type="button"
          ref={toggleRef}
          onClick={handleToggleClick}
          className="initial-cta"
          aria-label={t(ALL_TEXTS.ADV_BANNER_TITLE)}
        >
          <img
            className='visual'
            src="https://cdn.jotfor.ms/assets/img/enterprise/us-education-flow/austin.png"
            alt='Austin Foley'
          />
          {isRedDotVisible && <span className='notification-count'>1</span>}
        </button>
      </div>

      {/* banner */}
      <div className={bannerClasses} ref={bannerRef}>
        <div className='content-wrapper'>
          <h2 className='title'>{t(ALL_TEXTS.EXCLUSIVE_OFFER_FOR_YOU)}</h2>
          <p className='description middle'>{t(ALL_TEXTS.CONTACT_WITH_ME_UNLOCK_EDUCATION)}</p>
          <p className='description'>{t(ALL_TEXTS.BEST)}</p>
          <p className='description name'>{t(ALL_TEXTS.AUSTIN_FOLEY)}</p>
          <p className='description'>{t(ALL_TEXTS.EDUCATION_VERTICAL_SPECIALIST)}</p>
        </div>
        <div className='button-wrapper'>
          {/* schedule */}
          <button
            type='button'
            onClick={handleSchedule}
            className='cta primary-cta'
            aria-label={t(ALL_TEXTS.CONTACT_NOW)}
            {...gaAttributesSc}
          >
            {t(ALL_TEXTS.CONTACT_NOW)}
          </button>
          {/* not now */}
          <button
            type='button'
            onClick={handleNotNowClick}
            className='cta secondary-cta'
            aria-label={t(ALL_TEXTS.CLOSE)}
            {...gaAttributesNn}
          >
            {t(ALL_TEXTS.CLOSE)}
          </button>
        </div>
      </div>
    </div>
  );
};

EducationBdmFlowBanner.propTypes = {
  user: userPropType.isRequired
};

export default EducationBdmFlowBanner;
