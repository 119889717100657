/* eslint-disable max-statements */
import {
  select, put, call, takeEvery, spawn
} from 'redux-saga/effects';
import React from 'react';
import { t } from '@jotforminc/translation';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { handleCustomNavigation } from '@jotforminc/utils';
import { ABTestManager } from '@jotforminc/abtest-manager';
import {
  ShareFolderModal as EnterprisePromoShareFolderModal, renderFolderLimitModal
} from '@jotforminc/enterprise-promotions';
import { SDR_SOURCES, renderModal } from '@jotforminc/enterprise-promotions-utils';
import { IconPlusSquareFilled, IconPencilToSquare } from '@jotforminc/svg-icons';
import * as API from '../../api';
import { SELECTORS } from '../../store/selectors';
import { ACTION_CREATORS } from '../../store/actionCreators';
import { showError } from '../../components/ListItem/toastForItemAction';
import {
  normalizeSharedFormFolders, normalizeFolders, normalizeTeamSubfolders, constructFolderLayoutMap, normalizeSharedFoldersV2
} from '../../utils';
import WatchmanRecorder from '../../utils/WatchmanRecorder';

import openInputModal from '../../modals/InputModal';
import ConfirmationDialog from '../../utils/Confirmation';
import { openCreateNewFormModal } from '../../wizards/CreateNewFormModal';

import { ACTION_TYPES } from '../../store/actionTypes';
import {
  FOLDER_TYPES, FOLDER_PREDEFINED_COLOR_LIST, ALL_ASSETS_ID, LISTING_TYPES, FOLDER_LIMIT_FEATURE_DATE, FEATURE_LIST, DEFAULT_FOLDER_IDS
} from '../../constants';
import { registerUniqueAction } from '../utils';
import { getUserFormLimitExceeded, handleFetchAllForms } from './form';
import { switchToDefaultFolder } from '../main/team';

function* getUserFolders() {
  try {
    const isAssetFoldersActive = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT));
    const folders = yield call(API.fetchFolders, isAssetFoldersActive);
    return folders;
  } catch (error) {
    return {};
  }
}

export function* getAssetFolderSummary(folderInfo) {
  const folders = yield call(getUserFolders);
  const normalizedFolders = normalizeFolders(folders?.subfolders || []);
  const currentPage = yield select(SELECTORS.getCurrentPage);
  const allAssetsSectionIndex = folderInfo.folderList.findIndex(({ id }) => id === ALL_ASSETS_ID[currentPage]);
  const folderLayoutBackup = yield select(SELECTORS.getFolderLayoutBackup);
  if (Object.keys(folderLayoutBackup).length > 1 && folders?.id !== Object.keys(folderLayoutBackup)?.[0]) {
    const rootFolderId = folders?.id;
    const rootFolderLayoutId = Object.keys(folderLayoutBackup).find(key => key === rootFolderId);
    if (rootFolderLayoutId) {
      const parsedFolderLayout = {
        [rootFolderLayoutId]: folderLayoutBackup[rootFolderLayoutId]
      };
      const folderLayoutMap = constructFolderLayoutMap(parsedFolderLayout);
      yield put(ACTION_CREATORS.updateFolderLayoutSuccess(folderLayoutMap));
    }
  }

  return {
    ...folderInfo,
    folderList: [
      ...folderInfo.folderList.slice(0, allAssetsSectionIndex + 1),
      ...normalizedFolders,
      ...folderInfo.folderList.slice(allAssetsSectionIndex + 1)
    ]
  };
}

function* getSharedResources() {
  try {
    const isV2 = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.SHARED_WITH_ME_V2));
    if (isV2) {
      const isGuest = yield select(SELECTORS.getIsGuestUser);
      return isGuest ? {} : yield call(API.fetchSharedWithMeStructure);
    }
    const sharedData = yield call(API.fetchSharedWithMeForms);
    return sharedData?.data?.content;
  } catch (error) {
    return [];
  }
}

export function* getFormFolderSummary(folderInfo) {
  const { sharedAccounts: parentAccounts } = yield select(SELECTORS.getUser);
  const folders = yield call(getUserFolders);
  const isV2 = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.SHARED_WITH_ME_V2));
  const sharedResources = yield call(getSharedResources);
  const folderLayoutBackup = yield select(SELECTORS.getFolderLayoutBackup);
  if (Object.keys(folderLayoutBackup).length > 1 && folders?.id !== Object.keys(folderLayoutBackup)?.[0]) {
    const rootFolderId = folders?.id;
    const rootFolderLayoutId = Object.keys(folderLayoutBackup).find(key => key === rootFolderId);
    if (rootFolderLayoutId) {
      const parsedFolderLayout = {
        [rootFolderLayoutId]: folderLayoutBackup[rootFolderLayoutId]
      };
      const folderLayoutMap = constructFolderLayoutMap(parsedFolderLayout);
      yield put(ACTION_CREATORS.updateFolderLayoutSuccess(folderLayoutMap));
    }
  }

  const parentAccountNames = parentAccounts?.map(({ username }) => username);
  const sharedFolders = isV2 ? normalizeSharedFoldersV2(sharedResources) : normalizeSharedFormFolders(sharedResources, parentAccountNames);
  const allFormsFolders = normalizeFolders([{
    id: ALL_ASSETS_ID[LISTING_TYPES.FORM],
    text: 'All Forms',
    color: '#6F76A7',
    isDefault: true,
    hasContextMenu: false,
    isDroppable: false,
    folderType: FOLDER_TYPES.STATIC,
    isRoot: true,
    isFocussable: true
  }]);

  const normalizedFormFolders = normalizeFolders(folders?.subfolders || []);
  const myformsSectionIndex = folderInfo.folderList.findIndex(({ id }) => id === DEFAULT_FOLDER_IDS.MYFORMS_TITLE);
  const sharedWithMeSectionIndex = folderInfo.folderList.findIndex(({ id }) => id === DEFAULT_FOLDER_IDS.SHARED_WITH_ME);

  const newFolderList = [
    ...folderInfo.folderList.slice(0, myformsSectionIndex + 1),
    ...allFormsFolders,
    ...normalizedFormFolders,
    ...folderInfo.folderList.slice(myformsSectionIndex + 1, sharedWithMeSectionIndex),
    ...folderInfo.folderList.slice(sharedWithMeSectionIndex, sharedWithMeSectionIndex + 1),
    ...sharedFolders,
    ...folderInfo.folderList.slice(sharedWithMeSectionIndex + 1)
  ];

  return {
    ...folderInfo,
    folderList: newFolderList
  };
}

function* openAddFolderModal() {
  const name = yield call(openInputModal, {
    titleText: t('Add Folder'),
    headerIcon: <IconPlusSquareFilled />,
    labelText: t('Folder Name'),
    inputPlaceholder: t('Add folder name here'),
    confirmText: t('Continue'),
    makeValidation: true,
    isRequired: true,
    inputProps: { maxLength: '50' }
  });
  if (!name) {
    throw new Error('name is empty');
  }

  return name;
}

export function* handleCreateFolder(extraData = {}, teamID, source) {
  try {
    const flattenedFolders = yield select(SELECTORS.getFlattenedFolders) || [];
    const accountType = yield select(SELECTORS.getAccountType);
    if (!teamID && !isEnterprise() && accountType !== 'ADMIN') {
      const user = yield select(SELECTORS.getUserCredentials) || {};
      if (user.created_at) {
        const userCreationDate = new Date(user.created_at);
        const formOwnedFolders = flattenedFolders.filter(folder => folder.owner === user.username && folder.folderType !== FOLDER_TYPES.TEAM && folder.folderType !== FOLDER_TYPES.TEAM_FOLDER);

        if (formOwnedFolders.length >= 50 && userCreationDate >= new Date(FOLDER_LIMIT_FEATURE_DATE)) {
          WatchmanRecorder.trackEvent('open_folder_limit_modal', source, 'createFolder');
          renderFolderLimitModal('', user);
          return;
        }
      }
    }
    const name = yield openAddFolderModal() || '';
    const usedColorList = flattenedFolders
      .filter(({ folderType }) => folderType === FOLDER_TYPES.ASSET_FOLDER)
      .reduce((prev, { color }) => ({
        ...prev,
        [color]: prev[color] ? prev[color] + 1 : 1
      }), {});
    const [color] = FOLDER_PREDEFINED_COLOR_LIST.sort((color1, color2) => {
      const weight1 = usedColorList[color1] || 0;
      const weight2 = usedColorList[color2] || 0;
      return weight1 >= weight2 ? 1 : -1;
    });
    const teamFolderColor = FOLDER_PREDEFINED_COLOR_LIST[Math.floor(Math.random() * FOLDER_PREDEFINED_COLOR_LIST.length)];
    const data = { color: teamID ? teamFolderColor : color, ...extraData, name };
    WatchmanRecorder.trackEvent('click', `create${teamID ? 'Team' : ''}Folder-${source}`, 'createFolder');
    yield put(ACTION_CREATORS.addFolderRequest({ data, teamID }));
  } catch (err) {
    // Modal closed
  }
}
// eslint-disable-next-line complexity
export function* handleFolderContextActions({
  folderID,
  id: actionID,
  value = null,
  teamID
}) {
  const selectedFolder = yield select(SELECTORS.getFolderByID(folderID));
  const teamFolderMenuItems = yield select(SELECTORS.getTeamFolderMenuItems);
  const allFolderMenuItems = yield select(SELECTORS.getAllFolderMenuItems);
  const currentPage = yield select(SELECTORS.getCurrentPage);
  const isTeamFolder = selectedFolder && selectedFolder.folderType === FOLDER_TYPES.TEAM_FOLDER;
  const team = yield select(SELECTORS.getFolderByID(selectedFolder.team_id));
  const userFormCountLimit = yield call(getUserFormLimitExceeded);
  const user = yield select(SELECTORS.getUserCredentials);

  const actions = Object.fromEntries((isTeamFolder ? teamFolderMenuItems : allFolderMenuItems[currentPage]).map(({ id }) => [id, id]));
  switch (actionID) {
    case actions.NEW_FORM: {
      try {
        if (userFormCountLimit) {
          yield put(ACTION_CREATORS.openLimitDialog());
        } else {
          const forms = yield call(handleFetchAllForms);
          yield call(openCreateNewFormModal, {
            folderID,
            forms,
            handleScrollToTop: () => {
              if (window?.document?.scrollingElement) window.document.scrollingElement.scrollTop = 0;
            },
            ...(!!team && { teamID: team.id, teamName: team.name })
          });
        }
      } catch (err) {
        // Modal closed
      }
      break;
    }
    case actions.NEW_ASSET: {
      yield put(ACTION_CREATORS.createWizard({ toFolder: true, folderID, teamProperties: { id: team?.id || '', name: team?.name || '' } }));
      break;
    }
    case actions.CHANGE_FOLDER_NAME:
      try {
        const folder = yield select(SELECTORS.getFolderByID(folderID));
        const newName = yield call(openInputModal, {
          titleText: 'Change Folder Name',
          headerIcon: <IconPencilToSquare />,
          labelText: 'Folder Name',
          inputPlaceholder: 'Enter a new name for your folder',
          confirmText: 'Continue',
          defaultValue: folder.name || folder.text,
          isRequired: true,
          makeValidation: true,
          inputProps: { maxLength: '50' },
          isFocussable: true
        });
        if (!newName) {
          return;
        }
        const attributes = {
          name: newName,
          text: newName
        };
        yield put(ACTION_CREATORS.updateFolderRequest({ folderID, teamID: folder.team_id || '', attributes }));
      } catch (err) {
        // Modal closed
      }
      break;
    case actions.ADD_FOLDER: {
      yield handleCreateFolder({ parent: (Object.values(ALL_ASSETS_ID).indexOf(folderID) > -1 || folderID === teamID) ? undefined : folderID }, teamID, 'contextMenu');
      break;
    }
    case actions.SHARE_FOLDER: {
      yield put(ACTION_CREATORS.addAutomatedSDREmail({ lastProductAssetInteraction: SDR_SOURCES.MY_FORMS_SHARE_FOLDER }));
      const url = `${(typeof window.jfLocalizedPath !== 'undefined' && window.jfLocalizedPath) ? window.jfLocalizedPath : ''}/myaccount/${
        window.JOTFORM_ENV === 'ENTERPRISE' ? 'sharing' : 'users'}?share-folder=${folderID}`;
      WatchmanRecorder.trackEvent('click', 'shareFolder', 'myFormsFolderContextMenu');

      // A/B Test: epShareFolderModal
      const abTestManager = new ABTestManager({
        isTestEnabled: false,
        testName: 'epShareFolderModal',
        controlVariantCode: '13451',
        testVariantCode: '13461',
        urlParam: 'epsfm',
        user: user,
        cacheVariantCodeAtLocalStorage: true
      });

      try {
        const isTestVariant = yield call(abTestManager.isTestVariant.bind(abTestManager));
        if (isTestVariant) {
          renderModal({
            user,
            Modal: EnterprisePromoShareFolderModal,
            logAbTestAction: abTestManager.registerABTestAction
          });
        } else {
          handleCustomNavigation(url, '_blank');
        }
      } catch (e) {
        handleCustomNavigation(url, '_blank');
      }

      break;
    }
    case actions.DELETE_FOLDER: {
      try {
        yield call(ConfirmationDialog, {
          title: t('Delete Folders'),
          content: t('Are you sure you want to delete selected folder? This folder and all sub-folders will be removed.')
        });
        yield put(ACTION_CREATORS.deleteFolder(folderID, teamID));
      } catch (err) {
        // Modal closed
      }
      break;
    }
    case actions.CHANGE_COLOR: {
      const { selectedColor } = value;
      if (!selectedColor) return;
      const attributes = {
        color: selectedColor
      };
      yield put(ACTION_CREATORS.updateFolderRequest({ folderID, teamID: selectedFolder.team_id || '', attributes }));
      break;
    }
    default:
      break;
  }
}

export function* addFolder({ data, teamID }) {
  try {
    const { name, color, parent } = data;
    const newData = {
      name,
      color,
      createRootFolder: 1,
      ...(parent ? { parent } : {})
    };

    const isAssetFoldersActive = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT));
    const apiResult = teamID ? yield call(API.addAssetFolderToTeam, newData, teamID) : yield call(API.addAssetFolder, { ...newData, ...(isAssetFoldersActive ? { v2: 1 } : {}) });
    if (teamID && apiResult?.content === false) {
      yield call(showError);
      yield put(ACTION_CREATORS.addFolderError());
      return;
    }

    let result;
    if (teamID) {
      const teamPermissions = yield select(SELECTORS.getTeamPermissionsByID(teamID));
      [result] = normalizeTeamSubfolders([apiResult], teamPermissions);
    } else {
      [result] = normalizeFolders([apiResult]);
    }
    yield put(ACTION_CREATORS.addFolderSuccess({ parent, folder: result, teamID }));

    if (!teamID && !parent) {
      yield put(ACTION_CREATORS.updateAllFoldersVisibility(true));
    }
  } catch (err) {
    yield call(showError, err?.data?.message);
    yield put(ACTION_CREATORS.addFolderError());
  }
}

export function* deleteFolder({ folderID, teamID }) {
  if (teamID) {
    yield call(API.deleteTeamFolder, teamID, folderID);
  } else {
    const isAssetFoldersActive = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT));
    yield call(API.deleteAssetFolder, folderID, isAssetFoldersActive);
  }

  const selectedFolderID = yield select(SELECTORS.getSelectedFolder);
  const selectedFolder = yield select(SELECTORS.getFolderByID(selectedFolderID));
  if (!selectedFolder) {
    yield call(switchToDefaultFolder, false);
  }
}

export function* updateFolders({
  folderID, teamID, attributes, handleSuccessImmediately
}) {
  if (handleSuccessImmediately) {
    yield put(ACTION_CREATORS.updateFolderSuccess({ folderID, teamID, attributes }));
  }
  const newAttributes = { ...attributes };
  if (newAttributes.name && newAttributes.text) {
    delete newAttributes.text;
  }

  try {
    const isAssetFoldersActive = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT));
    const result = teamID ? yield call(API.updateTeamFolderProps, folderID, teamID, newAttributes) : yield call(API.updateAssetFolder, folderID, {
      ...newAttributes, ...(isAssetFoldersActive ? { v2: 1 } : {})
    });
    if (!result || (result?.data?.responseCode && result.data.responseCode !== 200)) {
      yield call(showError, result?.data?.message);
    } else {
      yield put(ACTION_CREATORS.updateFolderSuccess({ folderID, teamID, attributes }));
    }
  } catch (err) {
    yield call(showError, err?.data?.message);
  }
}

function* addAutomatedSDREmail({ source }) {
  try {
    if (!isEnterprise()) {
      yield call(API.addAutomatedSDREmail, source);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* rootAssetFolderFlow() {
  const isFolderFeatureActive = yield select(SELECTORS.isFolderFeatureActive);
  if (!isFolderFeatureActive) return;

  yield spawn(registerUniqueAction, ACTION_TYPES.ADD_FOLDER.REQUEST, addFolder, takeEvery);
  yield spawn(registerUniqueAction, ACTION_TYPES.DELETE_FOLDER.REQUEST, deleteFolder, takeEvery, 'assetFolder');
  yield spawn(registerUniqueAction, ACTION_TYPES.UPDATE_FOLDERS.REQUEST, updateFolders, takeEvery);
  yield spawn(registerUniqueAction, ACTION_TYPES.FOLDER_CONTEXT_ACTION, handleFolderContextActions, takeEvery);
  yield spawn(registerUniqueAction, ACTION_TYPES.ADD_AUTOMATED_SDR_EMAIL, addAutomatedSDREmail, takeEvery);
}
// use registerUniqueAction for listBased sagas, otherwise they will be duplicated because of handleFolderSelect function in main/folder.js file
