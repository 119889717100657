import {
  delay, call, select, put, spawn, all
} from 'redux-saga/effects';
import { openCloneAppModal } from '@jotforminc/create-new-wizards';

import * as API from '../../api';
import { SELECTORS } from '../../store/selectors';
import { ACTION_TYPES } from '../../store/actionTypes';
import { ACTION_CREATORS } from '../../store/actionCreators';
import { openCreateNewPortalModal } from '../../wizards/CreateNewPortalModal';
import WatchmanRecorder from '../../utils/WatchmanRecorder';
import { registerUniqueAction } from '../utils';
import { getPortalRoot, getAssetTypeFromListingType } from '../../utils';
import {
  ASSET_TYPES, FEATURE_LIST, FOLDER_TYPES, LISTING_TYPES
} from '../../constants';
import { openCreateNewReportModal } from '../../wizards/CreateNewReportModal';
import { handleFetchAllForms } from './form';
import { initCtAppGeneratorIII } from '../../utils/abTests/ctAppGeneratorIII';
import { initCtAppWizardStartFromBasic } from '../../utils/abTests/ctAppWizardStartFromBasic';

export function portalTrackData(id, target) {
  return WatchmanRecorder.trackEvent('click', `${id}-${target}`);
}

function* handleUpdatePortal({ id, properties }) {
  try {
    const result = yield call(API.updateApp, id, properties);
    if (!result) {
      yield put(ACTION_CREATORS.portalUpdateError(id));
      return;
    }

    yield put(ACTION_CREATORS.portalUpdateSuccess(id));
    yield call(portalTrackData, id, `portalUpdate:${Object.keys(properties)}`);
  } catch (e) {
    yield put(ACTION_CREATORS.portalUpdateError(id));
  }
}

export function* handlePortalFavorite({ id }) {
  yield delay(500);
  const portal = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.PORTAL));
  if (!portal) {
    return false;
  }
  const { favorite } = portal;
  yield call(API.favoriteAppForUser, id, favorite);
  yield call(portalTrackData, id, `portal${favorite === '1' ? 'Favorite' : 'Unfavorite'}`);
}

export function* handleOnDeleteMultiplePortals() {
  try {
    const deletedItemList = yield select(SELECTORS.getSelectedItemsWithInfo);
    const selectedIDList = yield select(SELECTORS.getSelectedItemIDs);
    const result = yield call(API.deleteMultipleApps, selectedIDList);
    if (!result) {
      yield put(ACTION_CREATORS.onDeleteMultiplePortalsError());
      return;
    }
    yield put(ACTION_CREATORS.onDeleteMultiplePortalsSuccess(deletedItemList));
  } catch (e) {
    yield put(ACTION_CREATORS.onDeleteMultiplePortalsError());
  }
}

export function* handleCreateWizard({
  initialView, toFolder, folderID: folderId, teamProperties, createAssetFrom, isSideBar, onWizardMount = f => f, newRootSelector, isEmbeddedToMixedCreationFlow, targetText = '', isMixPageUser
}) {
  try {
    const user = yield select(SELECTORS.getUser);
    const isAppFirstUser = yield select(SELECTORS.getUserIsAppFirst);
    const isAssetsFolderActive = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT));
    const currentTeamID = toFolder ? teamProperties?.id : yield select(SELECTORS.getCurrentTeamID);
    const folderID = toFolder ? folderId : yield select(SELECTORS.getSelectedFolderIDForCreation);
    const showBuildStoreAppFeature = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.APP_BUILD_STORE));
    const fromOnLineStoreLandingFeature = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.APP_BUILD_STORE_LANDING));
    const fromOnLineDonationLandingFeature = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.APP_BUILD_DONATION_LANDING));
    const showBuildDonationAppFeature = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.APP_BUILD_DONATION));
    const showRecommendation = yield select(SELECTORS.getAppRecommendation);
    const showOnlineStoreRecommendation = yield select(SELECTORS.getStoreRecommendation);
    const userLogAbTestAction = yield select(SELECTORS.getUserLogAbTestAction);
    const isMobileDevice = yield select(SELECTORS.getIsMobileDevice);
    const isCreationModalTestUser = yield select(SELECTORS.getIsCreationModalTestUser);
    const userApps = yield call(API.fetchApps, {
      filter: { 'status:ne': ['TRASHED'] },
      limit: 1
    });

    // A/B Test: ctAppWizardStartFromBasic
    const { isTestVariant, templateID, logAbTestAction } = yield call(initCtAppWizardStartFromBasic, { user, isAppFirstUser });

    // A/B Test: initCtAppGeneratorIII
    const { isTestVariant: showAppGenerator, logAbTestAction: logAppGenAbTestAction } = yield call(initCtAppGeneratorIII, { user, isAppFirstUser });

    yield call(openCreateNewPortalModal, {
      user,
      portalRoot: getPortalRoot(),
      showBuildStoreAppFeature,
      showBuildDonationAppFeature,
      fromOnLineStoreLandingFeature,
      fromOnLineDonationLandingFeature,
      initialView,
      showRecommendation,
      showOnlineStoreRecommendation,
      ...(!!currentTeamID && { teamID: currentTeamID }),
      ...(isAssetsFolderActive && !!folderID && { folderID }),
      totalListCount: userApps?.data?.content?.length || 0,
      showStartFromBasic: isTestVariant ? { templateID } : false,
      logAbTestAction,
      showAppGenerator,
      logAppGenAbTestAction,
      creationLogger: actionEvent => {
        WatchmanRecorder.trackEvent('click', `create-app-button${isSideBar ? '-sideBar' : ''}-${actionEvent}-app-created`, 'apps', true);
        if (createAssetFrom && userLogAbTestAction) {
          userLogAbTestAction({ action: 'click', target: `${isMobileDevice ? 'mobile-' : ''}${isSideBar ? 'sideBar-' : ''}${targetText}${createAssetFrom}-${actionEvent}-app-created` });
          userLogAbTestAction({ action: 'click', target: 'actTest-appCreated' });
        }
      },
      onWizardMount,
      newRootSelector,
      isEmbeddedToMixedCreationFlow,
      isMixAssetCreationModal: isCreationModalTestUser || isMixPageUser,
      backToMixModalActionLogger: () => WatchmanRecorder.trackEventForCustomProject('click', `${isMobileDevice ? 'mobile-' : ''}back-to-mix-modal-from-create-apps`, 'mixAssetCreationModal', true)
    });
  } catch (e) {
    console.log('error:', e);
  }
}

export function* handleAppClone({
  id, portalRoot
}) {
  try {
    const { items: portalItems, title: appTitle } = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.PORTAL));
    const user = yield select(SELECTORS.getUser);
    const teamID = yield select(SELECTORS.getCurrentTeamID);
    yield call(openCloneAppModal, {
      id, title: appTitle, user: user.credentials, portalRoot, portalItems, teamID
    });
  } catch (e) {
    yield put(ACTION_CREATORS.cloneItemError(id));
  }
}

/**
 * Resolve the digestable form information for the
 * currently selected app.
 *
 * @param {string | undefined} currentTeamID ID of the current team space,
 * if it exists.
 * @returns {Object} Object containing digestable form data.
 */
function* resolveDigestableForms(currentTeamID) {
  // Get the current app we are creating the digest for.
  const selectedIDList = yield select(SELECTORS.getSelectedItemIDs);
  const apps = yield select(SELECTORS.getList);
  const selectedApp = apps.find(({ id }) => selectedIDList.includes(id));
  const [
    assetFilter,
    currentPage,
    isAllAssetFilterSelected
  ] = yield all([
    select(SELECTORS.getAssetFilter),
    select(SELECTORS.getCurrentPage),
    select(SELECTORS.getIsAllAssetFilterTypesSelected)
  ]);
  // Fetch the forms in the current scope (team v. user)
  const forms = !currentTeamID
    ? yield call(handleFetchAllForms)
    : yield call(
      API.fetchTeamAssets,
      {
        selectedFolder: currentTeamID,
        type: getAssetTypeFromListingType(LISTING_TYPES.FORM),
        disableJotFormNormalize: false,
        assetFilter,
        currentPage,
        isAllAssetFilterSelected,
        folderType: FOLDER_TYPES.TEAM,
        skipV2: true
      }
    );
  const formIDList = forms.map(({ id }) => id);

  // If a checkoutable item is deleted after once created,
  // checkout form ID stays filled but there isn't an item anymore,
  // so you need to take that into account.
  const checkoutItem = selectedApp.items.find(({ type }) => ['DONATION', 'PRODUCT_LIST'].includes(type));
  const checkoutFormID = selectedApp && !!checkoutItem && selectedApp.checkoutFormID;

  let checkoutForm = null;
  if (checkoutFormID) {
    checkoutForm = {
      ...yield call(API.fetchForm, checkoutFormID),
      checkoutType: selectedApp.items.some(({ type }) => type === 'DONATION') ? 'DONATION' : 'STORE'
    };
  }

  // Select the resources to be included in the digest:
  // We will include all the forms in the app,
  // but only if they are in the current scope,
  // as in, user vs team forms, this is because
  // in teams apps you can add cross-app forms,
  // but we don't support this in digest.

  // Filter to selected app forms included the checked out form.
  const digestableAppResourceIDs = [
    ...selectedApp.items.filter(({ type, id }) => type === 'FORM' && formIDList.includes(id)).map(({ id }) => id),
    ...(checkoutFormID ? [checkoutFormID] : [])
  ];

  // Further refine the form list to only include the selected forms.
  // This makes it such that the selectable-forms are only the app forms.
  const appForms = forms.filter(({ id }) => digestableAppResourceIDs.includes(id));
  // scope_info is a valid subset of the selectedApp
  // So we should ignore the extra keys in digest_core
  // but that should be doable with typescript.
  const scopeInformation = selectedApp;

  // These are extra props we need to pass to digest.
  const digestProps = {
    // If this is set to true, user has opened the donation goal from app.
    hasOpenedDonationGoalFromApp: checkoutItem && checkoutItem.showDonationGoal === '1'
  };

  return {
    appForms: checkoutForm ? [...appForms, checkoutForm] : appForms,
    digestableAppResourceIDs,
    scopeID: selectedApp.id,
    scopeInformation,
    digestProps
  };
}

export function* handleCreateDigest() {
  try {
    const userCredentials = yield select(SELECTORS.getUserCredentials);
    const currentTeamID = yield select(SELECTORS.getCurrentTeamID);
    const {
      appForms,
      digestableAppResourceIDs,
      scopeID,
      scopeInformation,
      digestProps
    } = yield call(resolveDigestableForms, currentTeamID);
    const { report } = yield call(openCreateNewReportModal, {
      forms: appForms,
      user: userCredentials,
      formId: digestableAppResourceIDs,
      reportType: 'digest',
      teamID: currentTeamID,
      scope: 'APP',
      scopeID,
      scopeInformation,
      digestProps
    });

    if (report?.type === 'digest') {
      if (userCredentials?.digestBetaAccepted !== '1') {
        yield put(ACTION_CREATORS.updateUserProperty({ digestBetaAccepted: '1' }));
      }
      yield put(ACTION_CREATORS.fetchListRequest());
      yield put(ACTION_CREATORS.selectItem(report.id, true, ASSET_TYPES.REPORT));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    if (e) console.log({ e });
  }
}

export function* handleDigestUpdateWizard(action) {
  try {
    const { digestID } = action;
    const digest = yield call(API.getDigest, digestID);
    const user = yield select(SELECTORS.getUser);
    const reportData = { digest, user };
    const currentTeamID = yield select(SELECTORS.getCurrentTeamID);

    const { appForms, digestProps } = yield call(resolveDigestableForms, currentTeamID);

    yield put(ACTION_CREATORS.updateReportWizard(
      'digest',
      '',
      digestID,
      { ...reportData, digestProps },
      undefined,
      appForms
    ));
  } catch (e) {
    console.log('error:', e);
  }
}

export function* rootPortalFlow() {
  yield spawn(registerUniqueAction, ACTION_TYPES.CREATE_WIZARD, handleCreateWizard);
  yield spawn(registerUniqueAction, ACTION_TYPES.SET_FAVORITE, handlePortalFavorite);
  yield spawn(registerUniqueAction, ACTION_TYPES.PORTAL_UPDATE.REQUEST, handleUpdatePortal);
  yield spawn(registerUniqueAction, ACTION_TYPES.ON_DELETE_MULTIPLE_PORTALS.REQUEST, handleOnDeleteMultiplePortals);
  yield spawn(registerUniqueAction, ACTION_TYPES.CLONE_ITEM.REQUEST, handleAppClone);
  yield spawn(registerUniqueAction, ACTION_TYPES.CREATE_DIGEST, handleCreateDigest);
  yield spawn(registerUniqueAction, ACTION_TYPES.UPDATE_DIGEST, handleDigestUpdateWizard);
}
// use registerUniqueAction for listBased sagas, otherwise they will be duplicated because of handleFolderSelect function in main/folder.js file
