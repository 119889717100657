import { createRoot } from 'react-dom/client';
import React, { useState } from 'react';
import { t, translationRenderer } from '@jotforminc/translation';
import { bool, number, string } from 'prop-types';

import useInterval from '../hooks/useInterval';

import './styles/newuser.scss';

export const UKNewUserCampaignBanner = ({
  utmSource, accountTypeName, expirationDate, isOverquotaCampaign = false, campaignUtm = ''
}) => {
  const getDistance = () => {
    // Get today's date and time
    const now = new Date().getTime();
    // Find the distance between now and the count down date
    const distance = expirationDate - now;
    return distance;
  };
  const [countdown, setCountdown] = useState(getDistance() < 0 ? '00 : 00 : 00' : '24 : 00 : 00');
  const [delay, setDelay] = useState(getDistance() < 0 ? null : 1000);
  useInterval(() => {
    const distance = getDistance();
    if (distance < 0) {
      setCountdown('00 : 00 : 00');
      setDelay(null);
    }
    // Time calculations for days, hours, minutes and seconds
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (hours < 10) hours = `0${hours}`;

    if (minutes < 10) minutes = `0${minutes}`;

    if (seconds < 10) seconds = `0${seconds}`;

    // Output the result in an element with id="demo"
    setCountdown(`${hours}h : ${minutes}m : ${seconds}s `);
  }, delay);
  return (
    <div className="uk-campaign-container">
      <div className="uk-campaign-content-container">
        <div className="heading-counter-wrapper">
          <div className="heading">
            <strong className="title">{t('TODAY ONLY!')}</strong>
            <div className="discount">
              {translationRenderer('[1[SAVE]] [2[50]][3[%]]')({
                renderer1: save => <strong className="second-title">{save}</strong>,
                renderer2: rate => <strong className="third-title">{rate}</strong>,
                renderer3: percent => <strong className="four-title">{percent}</strong>
              })}
            </div>
          </div>

          <div className="counter">
            <span className="counter-title locale white">{t('Offer expires in')}</span>
            <span id="countdown-for-newuser">{countdown}</span>
          </div>
        </div>

        <div className="item button">
          <a
            className="locale"
            href={`/pricing?utm_campaign=${isOverquotaCampaign ? campaignUtm : 'newUserDesignTest'}&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header`}
          >
            {t('Save Now')}
          </a>
        </div>
      </div>
    </div>
  );
};

UKNewUserCampaignBanner.propTypes = {
  utmSource: string.isRequired,
  expirationDate: number.isRequired,
  accountTypeName: string.isRequired,
  isOverquotaCampaign: bool,
  campaignUtm: string
};

export const renderUKNewUserCampaign = ({
  container, expirationDate, accountTypeName, utmSource, isOverquotaCampaign, campaignUtm
}) => {
  const root = createRoot(container);
  root.render(
    <UKNewUserCampaignBanner
      utmSource={utmSource}
      expirationDate={expirationDate}
      accountTypeName={accountTypeName}
      isOverquotaCampaign={isOverquotaCampaign}
      campaignUtm={campaignUtm}
    />
  );
};
