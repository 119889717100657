import React from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { safeLazy, lazyRender } from '@jotforminc/safe-lazy-import';

const CreateNewWorkflowWizard = safeLazy(() => import(/* webpackChunkName: "LazyWizard_CreateNewWorkflowWizard" */'./LazyWizards/LazyCreateNewWorkflowWizard'));
const createAssetModalContainer = document.getElementById('create-asset-modal-container');

export const openCreateNewWorkflowModal = props => {
  return new Promise((resolve, reject) => {
    const containerSelector = '#modal-container';
    const root = document.querySelector(containerSelector);
    const rootElement = (props?.newRootSelector && document.getElementById(props.newRootSelector)) || root;
    const createFlowWrapperElement = document.getElementById('createFlowModal-wrapper');

    const handleClose = () => {
      reject();
      window.history.replaceState({}, '', window.location.pathname);
      unmountComponentAtNode(rootElement);
      if (createAssetModalContainer && props.isEmbeddedToMixedCreationFlow) {
        unmountComponentAtNode(createAssetModalContainer);
      }
    };

    const handleCreate = ({ type }) => {
      resolve(type);
    };

    const onViewChange = isMain => {
      if (createFlowWrapperElement) {
        if (isMain) {
          createFlowWrapperElement.classList.remove('isHidden');
        } else {
          createFlowWrapperElement.classList.add('isHidden');
        }
      }
    };

    lazyRender({
      root: rootElement,
      children: (
        <CreateNewWorkflowWizard
          {...props}
          onHide={handleClose}
          onCreate={handleCreate}
          initialVisibilityState={true}
          onViewChange={onViewChange}
        />
      )
    });
  });
};
