import React from 'react';
import { useSelector } from 'react-redux';
import ProtectedUI from './ProtectedUI';

import { LISTING_TYPES } from '../../constants';
import { ALL_PERMISSIONS } from '.';
import CreateButton from '../../containers/sidebar/createButton';
import { SELECTORS } from '../../store/selectors';
import { showAssetFilterOnTeamPage } from '..';

const permissionMap = {
  [LISTING_TYPES.PORTAL]: [ALL_PERMISSIONS.CREATE_APP],
  [LISTING_TYPES.ASSIGNED_FORM]: [ALL_PERMISSIONS.CREATE_FORM],
  [LISTING_TYPES.TASK]: [ALL_PERMISSIONS.CREATE_FLOW],
  [LISTING_TYPES.SHEET]: [ALL_PERMISSIONS.CREATE_SHEET],
  [LISTING_TYPES.REPORT]: [ALL_PERMISSIONS.CREATE_REPORT],
  [LISTING_TYPES.DOCUMENT]: [ALL_PERMISSIONS.CREATE_DOCUMENT],
  [LISTING_TYPES.FORM]: [ALL_PERMISSIONS.CREATE_FORM],
  [LISTING_TYPES.TEAM_PAGE]: [ALL_PERMISSIONS.CREATE_FORM],
  [LISTING_TYPES.TEAMS]: [ALL_PERMISSIONS.CREATE_FORM],
  [LISTING_TYPES.CONTACTS]: [ALL_PERMISSIONS.CREATE_CONTACT],
  [LISTING_TYPES.PAGES]: [ALL_PERMISSIONS.CREATE_PAGE],
  [LISTING_TYPES.BOARD]: [ALL_PERMISSIONS.CREATE_BOARD],
  [LISTING_TYPES.AGENT]: [ALL_PERMISSIONS.CREATE_AGENT],
  [LISTING_TYPES.MIX]: [
    ALL_PERMISSIONS.CREATE_FORM,
    ALL_PERMISSIONS.CREATE_SHEET,
    ALL_PERMISSIONS.CREATE_REPORT,
    ALL_PERMISSIONS.CREATE_APP,
    ALL_PERMISSIONS.CREATE_FLOW,
    ALL_PERMISSIONS.CREATE_DOCUMENT,
    ALL_PERMISSIONS.CREATE_BOARD
  ]
};

const ProtectedCreateButton = ({ ...props }) => {
  const permissions = useSelector(SELECTORS.getUserPermissions);
  const assetFilterType = useSelector(SELECTORS.getAssetFilterType);
  const isDisableAssetFilterDropdown = useSelector(SELECTORS.getIsAssetFiltersDisabled);
  const currentPage = useSelector(SELECTORS.getCurrentPage);
  let permissionType = assetFilterType;
  if (currentPage === LISTING_TYPES.MIX || (currentPage === LISTING_TYPES.TEAM_PAGE && showAssetFilterOnTeamPage())) {
    permissionType = isDisableAssetFilterDropdown ? LISTING_TYPES.FORM : LISTING_TYPES.MIX;
  }
  const requiredPermissions = permissionMap[permissionType];
  return (
    <ProtectedUI
      userPermissions={permissions}
      requiredPermissions={requiredPermissions}
      oneperm={permissionType === LISTING_TYPES.MIX}
    >
      <CreateButton {...props} />
    </ProtectedUI>
  );
};

export default ProtectedCreateButton;
