import React from 'react';
import { safeLazy, lazyRender } from '@jotforminc/safe-lazy-import';
import ReactDOM from 'react-dom';

import { fetchFormQuestions } from '../api';

const CreateNewReportWizard = safeLazy(() => import(/* webpackChunkName: "LazyWizard_CreateNewReportWizard" */'./LazyWizards/LazyCreateNewReportWizard'));

const root = document.getElementById('modal-container');
const createAssetModalContainer = document.getElementById('create-asset-modal-container');

export const openCreateNewReportModal = ({
  forms, selectedID, ...props
}) => {
  return new Promise((resolve, reject) => {
    const rootElement = (props?.newRootSelector && document.getElementById(props.newRootSelector)) || root;
    const createFlowWrapperElement = document.getElementById('createFlowModal-wrapper');

    const handleClose = () => {
      reject();
      window.history.replaceState({}, '', window.location.pathname);
      ReactDOM.unmountComponentAtNode(rootElement);
      if (createAssetModalContainer && props.isEmbeddedToMixedCreationFlow) {
        ReactDOM.unmountComponentAtNode(createAssetModalContainer);
      }
    };
    const skipValue = selectedID ? { form: true } : null;

    const createReport = (formID, report) => new Promise((resolveCreateReport, rejectCreateReport) => {
      resolve({
        formID,
        report,
        rejectCreateReport,
        resolveCreateReport
      });
    });

    const onViewChange = isMain => {
      if (createFlowWrapperElement) {
        if (isMain) {
          createFlowWrapperElement.classList.remove('isHidden');
        } else {
          createFlowWrapperElement.classList.add('isHidden');
        }
      }
    };

    lazyRender({
      root: rootElement,
      children: (
        <CreateNewReportWizard
          disableActionLogger
          usePortal
          fetchFormQuestions={fetchFormQuestions}
          forms={forms}
          initialVisibilityState={true}
          onHide={handleClose}
          targetContainer={props.newRootSelector ? `#${props.newRootSelector}` : '#modal-container'}
          formId={selectedID}
          skip={skipValue}
          onSave={createReport}
          onViewChange={onViewChange}
          {...props}
        />
      )
    });
  });
};
