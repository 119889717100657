import React from 'react';
import ReactDOM from 'react-dom';
import { ENTERPRSE_PROMOTIONS_ROOT_EL_ID, getRootElement } from '@jotforminc/enterprise-promotions-utils';
import { LunchAndLearnModal } from '../components';

const renderLunchAndLearnModal = props => {
  const rootEl = getRootElement(ENTERPRSE_PROMOTIONS_ROOT_EL_ID);

  const handleClose = () => {
    ReactDOM.unmountComponentAtNode(rootEl);
  };

  ReactDOM.render(
    <LunchAndLearnModal onClose={handleClose} {...props} />,
    rootEl
  );
};

export default renderLunchAndLearnModal;
