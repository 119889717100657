import {
  take, select, call, put, takeEvery, all, spawn
} from 'redux-saga/effects';
import { safeJSONParse } from '@jotforminc/utils';
import * as API from '../../api';
import { SELECTORS } from '../../store/selectors';

import { ACTION_TYPES } from '../../store/actionTypes';
import { ACTION_CREATORS } from '../../store/actionCreators';
import { constructFolderLayoutMap, constructFolderLayoutObject } from '../../utils';

import { FOLDER_DROP_POSITIONS, ALL_ASSETS_ID } from '../../constants';
import { registerUniqueAction } from '../utils';

export function* initializeFolderLayout() {
  try {
    const { folderLayout } = yield call(API.fetchFolderLayout);
    let parsedFolderLayout = safeJSONParse(folderLayout, {});
    while (typeof parsedFolderLayout === 'string') {
      parsedFolderLayout = safeJSONParse(parsedFolderLayout, {});
    }
    if (Object.keys(parsedFolderLayout).length > 1) {
      const parsedFolderLayoutBackup = Object.keys(parsedFolderLayout).reduce((acc, key) => ({ ...acc, [key]: parsedFolderLayout[key] }), {});
      yield put(ACTION_CREATORS.updateFolderLayoutBackupSuccess(parsedFolderLayoutBackup));
      const firstKey = Object.keys(parsedFolderLayout)[0];
      parsedFolderLayout = {
        [firstKey]: parsedFolderLayout[firstKey]
      };
    }
    const folderLayoutMap = constructFolderLayoutMap(parsedFolderLayout);
    yield put(ACTION_CREATORS.updateFolderLayoutSuccess(folderLayoutMap));
  } catch (e) {
    console.error('Folder layout initialize failed', e);
  }
}

function removeEntryFromNestedMap({
  key,
  map = new Map()
}) {
  const mapCopy = new Map(map);
  const keyList = [...mapCopy.keys()];
  keyList.forEach(tmpKey => {
    if (tmpKey !== key) {
      const subMap = mapCopy.get(tmpKey);
      mapCopy.set(tmpKey, removeEntryFromNestedMap({ key, map: subMap }));
      return;
    }

    mapCopy.delete(key);
  });

  return mapCopy;
}

export function* deleteFolderFromLayout({ folderID }) {
  const folderLayout = yield select(SELECTORS.getFolderLayout);
  const newFolderLayout = removeEntryFromNestedMap({ key: folderID, map: folderLayout });
  yield put(ACTION_CREATORS.updateFolderLayoutRequest(newFolderLayout));
}

function addEntryToNestedMap({
  key,
  parentKey,
  map = new Map(),
  value = new Map(),
  toBeginning = false
}) {
  const mapCopy = new Map(map);
  const keyList = [...mapCopy.keys()];
  keyList.forEach(tmpKey => {
    const subMap = mapCopy.get(tmpKey);
    if (tmpKey !== parentKey) {
      mapCopy.set(tmpKey, addEntryToNestedMap({
        parentKey, key, map: subMap, value, toBeginning
      }));
      return;
    }

    let subMapToSet = subMap;
    if (toBeginning) {
      subMapToSet = new Map([[key, value], ...subMap.entries()]);
    } else {
      subMapToSet.set(key, value);
    }
    mapCopy.set(tmpKey, subMapToSet);
  });

  return mapCopy;
}

function* initialFolderToLayout({ path, folderID }) {
  if (!path) return;

  const subFolderLayout = new Map();
  subFolderLayout.set(folderID, new Map());

  const [rootFolderID] = path.split(',');
  const folderLayout = new Map();
  folderLayout.set(rootFolderID, subFolderLayout);
  yield put(ACTION_CREATORS.updateFolderLayoutRequest(folderLayout));
}

export function* addFolderToLayout({ folder: { id: folderID, parent, path }, teamID }) {
  if (teamID) return;

  const folderLayout = yield select(SELECTORS.getFolderLayout);
  if (folderLayout.size === 0) {
    yield initialFolderToLayout({ folderID, path });
    return;
  }

  const newFolderLayout = addEntryToNestedMap({ parentKey: parent, key: folderID, map: folderLayout });
  yield put(ACTION_CREATORS.updateFolderLayoutRequest(newFolderLayout));
}

function addEntryAfterKeyOnNestedMap({
  key,
  afterKey,
  map = new Map(),
  value = new Map()
}) {
  const newMap = new Map();
  const keyList = [...map.keys()];
  keyList.forEach(tmpKey => {
    const subMap = map.get(tmpKey);
    if (tmpKey !== afterKey) {
      newMap.set(tmpKey, addEntryAfterKeyOnNestedMap({
        afterKey, key, map: subMap, value
      }));
      return;
    }

    newMap.set(key, value);
    newMap.set(tmpKey, subMap);
  });

  return newMap;
}

function addEntryBeforeKeyOnNestedMap({
  key,
  afterKey,
  map = new Map(),
  value = new Map()
}) {
  const newMap = new Map();
  const keyList = [...map.keys()];
  keyList.forEach(tmpKey => {
    const subMap = map.get(tmpKey);
    if (tmpKey !== afterKey) {
      newMap.set(tmpKey, addEntryBeforeKeyOnNestedMap({
        afterKey, key, map: subMap, value
      }));
      return;
    }

    newMap.set(tmpKey, subMap);
    newMap.set(key, value);
  });

  return newMap;
}

// eslint-disable-next-line complexity, max-statements
export function* reorderFolderLayout({ folderID, referenceFolderID, position }) {
  if (Object.values(FOLDER_DROP_POSITIONS).indexOf(position) === -1) {
    return false;
  }

  const [reorderedFolder, referencedFolder, folderLayout] = yield all([
    select(SELECTORS.getFolderByID(folderID)),
    select(SELECTORS.getFolderByID(referenceFolderID)),
    select(SELECTORS.getFolderLayout)
  ]);
  const currentPage = yield select(SELECTORS.getCurrentPage);
  if (!reorderedFolder || !referencedFolder || !folderLayout?.size || (!referencedFolder.path && referencedFolder.id !== ALL_ASSETS_ID[currentPage])) {
    return false;
  }

  if (referencedFolder.isRoot && position === FOLDER_DROP_POSITIONS.BEFORE) {
    return false;
  }

  const { path = '' } = reorderedFolder;
  const pathParts = path.split(',');
  const reorderedFolderLayoutMap = pathParts.length > 0 ? pathParts.reduce((prevMap, current) => {
    return prevMap.get(current) || new Map();
  }, folderLayout) : new Map();

  const removedFolderLayoutMap = removeEntryFromNestedMap({ key: folderID, map: folderLayout });

  let newFolderLayout = false;
  let newFolderPath = '';
  let newFolderParentId = '';
  switch (true) {
    case position === FOLDER_DROP_POSITIONS.OVER && referencedFolder.isRoot: {
      const rootFolderID = yield select(SELECTORS.getRootFolderID);
      newFolderLayout = addEntryToNestedMap({
        key: folderID,
        toBeginning: true,
        parentKey: rootFolderID,
        map: removedFolderLayoutMap,
        value: reorderedFolderLayoutMap
      });
      break;
    }
    case position === FOLDER_DROP_POSITIONS.OVER: {
      newFolderLayout = addEntryToNestedMap({
        key: folderID,
        map: removedFolderLayoutMap,
        parentKey: referenceFolderID,
        value: reorderedFolderLayoutMap
      });
      break;
    }
    case position === FOLDER_DROP_POSITIONS.BEFORE: {
      newFolderLayout = addEntryAfterKeyOnNestedMap({
        key: folderID,
        map: removedFolderLayoutMap,
        afterKey: referenceFolderID,
        value: reorderedFolderLayoutMap
      });
      break;
    }
    case position === FOLDER_DROP_POSITIONS.AFTER: {
      newFolderLayout = addEntryBeforeKeyOnNestedMap({
        key: folderID,
        map: removedFolderLayoutMap,
        afterKey: referenceFolderID,
        value: reorderedFolderLayoutMap
      });
      break;
    }
    default:
      break;
  }

  if (!newFolderLayout) {
    return;
  }

  if (position === FOLDER_DROP_POSITIONS.BEFORE || position === FOLDER_DROP_POSITIONS.AFTER) {
    const referencedFolderPathArray = referencedFolder.path.split(',');
    referencedFolderPathArray.pop();
    newFolderPath = `${referencedFolderPathArray.join()},${folderID}`;
    newFolderParentId = referencedFolderPathArray.pop();
  } else {
    newFolderPath = `${referencedFolder.path},${folderID}`;
    newFolderParentId = referenceFolderID;
  }

  yield put(ACTION_CREATORS.allowReorderFolders(false));

  if (newFolderPath && newFolderParentId) {
    yield put(ACTION_CREATORS.updateFolderRequest({ folderID, attributes: { path: newFolderPath, parent: newFolderParentId }, handleSuccessImmediately: true }));
    yield put(ACTION_CREATORS.fetchFolders());
  }

  yield put(ACTION_CREATORS.updateFolderLayoutRequest(newFolderLayout));
}

export function* updateFolderLayout({ folderLayout }) {
  try {
    const folderLayoutObject = constructFolderLayoutObject(folderLayout);
    yield all([
      put(ACTION_CREATORS.updateFolderLayoutSuccess(folderLayout)),
      call(API.updateUserSettings, { folderLayout: JSON.stringify(folderLayoutObject) })
    ]);
  } catch (e) {
    console.error('Folder layout update failed', e);
  } finally {
    yield put(ACTION_CREATORS.allowReorderFolders(true));
  }
}

export function* rootFolderLayoutFlow() {
  yield take(ACTION_TYPES.FETCH_USER.SUCCESS);
  const userType = yield select(SELECTORS.getUserType);
  const isFolderFeatureActive = yield select(SELECTORS.isFolderFeatureActive);

  if (userType !== 'USER' || !isFolderFeatureActive) {
    return;
  }

  yield call(initializeFolderLayout);
  yield spawn(registerUniqueAction, ACTION_TYPES.REORDER_FOLDERS, reorderFolderLayout, takeEvery, 'assetFolderLayout');
  yield spawn(registerUniqueAction, ACTION_TYPES.ADD_FOLDER.SUCCESS, addFolderToLayout, takeEvery);
  yield spawn(registerUniqueAction, ACTION_TYPES.DELETE_FOLDER.REQUEST, deleteFolderFromLayout, takeEvery, 'assetFolderLayout');
  yield spawn(registerUniqueAction, ACTION_TYPES.UPDATE_FOLDER_LAYOUT.REQUEST, updateFolderLayout, takeEvery);
}
// use registerUniqueAction for listBased sagas, otherwise they will be duplicated because of handleFolderSelect function in main/folder.js file
