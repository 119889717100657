import {
  delay, call, select, spawn
} from 'redux-saga/effects';
import querystring from 'qs';

import { handleCustomNavigation } from '@jotforminc/utils';

import * as API from '../../api';
import { SELECTORS } from '../../store/selectors';
import { ACTION_TYPES } from '../../store/actionTypes';
import { registerUniqueAction } from '../utils';
import {
  FEATURE_LIST, DEFAULT_FOLDER_IDS, ASSET_TYPES
} from '../../constants';

import { openCreateNewTableModal } from '../../wizards/CreateNewTableModal';
import WatchmanRecorder from '../../utils/WatchmanRecorder';

const apiAction = new API.CommonActions('sheet');

export function* handleSheetFavorite({ id }) {
  yield delay(500);
  const sheet = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.SHEET));
  const { favorite } = sheet;
  if (!sheet) {
    return false;
  }
  const newItem = { favorite: favorite };
  yield call(apiAction.updateItem, id, newItem);
}

export function* handleCreateWizard({
  toFolder, folderID: folderId, teamProperties, initialView, isSideBar, newRootSelector, isEmbeddedToMixedCreationFlow, createAssetFrom, targetText = '', onWizardMount = f => f, isMixPageUser
}) {
  const user = yield select(SELECTORS.getUser);
  const isAssetsFolderActive = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT));
  const teamID = toFolder ? teamProperties?.id : yield select(SELECTORS.getCurrentTeamID);
  const folderID = toFolder ? folderId : yield select(SELECTORS.getSelectedFolderIDForCreation);
  const logAbTestAction = yield select(SELECTORS.getUserLogAbTestAction);
  const isMobileDevice = yield select(SELECTORS.getIsMobileDevice);
  const isCreationModalTestUser = yield select(SELECTORS.getIsCreationModalTestUser);

  try {
    const isNewCreateFlowActive = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.CREATE_NEW_TABLE_FLOW));

    if (!isNewCreateFlowActive && !isEmbeddedToMixedCreationFlow) {
      const parameters = {
        ...(!!teamID && { teamID }),
        ...(isAssetsFolderActive && !!folderID && { folderID })
      };
      handleCustomNavigation(`/tables/new?${querystring.stringify(parameters)}`, '_blank');
    } else {
      yield call(openCreateNewTableModal, {
        user,
        creationLogger: actionEvent => {
          WatchmanRecorder.trackEvent('click', `create-table-button${isSideBar ? '-sideBar' : ''}-${actionEvent}-table-created`, 'tables', true);
          if (createAssetFrom && logAbTestAction) {
            logAbTestAction({ action: 'click', target: `${isMobileDevice ? 'mobile-' : ''}${isSideBar ? 'sideBar-' : ''}${targetText}${createAssetFrom}-${actionEvent}-table-created` });
            logAbTestAction({ action: 'click', target: 'actTest-tableCreated' });
          }
        },
        teamID,
        ...(isAssetsFolderActive ? { folderID } : {}),
        onWizardMount,
        initialView,
        newRootSelector,
        isEmbeddedToMixedCreationFlow,
        isMixAssetCreationModal: isCreationModalTestUser || isMixPageUser,
        backToMixModalActionLogger: () => WatchmanRecorder.trackEventForCustomProject('click', `${isMobileDevice ? 'mobile-' : ''}back-to-mix-modal-from-create-tables`, 'mixAssetCreationModal', true)
      });
    }
  } catch (e) {
    console.log('error:', e);
  }
}

export function* getSheetFolderSummary(folderInfo) {
  const { sharedSheetsCount = 0 } = yield call(API.getTablesSummary);
  const newFolderList = folderInfo.folderList.map(folder => {
    switch (folder.id) {
      case DEFAULT_FOLDER_IDS.SHARED_REPORTS:
      case DEFAULT_FOLDER_IDS.SHARED_REPORT_SEPERATOR: {
        return { ...folder, count: sharedSheetsCount };
      }
      default:
        return folder;
    }
  });

  return {
    ...folderInfo,
    folderList: newFolderList
  };
}

export function* rootSheetFlow() {
  yield spawn(registerUniqueAction, ACTION_TYPES.CREATE_WIZARD, handleCreateWizard);
  yield spawn(registerUniqueAction, ACTION_TYPES.SET_FAVORITE, handleSheetFavorite);
}
// use registerUniqueAction for listBased sagas, otherwise they will be duplicated because of handleFolderSelect function in main/folder.js file
