import {
  select, call, put, spawn, take, all
} from 'redux-saga/effects';
import { channel } from 'redux-saga';
import get from 'lodash/get';
import { handleCustomNavigation } from '@jotforminc/utils';
import * as API from '../../api';
import { SELECTORS } from '../../store/selectors';
import { ACTION_CREATORS } from '../../store/actionCreators';
import { ACTION_TYPES } from '../../store/actionTypes';
import { openCreateNewReportModal } from '../../wizards/CreateNewReportModal';
import {
  LISTING_API_END_POINT_MAP, LISTING_TYPES, FEATURE_LIST, DEFAULT_FOLDER_IDS, ASSET_TYPES, FOLDER_TYPES
} from '../../constants';
import { openEditReportModal } from '../../wizards/ReportEditModal';
import { ListActionToast } from '../../contexts/list/utils';
import { areTheSameItems, getAssetTypeFromListingType } from '../../utils';
import { registerUniqueAction } from '../utils';
import { handleFetchAllForms } from './form';
import WatchmanRecorder from '../../utils/WatchmanRecorder';

export function* handleCloneReport({ id }) {
  try {
    const result = yield call(API.cloneReport, id);
    const { id: cloneID } = result;
    if (!result) {
      yield put(ACTION_CREATORS.cloneItemError(id));
      return;
    }

    yield put(ACTION_CREATORS.fetchListRequest());
    yield put(ACTION_CREATORS.selectItem(cloneID, true, ASSET_TYPES.REPORT));
  } catch (e) {
    yield put(ACTION_CREATORS.cloneItemError(id));
  }
}

export function* handleFavoriteReport({ id, favoriteKey }) {
  const report = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.REPORT));
  const star = get(report, favoriteKey);
  yield call(API.favoriteReport, id, star);
}

const fetchAllFormsChannel = channel();

export function* handleCreateWizard({
  selectedID, setReportList, reportType = false, toFolder, folderID: folderId, teamProperties, isSideBar, newRootSelector, isEmbeddedToMixedCreationFlow, createAssetFrom, onWizardMount = f => f,
  targetText = '', isMixPageUser
}) {
  try {
    const currentPage = yield select(SELECTORS.getCurrentPage);
    const isAssetsFolderActive = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT));
    const currentTeamID = toFolder ? teamProperties?.id : yield select(SELECTORS.getCurrentTeamID);
    const folderID = toFolder ? folderId : yield select(SELECTORS.getSelectedFolderIDForCreation);
    const logAbTestAction = yield select(SELECTORS.getUserLogAbTestAction);
    const isMobileDevice = yield select(SELECTORS.getIsMobileDevice);
    const isCreationModalTestUser = yield select(SELECTORS.getIsCreationModalTestUser);

    const userCredentials = yield select(SELECTORS.getUserCredentials);
    let forms;
    if (currentTeamID) {
      const [
        assetFilter,
        isAllAssetFilterSelected
      ] = yield all([
        select(SELECTORS.getAssetFilter),
        select(SELECTORS.getIsAllAssetFilterTypesSelected)
      ]);
      forms = yield call(API.fetchTeamAssets, {
        selectedFolder: currentTeamID,
        type: getAssetTypeFromListingType(LISTING_TYPES.FORM),
        disableJotFormNormalize: false,
        assetFilter,
        currentPage,
        isAllAssetFilterSelected,
        folderType: FOLDER_TYPES.TEAM,
        skipV2: true
      });
    } else {
      forms = [LISTING_TYPES.FORM, LISTING_TYPES.REPORT].includes(currentPage) ? yield select(SELECTORS.getUserForms) : yield call(handleFetchAllForms);
    }
    const {
      formID, report, rejectCreateReport, resolveCreateReport
    } = yield call(openCreateNewReportModal, {
      fetchFormsCallback: API.fetchAllFormsInfo,
      fetchFormsSuccess: fetchedForms => { fetchAllFormsChannel.put({ fetchedForms }); },
      forms: forms.filter(form => !['DELETED', 'PURGED'].includes(form.status)),
      creationLogger: actionEvent => {
        WatchmanRecorder.trackEvent('click', `create-report-button${isSideBar ? '-sideBar' : ''}-${actionEvent?.replaceAll(' ', '-')}-report-created`, 'reports', true);
        if (createAssetFrom && logAbTestAction) {
          logAbTestAction({
            action: 'click',
            target: `${isMobileDevice ? 'mobile-' : ''}${isSideBar ? 'sideBar-' : ''}${targetText}${createAssetFrom}-${actionEvent?.replaceAll(' ', '-')}-report-created`
          });
          logAbTestAction({ action: 'click', target: 'actTest-reportCreated' });
        }
      },
      selectedID,
      ...(!!currentTeamID && { teamID: currentTeamID }),
      user: userCredentials,
      ...(!!reportType && { reportType }),
      ...(isAssetsFolderActive && !!folderID && { folderID }),
      onWizardMount,
      newRootSelector,
      isEmbeddedToMixedCreationFlow,
      isMixAssetCreationModal: isCreationModalTestUser || isMixPageUser,
      backToMixModalActionLogger: () => WatchmanRecorder.trackEventForCustomProject('click', `${isMobileDevice ? 'mobile-' : ''}back-to-mix-modal-from-create-reports`, 'mixAssetCreationModal', true)
    });

    if (report?.type === 'digest') {
      if (userCredentials?.digestBetaAccepted !== '1') {
        yield put(ACTION_CREATORS.updateUserProperty({ digestBetaAccepted: '1' }));
      }
      yield put(ACTION_CREATORS.fetchListRequest());
      yield put(ACTION_CREATORS.selectItem(report.id, true, ASSET_TYPES.REPORT));
      return;
    }

    const result = yield call(API.createReport, formID, report);
    if (!result) {
      rejectCreateReport(result);
      yield put(ACTION_CREATORS.createReportError(result));
      return;
    }

    resolveCreateReport(result);

    if (setReportList) {
      const tmpAppList = yield call(API.getFormReports, formID);
      setReportList(tmpAppList);
    }

    const { id } = result;
    if (currentPage !== LISTING_TYPES.FORM) {
      yield put(ACTION_CREATORS.fetchListRequest());
      yield put(ACTION_CREATORS.selectItem(id, true, ASSET_TYPES.REPORT));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    if (e) console.log({ e });
  }
}

export function handleUpdateReportModal({ resolveReportUpdate, result }) {
  if (resolveReportUpdate) {
    resolveReportUpdate(result);
  }
}

export function* handleReportUpdateWizard(reportInfo) {
  try {
    const {
      reportType, formID, reportID, reportData, setReportList, isAvailable = false, overrideForms = false
    } = reportInfo;
    const isTeam = yield select(SELECTORS.getIsTeamFolder);

    if (!isAvailable && isTeam && reportType === 'reports') { // FOR TEAM CASE
      yield put(ACTION_CREATORS.requestEditAction(reportID, reportInfo, ASSET_TYPES.REPORT));
    } else { // FOR INDIVIDUAL OR TEAM IS AVAILABLE CASE (sorry for next lint-line but it has to be clear)
      // eslint-disable-next-line no-lonely-if
      if (reportType === 'reports') {
        handleCustomNavigation(`/reports/${reportID}`, '_self');
      } else if (reportType === 'VISUAL') {
        handleCustomNavigation(`/page.php?p=reports&formID=${formID}&reportID=${reportID}`, '_self', true);
      } else {
        const currentTeamID = yield select(SELECTORS.getCurrentTeamID);
        let forms;
        if (Array.isArray(overrideForms)) {
          forms = overrideForms;
        } else if (currentTeamID) {
          const [
            assetFilter,
            currentPage,
            isAllAssetFilterSelected
          ] = yield all([
            select(SELECTORS.getAssetFilter),
            select(SELECTORS.getCurrentPage),
            select(SELECTORS.getIsAllAssetFilterTypesSelected)
          ]);
          forms = yield call(API.fetchTeamAssets, {
            selectedFolder: currentTeamID,
            type: getAssetTypeFromListingType(LISTING_TYPES.FORM),
            disableJotFormNormalize: false,
            assetFilter,
            currentPage,
            isAllAssetFilterSelected,
            folderType: FOLDER_TYPES.TEAM,
            skipV2: true
          });
        } else {
          forms = yield call(handleFetchAllForms);
        }
        const {
          id, payload, resolveUpdateReport
        } = yield call(openEditReportModal, {
          forms, reportID, formID, reportType, reportData, teamID: currentTeamID || ''
        });

        if (payload?.type === 'digest') {
          const { id: digestId, result, forceFetchForListing } = payload;
          yield put(ACTION_CREATORS.updateItemSuccess({
            id: digestId, payload, resolveReportUpdate: resolveUpdateReport, result, assetType: ASSET_TYPES.REPORT
          }));
          if (forceFetchForListing) {
            yield put(ACTION_CREATORS.fetchListRequest());
          }
          return;
        }

        const currentPage = yield select(SELECTORS.getCurrentPage);
        if (currentPage === LISTING_TYPES.FORM) {
          try {
            const reportEndpoint = LISTING_API_END_POINT_MAP.reports;
            const tempAPI = new API.CommonActions(reportEndpoint);
            const result = yield call(tempAPI.updateItem, id, payload);
            resolveUpdateReport(result);
            const newList = yield call(API.getFormReports, formID);
            setReportList(newList);
          } catch (err) {
            console.log('error:', err);
          }
          return;
        }
        yield put(ACTION_CREATORS.updateItemRequest({
          id, payload, resolveReportUpdate: resolveUpdateReport, assetType: ASSET_TYPES.REPORT
        }));
      }
    }
  } catch (e) {
    console.log('error:', e);
  }
}

export function* handleFormDeleteReport({
  reportID,
  setReportList,
  fetchList
}) {
  try {
    const isMixEnabledPage = yield select(SELECTORS.getIsMixEnabledPage);
    const tempAction = new API.CommonActions('report');
    const conditions = JSON.stringify({ includes: [reportID] });
    const result = yield call(tempAction.bulkUpdateList, 'status', 'TRASHED', conditions);
    const selectedItemIDs = yield select(SELECTORS.getSelectedItemIDs);
    const selectedFormID = selectedItemIDs.length > 0 && selectedItemIDs[0];
    if (!selectedFormID) return;

    const list = yield select(SELECTORS.getList);
    if (!result) {
      yield put(ACTION_CREATORS.bulkUpdateListError());
      return;
    }
    const restoreCall = async () => {
      await tempAction.bulkUpdateList('status', 'ENABLED', conditions);
      const formReports = await API.getFormReports(selectedFormID);
      setReportList(formReports);
      if (isMixEnabledPage) fetchList();
    };
    const updateToast = new ListActionToast('report')?.updateToast;

    yield call(updateToast, {
      prop: 'status', value: 'TRASHED', selected: [reportID], actions: { restore: restoreCall }
    });
    const formReports = yield call(API.getFormReports, selectedFormID);
    setReportList(formReports);
    if (isMixEnabledPage) {
      const newList = list.filter(item => !areTheSameItems({ item, assetID: reportID, assetType: ASSET_TYPES.REPORT }));
      yield put(ACTION_CREATORS.setList(newList));
    }
  } catch (e) {
    // handle error
  }
}

export function* getReportFolderSummary(folderInfo) {
  try {
    const { sharedReportsCount = 0 } = yield call(API.getReportsSummary);
    const newFolderList = folderInfo.folderList.map(folder => {
      switch (folder.id) {
        case DEFAULT_FOLDER_IDS.SHARED_REPORTS:
        case DEFAULT_FOLDER_IDS.SHARED_REPORT_SEPERATOR: {
          return { ...folder, count: sharedReportsCount };
        }
        default:
          return folder;
      }
    });
    return {
      ...folderInfo,
      folderList: newFolderList
    };
  } catch (e) {
    return folderInfo;
  }
}

export function* watchReportsFetchAllFormsChannel() {
  while (true) {
    const { fetchedForms } = yield take(fetchAllFormsChannel);
    yield put(ACTION_CREATORS.fetchAllFormsSuccess(fetchedForms));
  }
}

export function* rootReportFlow() {
  yield spawn(registerUniqueAction, ACTION_TYPES.CREATE_WIZARD, handleCreateWizard);
  yield spawn(registerUniqueAction, ACTION_TYPES.SET_FAVORITE, handleFavoriteReport);
  yield spawn(registerUniqueAction, ACTION_TYPES.CLONE_ITEM.REQUEST, handleCloneReport);
  yield spawn(registerUniqueAction, ACTION_TYPES.UPDATE_ITEM.SUCCESS, handleUpdateReportModal);
  yield spawn(registerUniqueAction, ACTION_TYPES.UPDATE_REPORT_WIZARD, handleReportUpdateWizard);
  yield spawn(watchReportsFetchAllFormsChannel);
}

export function* formReportFlow() {
  yield spawn(registerUniqueAction, ACTION_TYPES.CREATE_REPORT_WIZARD, handleCreateWizard);
  yield spawn(registerUniqueAction, ACTION_TYPES.UPDATE_REPORT_WIZARD, handleReportUpdateWizard);
  yield spawn(registerUniqueAction, ACTION_TYPES.FORM_DELETE_REPORT.REQUEST, handleFormDeleteReport);
  yield spawn(watchReportsFetchAllFormsChannel);
}

export function* portalReportFlow() {
  yield spawn(registerUniqueAction, ACTION_TYPES.UPDATE_REPORT_WIZARD, handleReportUpdateWizard);
}
// use registerUniqueAction for listBased sagas, otherwise they will be duplicated because of handleFolderSelect function in main/folder.js file
