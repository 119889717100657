import React, {
  useRef, useCallback, useState, useEffect
} from 'react';
import { func, string } from 'prop-types';
import { t, translationRenderer } from '@jotforminc/translation';
import { Modal } from '@jotforminc/uikit';
import { Button } from '@jotforminc/magnet';
import '../../styles/agent-beta-launch-modal.scss';
import { setCookie } from '@jotforminc/utils';

import AgentsLeft from '../../assets/modals/agent-beta-launch-modal/left.png';
import AgentsRight from '../../assets/modals/agent-beta-launch-modal/right.png';
import Shadow from '../../assets/modals/agent-beta-launch-modal/shadow.svg';

const AgentBetaLaunchModal = ({
  username,
  tracker = f => f,
  handleClose = f => f
}) => {
  const [dismissed, setDismissed] = useState(false);

  const target = 'agentBetaLaunchModal';
  const contentRef = useRef();
  const uikitModalRef = useRef();

  useEffect(() => {
    tracker({ action: 'seen', target, actor: username });
  }, []);

  const DialogRenderer = useCallback(
    ({ children }) => (
      <div onKeyDown={f => f} className="jfBasicModal agentBetaLaunchModal">
        <div className="jfBasicModal-container">
          {children}
          <div className="agentBetaLaunchModal-neverShowGroup">
            <label
              className="agentBetaLaunchModal-neverShowLabel"
              htmlFor="agentBetaLaunchModal-neverShowCheckbox"
            >
              <input
                type="checkbox"
                id="agentBetaLaunchModal-neverShowCheckbox"
                name="agentBetaLaunchModal-neverShowCheckbox"
                onChange={() => {
                  tracker({
                    action: `toggle-${dismissed ? 'off' : 'on'}-dismiss`,
                    target,
                    actor: username
                  });
                  setDismissed(!dismissed);
                }}
              />
              {t("Don't show this again")}
            </label>
          </div>
        </div>
      </div>
    ),
    [handleClose]
  );

  const ContentRenderer = useCallback(
    ({ children }) => (
      <div className="jfBasicModal-content-wrapper">
        <div className="jfBasicModal-content" ref={contentRef}>
          {children}
        </div>
      </div>
    ),
    [contentRef]
  );

  const handleCloseModal = () => {
    tracker({ action: 'click-close-button', target, actor: username });

    if (document.body.classList.contains('agentBetaLaunchModal--isVisible')) {
      document.body.classList.remove('agentBetaLaunchModal--isVisible');
    }

    handleClose();
  };

  const FooterRenderer = () => <></>;
  const HeaderRenderer = () => (
    <Button
      colorStyle="transparent"
      className="absolute lg:-top-2 lg:-right-16 top-4 right-0 z-4"
      rounded={true}
      aria-label={t('Close Agent Beta Launch Modal')}
      onClick={() => {
        setCookie('dismissAiAgentBetaLightbox', '1', dismissed ? 365 : 1);
        handleCloseModal();
      }}
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          // eslint-disable-next-line max-len
          d="M27.8452 14.5119C28.496 13.861 28.496 12.8057 27.8452 12.1548C27.1943 11.504 26.139 11.504 25.4881 12.1548L20 17.643L14.5118 12.1548C13.861 11.504 12.8057 11.504 12.1548 12.1548C11.5039 12.8057 11.5039 13.861 12.1548 14.5119L17.643 20L12.1548 25.4882C11.5039 26.139 11.5039 27.1943 12.1548 27.8452C12.8057 28.4961 13.861 28.4961 14.5118 27.8452L20 22.357L25.4881 27.8452C26.139 28.4961 27.1943 28.4961 27.8452 27.8452C28.496 27.1943 28.496 26.139 27.8452 25.4882L22.357 20L27.8452 14.5119Z"
          fill="#0A1551"
        />
      </svg>
    </Button>
  );

  const handleModalClose = type => {
    tracker({ action: `close-on-${type}-click`, target, actor: username });
    if (document.body.classList.contains('agentBetaLaunchModal--isVisible')) {
      document.body.classList.remove('agentBetaLaunchModal--isVisible');
    }
  };

  return (
    <Modal
      usePortal={true}
      ref={uikitModalRef}
      defaultVisible={true}
      closeOnEscPress={false}
      closeOnOutsideClick={true}
      DialogRenderer={DialogRenderer}
      FooterRenderer={FooterRenderer}
      ContentRenderer={ContentRenderer}
      HeaderRenderer={HeaderRenderer}
      exceptionalSelectorList={[
        '.jfWizard-modal',
        '#CookieBanner',
        '.agentBetaLaunchModal-neverShowGroup'
      ]}
      onModalClose={() => handleModalClose('outside')}
    >
      <div className="agentBetaLaunchModal-container">
        <div className="agentBetaLaunchModal-wrapper">
          <div className="left-image">
            <img src={AgentsLeft} alt="Agents Left Banner" />
          </div>
          <div className="wrapper">
            <div className="badge">
              <span className="circle" />
              BETA
            </div>
            <strong className="title">{t('AI Agents')}</strong>
            <span className="subtitle">{t('COMING SOON')}</span>
            <div className="content">
              <p>
                {translationRenderer('You’ve been added to our [1[exclusive Beta Program.]]')({
                  renderer1: text => <strong>{text}</strong>
                })}
              </p>
              <p>{t('Turn your forms into dynamic conversations today.')}</p>
            </div>
            <div className="buttons">
              <a
                href="/myagents/?action=createWizard"
                onClick={() => {
                  tracker({
                    target,
                    actor: username,
                    action: 'click-create-agent-btn'
                  });
                }}
                className="first"
              >
                {t('Create Your Agent')}
              </a>
              <a
                className="second"
                onClick={() => {
                  tracker({
                    target,
                    actor: username,
                    action: 'click-browse-agent-btn'
                  });
                }}
                href="/ai-agents"
              >
                {t('Learn More')}
              </a>
            </div>
          </div>
          <div className="right-image">
            <img src={AgentsRight} alt="Agents Right Banner" />
          </div>
          <div className="shadow">
            <Shadow />
          </div>
        </div>
      </div>
    </Modal>
  );
};

AgentBetaLaunchModal.propTypes = {
  username: string,
  tracker: func,
  handleClose: func
};

export default AgentBetaLaunchModal;
