import {
  call, select, spawn
} from 'redux-saga/effects';
import { ACTION_TYPES } from '../../store/actionTypes';
import {
  ASSET_TYPE_MAP, LISTING_TYPES, FEATURE_LIST, FOLDER_TYPES
} from '../../constants';
import { SELECTORS } from '../../store/selectors';
import {
  handleFormCreateWizard, handleFormClone, handlePurgeItem, handleRestoreItem,
  handleArchiveItem, handleCreateApp, handleMarkSelectedRead, handleCreateDigest, watchFetchAllFormsChannel
} from './form';
import { handleCreateWizard as handleSheetCreateWizard } from './sheet';
import {
  handleCreateWizard as handleReportCreateWizard, handleCloneReport, handleReportUpdateWizard, handleUpdateReportModal, handleFormDeleteReport
} from './report';
import {
  handleCreateWizard as handleTaskCreateWizard, handleApprovalClone, handleDeleteItem, handlePurgeItem as handleTaskPurgeItem
} from './approval';
import {
  handleCreateWizard as handleDocumentCreateWizard, handleDeleteSignDocument, handleCloneSignDocument, handleRenameSignDocument
} from './document';
import { handleCreateWizard as handleBoardCreateWizard } from './board';
import { handleCreateWizard as handlePortalCreateWizard, handleAppClone, handleCreateDigest as handleCreateDigestForPortal } from './portal';
import {
  handleCreateWizard as handleAgentCreateWizard, handleCloneAgent, handleDeleteAgent, handleRenameAgent, handleArchiveAgent, handleEnableAgent, handleDisableAgent, handleRestoreAgent,
  handlePurgeAgent
} from './agent';
import { favoriteTeamAsset, fetchTeamAssets } from '../../api';
import { registerUniqueAction } from '../utils';
import { openMixAssetModal } from '../../modals/MixAssetModal/utils';

const LIST_BASED_HANDLERS = {
  createWizard: {
    [LISTING_TYPES.FORM]: handleFormCreateWizard,
    [LISTING_TYPES.SHEET]: handleSheetCreateWizard,
    [LISTING_TYPES.REPORT]: handleReportCreateWizard,
    [LISTING_TYPES.TASK]: handleTaskCreateWizard,
    [LISTING_TYPES.DOCUMENT]: handleDocumentCreateWizard,
    [LISTING_TYPES.PORTAL]: handlePortalCreateWizard,
    [LISTING_TYPES.BOARD]: handleBoardCreateWizard,
    [LISTING_TYPES.AGENT]: handleAgentCreateWizard
  },
  clone: {
    [LISTING_TYPES.FORM]: handleFormClone,
    [LISTING_TYPES.REPORT]: handleCloneReport,
    [LISTING_TYPES.TASK]: handleApprovalClone,
    [LISTING_TYPES.PORTAL]: handleAppClone
  },
  purge: {
    [LISTING_TYPES.FORM]: handlePurgeItem,
    [LISTING_TYPES.TASK]: handleTaskPurgeItem,
    [LISTING_TYPES.AGENT]: handlePurgeAgent
  },
  restore: {
    [LISTING_TYPES.FORM]: handleRestoreItem
  },
  archive: {
    [LISTING_TYPES.FORM]: handleArchiveItem
  },
  delete: {
    [LISTING_TYPES.TASK]: handleDeleteItem
  },
  deleteSignDocument: {
    [LISTING_TYPES.DOCUMENT]: handleDeleteSignDocument
  },
  cloneSignDocument: {
    [LISTING_TYPES.DOCUMENT]: handleCloneSignDocument
  },
  renameSignDocument: {
    [LISTING_TYPES.DOCUMENT]: handleRenameSignDocument
  },
  deleteAgent: {
    [LISTING_TYPES.AGENT]: handleDeleteAgent
  },
  cloneAgent: {
    [LISTING_TYPES.AGENT]: handleCloneAgent
  },
  renameAgent: {
    [LISTING_TYPES.AGENT]: handleRenameAgent
  },
  archiveAgent: {
    [LISTING_TYPES.AGENT]: handleArchiveAgent
  },
  enableAgent: {
    [LISTING_TYPES.AGENT]: handleEnableAgent
  },
  disableAgent: {
    [LISTING_TYPES.AGENT]: handleDisableAgent
  },
  restoreAgent: {
    [LISTING_TYPES.AGENT]: handleRestoreAgent
  },
  createDigest: {
    [LISTING_TYPES.FORM]: handleCreateDigest,
    [LISTING_TYPES.PORTAL]: handleCreateDigestForPortal
  }
};

function* handleCreateWizard(props) {
  const currentPage = yield select(SELECTORS.getCurrentPage);
  const { toFolder, folderID: folderId } = props;
  if (currentPage === LISTING_TYPES.TEAM_PAGE) {
    const teamPermissions = yield select(SELECTORS.getTeamPermissions);
    const folderID = toFolder ? folderId : yield select(SELECTORS.getSelectedFolderIDForCreation);
    const isAssetsFolderActive = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT));
    const isMobileDevice = yield select(SELECTORS.getIsMobileDevice);
    const user = yield select(SELECTORS.getUserCredentials);
    if (!isAssetsFolderActive && folderID) {
      yield call(LIST_BASED_HANDLERS.createWizard[LISTING_TYPES.FORM], props || {});
    } else {
      const currentTeamID = yield select(SELECTORS.getCurrentTeamID);

      const forms = yield call(fetchTeamAssets, {
        currentTeamID,
        selectedFolder: currentTeamID,
        query: {
          offset: 0,
          limit: 1,
          orderby: 'created_at',
          status: 'active'
        },
        disableJotFormNormalize: false,
        isAllAssetFilterSelected: false,
        currentPage,
        assetFilter: [LISTING_TYPES.FORM],
        folderType: FOLDER_TYPES.TEAM
      });

      import('../../wizards/LazyWizards/LazyCreateNewFormWizard');
      import('../../wizards/LazyWizards/LazyCreateNewPortalWizard');
      import('../../wizards/LazyWizards/LazyCreateNewDocumentWizard');
      import('../../wizards/LazyWizards/LazyCreateNewTableWizard');
      import('../../wizards/LazyWizards/LazyCreateNewWorkflowWizard');
      import('../../wizards/LazyWizards/LazyCreateNewReportWizard');

      yield call(openMixAssetModal, {
        user,
        hasUserForms: !!forms?.length,
        isMobileDevice,
        isSideBar: props?.isSideBar,
        isMixPageUser: true,
        isTeamAssetCreation: true,
        teamPermissions,
        folderID,
        isAssetsFolderActive
      });
    }
  } else {
    const assetFilterType = yield select(SELECTORS.getAssetFilterType);
    yield call(LIST_BASED_HANDLERS.createWizard[assetFilterType], props || {});
  }
}

function* handleFavorite({ id, assetType }) {
  const { teamFavorite = false } = yield select(SELECTORS.getItemByIDAndType(id, assetType));
  const teamID = yield select(SELECTORS.getCurrentTeamID);
  yield call(favoriteTeamAsset, teamID, id, assetType === 'board' ? 'sheet' : assetType, !!teamFavorite);
}

function getActionHandler(handlerType) {
  function* actionHandler(action) {
    const { id } = action;
    let { assetType } = action;
    let handler;
    if (!assetType) {
      const asset = yield select(SELECTORS.getItemByID(id));
      assetType = asset?.assetType;
    }

    if (Object.keys(ASSET_TYPE_MAP).indexOf(assetType) > -1) {
      handler = LIST_BASED_HANDLERS[handlerType][ASSET_TYPE_MAP[assetType]];
    } else if (Object.values(LISTING_TYPES).indexOf(assetType) > -1) {
      handler = LIST_BASED_HANDLERS[handlerType][assetType];
    }

    if (handler) {
      yield call(handler, action);
    }
  }
  return actionHandler;
}

export function* rootTeamFlow() {
  yield spawn(registerUniqueAction, ACTION_TYPES.CREATE_WIZARD, handleCreateWizard);
  yield spawn(registerUniqueAction, ACTION_TYPES.SET_FAVORITE, handleFavorite);
  yield spawn(registerUniqueAction, ACTION_TYPES.CLONE_ITEM.REQUEST, getActionHandler('clone'));
  yield spawn(registerUniqueAction, ACTION_TYPES.PURGE_ITEM, getActionHandler('purge'));
  yield spawn(registerUniqueAction, ACTION_TYPES.RESTORE_ITEM, getActionHandler('restore'));
  yield spawn(registerUniqueAction, ACTION_TYPES.SET_ARCHIVED, getActionHandler('archive'));
  yield spawn(registerUniqueAction, ACTION_TYPES.BULK_MARK_AS_READ, handleMarkSelectedRead);
  yield spawn(registerUniqueAction, ACTION_TYPES.CREATE_APP, handleCreateApp);
  yield spawn(registerUniqueAction, ACTION_TYPES.DELETE_SIGN_DOCUMENT.REQUEST, getActionHandler('deleteSignDocument'));
  yield spawn(registerUniqueAction, ACTION_TYPES.CLONE_SIGN_DOCUMENT.REQUEST, getActionHandler('cloneSignDocument'));
  yield spawn(registerUniqueAction, ACTION_TYPES.RENAME_SIGN_DOCUMENT.REQUEST, getActionHandler('renameSignDocument'));
  yield spawn(registerUniqueAction, ACTION_TYPES.CREATE_REPORT_WIZARD, handleReportCreateWizard);
  yield spawn(registerUniqueAction, ACTION_TYPES.CREATE_DIGEST, getActionHandler('createDigest'));
  yield spawn(registerUniqueAction, ACTION_TYPES.UPDATE_REPORT_WIZARD, handleReportUpdateWizard);
  yield spawn(registerUniqueAction, ACTION_TYPES.UPDATE_ITEM.SUCCESS, handleUpdateReportModal);
  yield spawn(registerUniqueAction, ACTION_TYPES.DELETE_TASK_ITEM.REQUEST, getActionHandler('delete'));
  yield spawn(registerUniqueAction, ACTION_TYPES.FORM_DELETE_REPORT.REQUEST, handleFormDeleteReport);
  yield spawn(registerUniqueAction, ACTION_TYPES.DELETE_AGENT.REQUEST, getActionHandler('deleteAgent'));
  yield spawn(registerUniqueAction, ACTION_TYPES.CLONE_AGENT.REQUEST, getActionHandler('cloneAgent'));
  yield spawn(registerUniqueAction, ACTION_TYPES.RENAME_AGENT.REQUEST, getActionHandler('renameAgent'));
  yield spawn(registerUniqueAction, ACTION_TYPES.ARCHIVE_AGENT.REQUEST, getActionHandler('archiveAgent'));
  yield spawn(registerUniqueAction, ACTION_TYPES.ENABLE_AGENT.REQUEST, getActionHandler('enableAgent'));
  yield spawn(registerUniqueAction, ACTION_TYPES.DISABLE_AGENT.REQUEST, getActionHandler('disableAgent'));
  yield spawn(registerUniqueAction, ACTION_TYPES.RESTORE_AGENT.REQUEST, getActionHandler('restoreAgent'));
  yield spawn(watchFetchAllFormsChannel);
}
// use registerUniqueAction for listBased sagas, otherwise they will be duplicated because of handleFolderSelect function in main/folder.js file
