import {
  select, call, all, take
} from 'redux-saga/effects';
import { SELECTORS } from '../../store/selectors';
import { renderExperienceModals } from '../../campaigns';
import { shouldShowAutoVisibleNotification } from '../../utils/ExperienceUtils';
import { MODAL_TYPES } from '../../constants';
import { ACTION_TYPES } from '../../store/actionTypes';

/*
  Why we need jotform enterprise experience?

  Enterprise admins are part of the lunch and learn banner webinar series as an audience.
  https://www.jotform.com/enterprise/lunch-and-learn-sessions/
*/

function* checkThenRenderModals() {
  const [user, userType, currentPage, accountTypeName] = yield all([
    select(SELECTORS.getUser),
    select(SELECTORS.getUserType),
    select(SELECTORS.getCurrentPage),
    select(SELECTORS.getUserAccountTypeName)
  ]);

  const { credentials } = user;
  let modalType;
  let campaignData;

  if (yield call(shouldShowAutoVisibleNotification, {
    credentials, userType, currentPage, enterpriseMode: true, accountTypeName
  })) {
    modalType = MODAL_TYPES.EXPERIENCE_NOTIFICATION_CENTER;
  } else {
    return;
  }

  yield call(renderExperienceModals, modalType, credentials, campaignData);
}

export function* rootJotformEnterpriseExperienceFlows() {
  yield take(ACTION_TYPES.FETCH_USER.SUCCESS);
  yield checkThenRenderModals();
}
