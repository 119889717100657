// It's app picker navigation item badge
// DUE TO DIFFERENT DESIGN RULES, IT WAS CREATED AS A CUSTOM COMPONENT OTHER THAN THE MAGNET BADGE.

import { Flex } from '@jotforminc/magnet';
import React from 'react';
import PropTypes from 'prop-types';

const NavigationItemBadge = ({ text = '', selected = false }) => {
  return (
    <Flex
      direction="column" alignItems="center" justifyContent="center"
      className={`absolute w-auto h-full min-h-4 max-h-4 text-xxs line-height-xxs font-medium px-1.5 ${!selected ? 'bg-blue-500 color-white' : 'bg-navy-75 color-navy-700'}`}
      style={{
        top: selected ? '-1px' : '0', // for border box top value reference
        right: selected ? '-1px' : '0', // for border box right value reference
        borderBottomLeftRadius: '4px',
        borderTopRightRadius: '4px'
      }}
    >
      {text}
    </Flex>
  );
};

NavigationItemBadge.propTypes = {
  text: PropTypes.string,
  selected: PropTypes.bool
};

export default NavigationItemBadge;
