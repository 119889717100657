import { autoTranslate, getUrlParameter } from '@jotforminc/utils';

const isWorkflowReleased = window?.isWorkflowReleased;
const hasIntegration = getUrlParameter('integration').length > 0;
const getTextByKey = key => {
  switch (key) {
    case 'CREATE_AN_APPROVAL_FORM':
      if (hasIntegration) return 'Add the {integration} Integration to a Workflow';
      return isWorkflowReleased ? 'Create a Workflow' : 'Create an Approval Flow';
    case 'CREATE_AN_APPROVAL_FORM_SUBTITLE':
      if (hasIntegration) {
        return 'Choose an option to create your workflow and add the integration';
      }
      return isWorkflowReleased ? 'Choose an option to create your workflow and automate your process.' : 'Create an approval flow to automate your approval process';
    default:
      return '';
  }
};

export default autoTranslate({
  CREATE_AN_APPROVAL_FORM: getTextByKey('CREATE_AN_APPROVAL_FORM'),
  CREATE_AN_APPROVAL_FORM_SUBTITLE: getTextByKey('CREATE_AN_APPROVAL_FORM_SUBTITLE'),
  START_FROM_SCRATCH: 'Start From Scratch',
  START_FROM_SCRATCH_SUBTEXT: isWorkflowReleased ? 'Create a workflow from scratch' : 'Create an approval flow from scratch',
  ONE_STEP_APPROVAL: 'One Step Approval',
  ONE_STEP_APPROVAL_SUBTEXT: isWorkflowReleased ? 'Start with a single-step approval workflow' : 'Create an approval flow with one step only',
  USE_TEMPLATE: 'Use Template',
  USE_TEMPLATE_SUBTEXT: isWorkflowReleased ? 'Get started instantly with a workflow template' : 'Get started with an approval process template instantly.',
  CHOOSE_A_TEMPLATE: 'Choose a template',
  CHOOSE_A_TEMPLATE_SUBTITLE: 'Explore 10,000+ ready-made templates to create a form in minutes',
  CHOOSE_APPROVAL_TEMPLATE_SUBTITLE: isWorkflowReleased
    ? 'Select a template from the largest selection of free workflow templates available online.'
    : 'Select a template from the largest selection of free approval templates available online.',
  CLONE_APPROVAL_FLOW_WITH_FORM: isWorkflowReleased
    ? 'Clone workflow and forms'
    : 'Clone approval flow and forms',
  CLONE_APPROVAL_FLOW_WITH_FORM_TEXT: isWorkflowReleased
    ? 'Clone workflow and forms'
    : 'Clone approval and forms',
  CLONE_APPROVAL_FLOW: isWorkflowReleased
    ? 'Clone workflow'
    : 'Clone approval flow',
  CLONE_MODAL_TEXT: isWorkflowReleased
    ? 'Clone Workflow'
    : 'Clone Approval',
  CLONE_MODAL_DESCRIPTION: isWorkflowReleased
    ? 'Duplicate your flow, including all workflow elements and settings.'
    : 'Duplicate your flow, including all approval elements and settings.',
  CLONE_MODAL_INPUT: isWorkflowReleased
    ? 'Workflow Name'
    : 'Approval Name',
  CLONE_MODAL_SETTINGS: isWorkflowReleased
    ? 'Workflow Settings'
    : 'Approval Settings',
  MAIN_STEP_START_FROM_SCRATCH: 'Start from scratch',
  MAIN_STEP_ONE_STEP_APPROVAL: isWorkflowReleased ? 'One step flow' : 'One step approval',
  MAIN_STEP_USE_TEMPLATE: 'Use template'
});
