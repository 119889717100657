import {
  delay, call, select, put, spawn, all
} from 'redux-saga/effects';
import querystring from 'qs';
import { t } from '@jotforminc/translation';
import { handleCustomNavigation, pushDataLayer, getUrlParameter } from '@jotforminc/utils';
import { openCloneApprovalModal } from '@jotforminc/create-new-wizards';
import * as API from '../../api';
import {
  ALL_ASSETS_ID, DEFAULT_FOLDER_IDS, FEATURE_LIST, LISTING_TYPES, isWorkflowReleased, ASSET_TYPES
} from '../../constants';
import { registerUniqueAction } from '../utils';
import { SELECTORS } from '../../store/selectors';
import { ACTION_TYPES } from '../../store/actionTypes';
import { ACTION_CREATORS } from '../../store/actionCreators';
import ConfirmationDialog from '../../utils/Confirmation';
import { openCreateNewWorkflowModal } from '../../wizards/CreateNewWorkflowModal';
import WatchmanRecorder from '../../utils/WatchmanRecorder';
import { findItemByIDAndType } from '../../utils';

export function* handleApprovalFavorite(value) {
  try {
    const { id } = value;
    yield delay(500);
    const workflow = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.WORKFLOW));
    if (!workflow) {
      return false;
    }
    const { favorite } = workflow;
    yield API.updateApprovalProperties(id, { favorite });
  } catch (e) {
    console.log('error', e);
  }
}

export function* handleApprovalClone({ id }) {
  try {
    const { title, form_id: formID } = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.WORKFLOW));
    const teamID = yield select(SELECTORS.getCurrentTeamID);
    const root = document.getElementById('modal-container');
    yield call(openCloneApprovalModal({
      id, title, workflowRoot: root, teamID, formID
    }));
  } catch (e) {
    yield put(ACTION_CREATORS.cloneItemError(id));
  }
}

const handleCreateApprovalGtmAction = (type = '') => {
  const workflowActionsMapper = {
    oneStep: 'create_approval_one_step',
    scratch: 'create_approval_from_scratch'
  };
  const gtmEventKey = workflowActionsMapper[type];
  if (gtmEventKey) pushDataLayer({ event: gtmEventKey });
};

export function* handleCreateWizard({
  toFolder, folderID: folderId, teamProperties, isSideBar, newRootSelector, isEmbeddedToMixedCreationFlow, createAssetFrom, targetText = '', onWizardMount = f => f, isMixPageUser
}) {
  try {
    const useTemplate = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.WORKFLOW_USE_TEMPLATE));
    const isAssetsFolderActive = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT));
    const team = toFolder ? teamProperties : yield select(SELECTORS.getCurrentTeam);
    const folderID = toFolder ? folderId : yield select(SELECTORS.getSelectedFolderIDForCreation);
    const logAbTestAction = yield select(SELECTORS.getUserLogAbTestAction);
    const isMobileDevice = yield select(SELECTORS.getIsMobileDevice);
    const isCreationModalTestUser = yield select(SELECTORS.getIsCreationModalTestUser);

    const type = yield call(openCreateNewWorkflowModal, {
      useTemplate,
      creationLogger: actionEvent => {
        WatchmanRecorder.trackEvent('click', `create-approval-button${isSideBar ? '-sideBar' : ''}-${actionEvent}-approval-created`, 'approvals', true);
        if (createAssetFrom && logAbTestAction) {
          logAbTestAction({ action: 'click', target: `${isMobileDevice ? 'mobile-' : ''}${isSideBar ? 'sideBar-' : ''}${targetText}${createAssetFrom}-${actionEvent}-approval-created` });
          logAbTestAction({ action: 'click', target: 'actTest-approvalCreated' });
        }
      },
      ...(!!team && { teamID: team.id, teamName: team.name }),
      ...(isAssetsFolderActive && !!folderID && { folderID }),
      onWizardMount,
      newRootSelector,
      isEmbeddedToMixedCreationFlow,
      isMixAssetCreationModal: isCreationModalTestUser || isMixPageUser,
      backToMixModalActionLogger: () => WatchmanRecorder.trackEventForCustomProject('click', `${isMobileDevice ? 'mobile-' : ''}back-to-mix-modal-from-create-approvals`, 'mixAssetCreationModal', true)
    });
    const integration = getUrlParameter('integration');
    const hasIntegration = integration.length > 0;
    const parameters = {
      ...(!!team?.id && { teamID: team.id }),
      ...(isAssetsFolderActive && !!folderID && { folderID }),
      ...(hasIntegration && { integration })
    };
    handleCreateApprovalGtmAction(type);
    handleCustomNavigation(`/workflow/new/${type.toLowerCase()}?${querystring.stringify(parameters)}`, '_self');
  } catch (e) {
    console.log('error:', e);
  }
}

export function* getTaskFolderSummary(folderInfo) {
  const { hasAnyApprovalFlow, notification: waitingCount = 0 } = yield call(API.getApprovalsSummary);
  return {
    selectedFolder: hasAnyApprovalFlow ? ALL_ASSETS_ID[LISTING_TYPES.TASK] : folderInfo.selectedFolder,
    folderList: folderInfo.folderList.map(folder => (folder.id !== DEFAULT_FOLDER_IDS.TASKS ? folder : { ...folder, badge: waitingCount }))
  };
}

export function* handleDeleteItem({ id }) {
  const list = yield select(SELECTORS.getList);
  const deletedItem = findItemByIDAndType({ list, assetID: id, assetType: ASSET_TYPES.WORKFLOW });
  yield all([
    call(API.updateApprovalProperties, id, { status: 'DELETED' }),
    yield put(ACTION_CREATORS.deleteItemSuccess(id, ASSET_TYPES.WORKFLOW)),
    yield put(ACTION_CREATORS.setDeletedItems(deletedItem ? [deletedItem] : []))
  ]);
}

export function* handlePurgeItem({ id }) {
  const purgeText = isWorkflowReleased ? 'Purge Workflow' : 'Purge Approval Flow';
  try {
    yield call(ConfirmationDialog, {
      title: t(purgeText),
      content: t('Deleting this flow removes all approval actions and thread data for the form, but submissions remain. This action cannot be undone.')
    });
    yield all([
      call(API.purgeFlowItem, id),
      yield put(ACTION_CREATORS.deleteItemSuccess(id, ASSET_TYPES.WORKFLOW))
    ]);
  } catch (err) {
    // Modal closed
  }
}

export function* rootTaskFlow() {
  yield spawn(registerUniqueAction, ACTION_TYPES.CREATE_WIZARD, handleCreateWizard);
  yield spawn(registerUniqueAction, ACTION_TYPES.SET_FAVORITE, handleApprovalFavorite);
  yield spawn(registerUniqueAction, ACTION_TYPES.CLONE_ITEM.REQUEST, handleApprovalClone);
  yield spawn(registerUniqueAction, ACTION_TYPES.PURGE_ITEM, handlePurgeItem);
  yield spawn(registerUniqueAction, ACTION_TYPES.DELETE_TASK_ITEM.REQUEST, handleDeleteItem);
}
// use registerUniqueAction for listBased sagas, otherwise they will be duplicated because of handleFolderSelect function in main/folder.js file
