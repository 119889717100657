import React from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { safeLazy, lazyRender } from '@jotforminc/safe-lazy-import';

import { createSignDocument } from '../api';
import WatchmanRecorder from '../utils/WatchmanRecorder';

const CreateNewDocumentWizard = safeLazy(() => import(/* webpackChunkName: "LazyWizard_CreateNewDocumentWizard" */'./LazyWizards/LazyCreateNewDocumentWizard'));
const createAssetModalContainer = document.getElementById('create-asset-modal-container');

export const openCreateNewDocumentModal = props => {
  return new Promise((resolve, reject) => {
    const containerSelector = '#modal-container';
    const root = document.querySelector(containerSelector);
    const rootElement = (props?.newRootSelector && document.getElementById(props.newRootSelector)) || root;
    const createFlowWrapperElement = document.getElementById('createFlowModal-wrapper');

    const onCreateDocument = (files, isDemo, modifyContent, variantCode) => {
      WatchmanRecorder.trackEvent('click-create-document-in-wizard');
      return createSignDocument({
        files, isDemo, modifyContent, teamID: props?.teamID || '', folderID: props?.folderID || '', variantCode
      });
    };
    const onClickListItem = (item = '') => {
      WatchmanRecorder.trackEvent(`click-${item}-in-wizard`);
    };
    const handleClose = () => {
      reject();
      window.history.replaceState({}, '', window.location.pathname);
      unmountComponentAtNode(rootElement);
      if (createAssetModalContainer && props.isEmbeddedToMixedCreationFlow) {
        unmountComponentAtNode(createAssetModalContainer);
      }
    };
    const allowDoc = !window.isHIPAA && window.JOTFORM_ENV !== 'ENTERPRISE';

    // Action tracking: sign beta
    const { allowSign, username } = window.user || {};
    if (allowSign) {
      if (!window.signActions) {
        const { JotFormActions } = window;
        if (typeof JotFormActions === 'function') {
          window.signActions = JotFormActions('documents-listings');
        }
      }
      window.signActions.tick({ actor: username, action: 'click', target: 'create-document-button' });
    }

    const onViewChange = isMain => {
      if (createFlowWrapperElement) {
        if (isMain) {
          createFlowWrapperElement.classList.remove('isHidden');
        } else {
          createFlowWrapperElement.classList.add('isHidden');
        }
      }
    };

    lazyRender({
      root: rootElement,
      children: (
        <CreateNewDocumentWizard
          {...props}
          initialVisibilityState={true}
          createDocument={onCreateDocument}
          onClickListItem={onClickListItem}
          onHide={handleClose}
          allowDoc={allowDoc}
          isSignReleased={window?.isSignReleased || false}
          onViewChange={onViewChange}
        />
      )
    });
  });
};
