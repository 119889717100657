/* eslint-disable no-async-promise-executor */
/* eslint-disable complexity */

import React, {
  useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tracking from '@jotforminc/tracking';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { t } from '@jotforminc/translation';
import { Tooltip } from '@jotforminc/tooltip';
import { checkUserCanInviteMembers } from '@jotforminc/enterprise-components';
import { ABTestManager } from '@jotforminc/abtest-manager';
import { Button } from '../../components/Button';
import {
  SHARED_FOLDERS, CREATE_BUTTON_TITLES, ITEM_NAMING_MAP, LISTING_TYPES,
  NEW_USER_MIXED_CREATION_TESTS_LIMIT_FEATURE_DATE
} from '../../constants';
import translate from '../../utils/translate';
import { getNameFromListingType, guestChecker, showAssetFilterOnTeamPage } from '../../utils';
import { SELECTORS } from '../../store/selectors';
import { ACTION_CREATORS } from '../../store/actionCreators';
import WatchmanRecorder from '../../utils/WatchmanRecorder';
import { PRODUCT_CONFIGS } from '../../modals/CreateAssetModal/constants';
import { checkUserRegistrationHour, fetchUser, fetchUserPermissions } from '../../api';

const allCreationPermissions = PRODUCT_CONFIGS.map(config => `create${config.createPermissionName || config.name}`);

// eslint-disable-next-line max-statements
const CreateButton = ({ trackName = '', isSideBar = false, className = '' }) => {
  const dispatch = useDispatch();
  const user = useSelector(SELECTORS.getUserCredentials);
  const selectedFolder = useSelector(SELECTORS.getSelectedFolder);
  const selectedFolderObj = useSelector(SELECTORS.getSelectedFolderObject);
  const currentPage = useSelector(SELECTORS.getCurrentPage);
  const assetFilterType = useSelector(SELECTORS.getAssetFilterType);
  const formUser = useSelector(SELECTORS.isFormUser);
  const isDisableAssetFilterDropdown = useSelector(SELECTORS.getIsAssetFiltersDisabled);
  const [isButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const teamPermissions = useSelector(SELECTORS.getTeamPermissions);
  const currentTeamID = useSelector(SELECTORS.getCurrentTeamID);
  const isInboxPanelOpen = useSelector(SELECTORS.getIsInboxPanelOpen);
  const isEmbedMainStepTestUser = useSelector(SELECTORS.getIsEmbedMainStepTestUser);
  const isCreationModalTestUser = useSelector(SELECTORS.getIsCreationModalTestUser);

  const productConfig = PRODUCT_CONFIGS.find(config => config.type === assetFilterType);
  let permissionName = productConfig ? `create${productConfig.createPermissionName || productConfig.name}` : 'createForm';
  let isMixAssetCreationDisabled = false;
  if (currentPage === LISTING_TYPES.MIX || (currentPage === LISTING_TYPES.TEAM_PAGE && showAssetFilterOnTeamPage())) {
    if (isDisableAssetFilterDropdown) {
      permissionName = 'createForm';
    } else if (currentTeamID) {
      isMixAssetCreationDisabled = !allCreationPermissions.find(cPerm => teamPermissions && !!teamPermissions[cPerm]);
    }
  }
  const isSharingFolderSelected = SHARED_FOLDERS.includes(selectedFolder) || (selectedFolderObj?.isShared && selectedFolderObj?.owner !== user?.username);
  const disabled = isButtonDisabled || isMixAssetCreationDisabled || (!!currentTeamID && !teamPermissions[permissionName]) || loading || (isSharingFolderSelected && !formUser);
  const canChangeTeamUserRole = checkUserCanInviteMembers() && !!currentTeamID;

  const isUserPermissionRevoked = async () => {
    try {
      setLoading(true);
      const userPermissions = await fetchUserPermissions(currentTeamID);
      setLoading(false);
      return (userPermissions && (userPermissions[permissionName] !== teamPermissions[permissionName]));
    } catch (error) {
      setLoading(false);
      return true;
    }
  };

  const sendTrackEvent = target => WatchmanRecorder.trackEvent('click', `${target}`);

  const handleCreate = async () => {
    if (currentPage === LISTING_TYPES.FORM && isEmbedMainStepTestUser) {
      if (user?.created_at && new Date(user.created_at) < new Date(NEW_USER_MIXED_CREATION_TESTS_LIMIT_FEATURE_DATE)) {
        Tracking.enableFS({ creationFlowMainStepTestExistingUser_bool: true });
      } else {
        Tracking.enableFS({ creationFlowMainStepTestNewUser_bool: true });
      }
    }

    new Promise(async resolve => {
      if (user.aiBuilderFromLanding) {
        return resolve(true);
      }

      if (user.aiFormBuilderIncrementalUpdateTest === '22601') {
        window.isAICVariant = true;
        window.AITestVariant = true;
        return resolve(true);
      }

      if (window.location.href.indexOf('createAIForm=true') > -1) {
        window.AITestVariant = true;
        return resolve(true);
      }

      if (user?.account_type?.name === 'ADMIN' && window.JOTFORM_ENV !== 'ENTERPRISE') {
        window.AITestVariant = true;
        window.isAICVariant = true;
        return resolve(true);
      }

      const isGuest = guestChecker(user?.username || '');

      const isNewIntentionalPublish = user.intentionalPublishNewUserABupdated === '22231' || user.intentionalPublishNewUserABupdated === '22221' || user.intentionalPublishNewUserAB === '16231';
      const isIntentionalPublish = user.intentionalPublishExistingUserABupdated === '22251' || user.intentionalPublishExistingUserABupdated === '22261';

      if (window.JOTFORM_ENV !== 'ENTERPRISE' && user?.location?.country_code === 'US' && !isGuest && !isIntentionalPublish && !isNewIntentionalPublish) {
        const userVariantIDForAIFormBuilder = user.aiFormBuilderBetaUser;
        const userVariantIDForIncrementalUpdate = user.aiFormBuilderIncrementalUpdateTest;

        let isUserSignUpIn24Hours = false;

        if (!userVariantIDForIncrementalUpdate) {
          try {
            const response = await checkUserRegistrationHour(24);
            isUserSignUpIn24Hours = response?.message;
          } catch (error) {
            isUserSignUpIn24Hours = false;
          }
        }

        if (!userVariantIDForIncrementalUpdate && isUserSignUpIn24Hours && !userVariantIDForAIFormBuilder) {
          const { credentials } = await fetchUser();

          const abTestManager = new ABTestManager({
            user: user,
            isTestEnabled: true,
            testName: 'aiFormBuilderIncrementalUpdateTest',
            testVariantCode: '22601',
            controlVariantCode: '22591',
            urlParam: 'createAIForm',
            customUserChecks: {
              isNotNewIntentionalAB: !['22261', '22251'].includes(credentials?.intentionalPublishExistingUserABupdated),
              isNotOldIntentionalAB: !['22231', '22221'].includes(credentials?.intentionalPublishNewUserABupdated) && !['16231'].includes(credentials?.intentionalPublishNewUserAB)
            }
          });

          const isTestVariantCode = await abTestManager.isTestVariant();

          if (isTestVariantCode) {
            window.AITestVariant = true;
            window.isAICVariant = true;
            return resolve(true);
          }
        } else if (userVariantIDForAIFormBuilder === '16861' || userVariantIDForIncrementalUpdate === '22601') {
          window.AITestVariant = true;
          return resolve(true);
        }
      }
      resolve(true);
    }).then(async () => {
      if (canChangeTeamUserRole && await isUserPermissionRevoked()) {
        window.location.reload();
      } else {
        const target = trackName || `create${getNameFromListingType(currentPage)}Button`;
        if (currentPage === LISTING_TYPES.TEAM_PAGE) {
          WatchmanRecorder.trackEventForCustomProject('click', `${target}`, 'teams', true);
        } else {
          sendTrackEvent(target);
        }
        if (isInboxPanelOpen) {
          dispatch(ACTION_CREATORS.toggleInboxPanel(false));
        }
        dispatch(ACTION_CREATORS.createWizard({ isSideBar }));
      }
    });
  };

  // This class is used in common/formUserMigrator. If you delete it form users can't create approvals/apps..
  const getExtraClass = () => {
    switch (currentPage) {
      case LISTING_TYPES.ASSIGNED_FORM:
        return 'forCreateForm';
      case LISTING_TYPES.TASK:
        return 'js-isCreateApprovalButton';
      case LISTING_TYPES.PORTAL:
        return 'js-isCreateAppsButton';
      default:
        return '';
    }
  };

  // Track impressions
  useEffect(() => {
    if (currentPage === LISTING_TYPES.PORTAL) {
      WatchmanRecorder.trackEvent('seen', 'createPortalButton');
    }
  }, []);

  const CreateButtonMarkup = () => {
    const buttonText = (isEmbedMainStepTestUser || isCreationModalTestUser) && currentPage === LISTING_TYPES.FORM ? CREATE_BUTTON_TITLES[LISTING_TYPES.MIX] : CREATE_BUTTON_TITLES[currentPage];
    return (
      <Button
        disabled={disabled}
        className={classNames(
          'createForm',
          className,
          getExtraClass(),
          { disabled }
        )}
        wide="true"
        type="button"
        onClick={handleCreate}
      >
        {translate(buttonText)}
      </Button>
    );
  };

  const Wrapper = useCallback(({ children }) => {
    if (!disabled) {
      return children;
    }
    const TooltipContainer = styled.div`
      width: 100%;
      .jfTooltipNew-hoverTarget {
        position: relative;
      }
      .jfTooltipNew-body {
        width: 274px
      }
    `;
    return (
      <TooltipContainer>
        <div className="jfTooltipNew-hoverTarget">
          {children}
          <Tooltip
            v2
            wrapperClassName="tooltip"
            align="Center"
            attach="Bottom"
            style={{
              backgroundColor: '#141E46'
            }}
          >
            {!loading && t(`You don't have permission to create ${ITEM_NAMING_MAP[currentPage]}${isSharingFolderSelected ? ' to this shared folders' : ''}`)}
          </Tooltip>
        </div>
      </TooltipContainer>
    );
  }, [disabled]);

  return (
    <Wrapper>
      <CreateButtonMarkup />
    </Wrapper>
  );
};

CreateButton.propTypes = {
  trackName: PropTypes.string,
  className: PropTypes.string,
  isSideBar: PropTypes.bool
};

export default CreateButton;
