import { t, translationRenderer } from '@jotforminc/translation';
import { func, string } from 'prop-types';
import React from 'react';
import Styled from 'styled-components';

const productFileType = {
  'LISTING@PORTAL': 'app',
  'LISTING@REPORT': 'report',
  'LISTING@TASK': 'workflow',
  'LISTING@DOCUMENT': 'document'
};

const ScContent = Styled.div`
  width: 100%;
  padding: 32px;
  text-align: center;
  font-size: 1em;

  .title {
    font-size: 20px;
    color: #18235C;
    font-weight: 500;
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    color: #6F76A7;
    margin-top: 8px;
    line-height: 24px;

    b {
      color: #0A1551;
      font-weight: 700;
    }
  }
`;

const ScButton = Styled.button`
  margin: 0 auto;

  &.taste {
    display: block;
    padding: 12px 16px;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
    border: 0px;
    font-weight: 400;
    font-size: 16px;
  }
  
  &.isGreen {
    background-color: #7BB60F;
    color: #fff;
  }
`;

const Content = ({
  assetTitle, name, username, currentListing, onDone, doneButtonText
}) => (
  <>
    <ScContent>
      <div className="title">
        {t(`${assetTitle} is being edited`)}
      </div>
      <div className="description">
        {translationRenderer(`This ${productFileType[currentListing]} currently being edited by [1[{username}]].[2[{}]]You will be able to edit when the user stops editing. `)({
          renderer1: () => (<b>{name || username}</b>),
          renderer2: () => (<br />)
        })}
      </div>
    </ScContent>
    {doneButtonText ? <ScButton type="button" className="taste isGreen doneBtn" onClick={onDone}>{doneButtonText}</ScButton> : null}
  </>
);

Content.propTypes = {
  assetTitle: string,
  name: string,
  username: string,
  currentListing: string,
  onDone: func,
  doneButtonText: string
};

Content.defaultProps = {
  assetTitle: '',
  name: '',
  username: '',
  currentListing: '',
  onDone: f => f,
  doneButtonText: ''
};

export default Content;
