// eslint-disable-next-line
import QueryString from 'querystring-es3';
import {
  put, call, select, take, takeLatest, all
} from 'redux-saga/effects';
import { fetchForm } from '../../api';
import { SELECTORS } from '../../store/selectors';
import { ACTION_CREATORS } from '../../store/actionCreators';
import { ACTION_TYPES } from '../../store/actionTypes';
import { LISTING_TYPES, APP_CREATION_VIEWS } from '../../constants';
import { initUrlActionCreateWizVsTempSugModalOnMyApps } from '../../utils/abTests/urlActionCreateWizVsTempSugModalOnMyApps';

function* handleActionOperations(value, queryObject) {
  switch (value) {
    case 'createWizard':
      const [
        user, currentPage
      ] = yield all([
        select(SELECTORS.getUserCredentials),
        select(SELECTORS.getCurrentPage)
      ]);

      const isPortal = currentPage === LISTING_TYPES.PORTAL;

      // A/B Test: urlActionCreateWizVsTempSugModalOnMyApps
      if (isPortal) {
        const { isTestVariant } = yield call(initUrlActionCreateWizVsTempSugModalOnMyApps, user);
        if (isTestVariant) {
          yield put(ACTION_CREATORS.templateSuggestionModal());
        } else {
          yield put(ACTION_CREATORS.createWizard());
        }
      } else {
        const { assetCreationType = '' } = queryObject || {};
        yield put(ACTION_CREATORS.createWizard(assetCreationType ? { assetCreationType } : null));
      }
      break;
    case 'createRecommendedApp':
      yield put(ACTION_CREATORS.createWizard({ initialView: APP_CREATION_VIEWS.APP_RECOMMENDATION }));
      break;
    case 'createRecommendedOnlineStoreApp':
      yield put(ACTION_CREATORS.createWizard({ initialView: APP_CREATION_VIEWS.ONLINE_STORE }));
      break;
    default:
      break;
  }
}

function* executeQueryBasedOperations() {
  const queryObject = QueryString.parse(window.location.search.replace('?', ''));
  const queryEntries = Object.entries(queryObject);
  for (let i = 0; i < queryEntries.length; i++) {
    const [key, value] = queryEntries[i];
    switch (key) {
      case 'action':
        yield handleActionOperations(value, queryObject);
        break;
      default:
        break;
    }
  }
}

function* handleSetURLID({ id }) {
  try {
    const isMixEnabledPage = yield select(SELECTORS.getIsMixEnabledPage);

    if (!isMixEnabledPage) {
      const form = yield call(fetchForm, id);
      yield put(ACTION_CREATORS.setFilterForm(form));
      yield put(ACTION_CREATORS.setFilterID(id));
    }
  } catch (err) {
    // form not found
  }
}

function removePreventMixRedirectionParam() {
  const params = new URLSearchParams(window.location.search);
  if (params.get('preventWorkspaceRedirect')) {
    params.delete('preventWorkspaceRedirect');
    window.history.replaceState(window.history.state, document.title, `${params ? `?${params}` : ''}${window.location.hash}`);
  }
}

export function* rootUrlFlow() {
  yield takeLatest(ACTION_TYPES.SET_URL_ID, handleSetURLID);
  const currentPage = yield select(SELECTORS.getCurrentPage);

  if (currentPage === LISTING_TYPES.AGENT) {
    yield take(ACTION_TYPES.FETCH_USER.SUCCESS);
  } else {
    yield take(ACTION_TYPES.FETCH_LIST.SUCCESS);
  }
  yield executeQueryBasedOperations();
  yield call(removePreventMixRedirectionParam);
}
