import React, { PureComponent } from 'react';
import PropTypes, { bool } from 'prop-types';
import classNames from 'classnames';
import { t } from '@jotforminc/translation';
import { IconChevronDown, IconXmark } from '@jotforminc/svg-icons';
import { Badge } from '@jotforminc/magnet';

class NavigationTrigger extends PureComponent {
  render() {
    const {
      appTag = '',
      appTitle = '',
      appName = '',
      summary = {},
      toggleNavigation = () => {},
      logDiscoverabilityActions = f => f,
      isAppPickerTooltipActive = false,
      onAppPickerTooltipOverlayClicked = f => f,
      onAppPickerTooltipCloseIconClicked = f => f,
      onAppPickerTooltipRendered = f => f,
      darkMode = false,
      isNavigationOpened = false
    } = this.props;

    const notification = Object.values(summary).findIndex(s => (s?.notification || 0) > 0) > -1;

    if (isAppPickerTooltipActive) {
      onAppPickerTooltipRendered();
    }

    const appTitleName = appName === 'myTeams' ? appTitle : t(appTitle);

    const isBetaAsset = ['boards', 'myboards', 'conversations'].includes(appName?.toLowerCase()) || false;

    return (
      <>
        <button
          type="button"
          className={classNames(`jNewHeader-buttonNew appSelectorTrigger-button ${darkMode && 'appSelectorTrigger-darkMode'}`, { notification })}
          onClick={() => {
            toggleNavigation();

            // discoverabilityTracking
            if (appName === 'formBuilder' || appName === 'myForms') {
              logDiscoverabilityActions({
                action: 'click',
                target: '.appSelectorTrigger-button'
              });
            }
          }}
          aria-haspopup="true"
          aria-expanded={isNavigationOpened}
          data-testid="appSelectorTrigger-button"
          aria-label={`${t('Product Selector, Currently Selected')} ${appTitleName}`}
        >
          <span
            className="currentApp iBlock"
            data-tag={appTag}
            data-testid="current-app"
          >
            {appTitleName}
          </span>
          {isBetaAsset ? (
            <Badge
              colorStyle="primary"
              rounded={false}
              size="small"
              className="hiddenjf md:inline-flex ml-1"
            >
              {t('BETA')}
            </Badge>
          ) : null}
          <span className='jNewHeader-icon jNewHeader-selectorIcon iBlock appSelectorTrigger'>
            <IconChevronDown />
          </span>
          <div className="jNewHeader-appBoxArrow" />
        </button>
        {isAppPickerTooltipActive
          && (
          <div className='appSelectorTooltip'>
            <button
              className='closeButton' type='button' aria-label={t('Close')}
              onClick={onAppPickerTooltipCloseIconClicked}
            >
              <IconXmark />
            </button>
            <h5 className='title'>{t("You've got responses!  🎉'")}</h5>
            <p className='content'>{t('Now click here to discover how you can make the most of your responses.')}</p>
          </div>
          )}

        {isAppPickerTooltipActive
          && (
          <>
            <div
              className='appSelectorOverlay'
              onClick={onAppPickerTooltipOverlayClicked}
            />
          </>
          )}

      </>
    );
  }
}

NavigationTrigger.propTypes = {
  appTag: PropTypes.string,
  appTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  appName: PropTypes.string,
  toggleNavigation: PropTypes.func,
  summary: PropTypes.shape({}),
  logDiscoverabilityActions: PropTypes.func,
  isAppPickerTooltipActive: bool,
  onAppPickerTooltipOverlayClicked: PropTypes.func,
  onAppPickerTooltipCloseIconClicked: PropTypes.func,
  onAppPickerTooltipRendered: PropTypes.func,
  darkMode: PropTypes.bool,
  isNavigationOpened: PropTypes.bool
};

export default NavigationTrigger;
