import React from 'react';
import { number, func, bool } from 'prop-types';
import { t } from '@jotforminc/translation';
import { ScNotificationListTitle } from './sc/scNotificationListTitle';
import { ScNotificationClearAllButton } from './sc/scNotificationClearAllButton';
import { ScNotificationBadge } from './sc/scNotificationBadge';

const NotificationListHeader = ({
  notificationCount,
  showNotificationCount,
  onDismissAll,
  hideHeader
}) => {
  const isEmptyNotification = notificationCount > 0 ? false : true;

  const handleDismissAll = () => {
    onDismissAll();
  };

  if (hideHeader) return null;

  return (
    <ScNotificationListTitle isEmptyNotification={isEmptyNotification}>
      <span>{t('Notifications')}</span>
      {showNotificationCount && <ScNotificationBadge>{notificationCount}</ScNotificationBadge>}
      <ScNotificationClearAllButton onClick={handleDismissAll} isDisabled={isEmptyNotification}>{t('Clear All')}</ScNotificationClearAllButton>
    </ScNotificationListTitle>
  );
};

NotificationListHeader.propTypes = {
  hideHeader: bool,
  showNotificationCount: bool,
  notificationCount: number,
  onDismissAll: func
};

NotificationListHeader.defaultProps = {
  hideHeader: false,
  notificationCount: 0,
  showNotificationCount: false,
  onDismissAll: f => f
};

export default NotificationListHeader;
