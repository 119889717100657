import styled from 'styled-components';
import { WizardBody, WizardItem } from '@jotforminc/wizard-shared';

export const ScWizardItem = styled(WizardItem)`
  &.jfWizard-item {
    max-width: 830px;

    .jfWizard-header{
      &-title {
        color: #343C6A;
        font-weight: 700;
        line-height: 40px;
        font-size: 28px;
      }

      &-subtitle{
        margin-top: 12px;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
      }
    }

    @media screen and (max-width: 880px) {
      max-width: 475px;
    }

    @media screen and (max-height: 620px) {
      margin: 0;
    }
  }
`;

export const ScWizardBody = styled(WizardBody)`
  &.jfWizard-body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    flex-grow: 0;
    margin: 60px 0 127px;
  }

  .jfWizard-header-title.secondaryProducts-header{
    color: #0A1551;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-top: 0;
  }

  .divider {
    width: 100%;
    height: 1px;
    background: #C8CEED;
    margin: 20px 0px;
  }
  
  .productItem {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: grid;
    gap: 16px;
    background-color: #F3F3FE;
    padding: 16px;
    border-radius: 8px;
    border: 2px solid #F3F3FE;
    height: 100%;
    width: 100%;

    @media screen and (max-width: 880px) {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    @media screen and (max-width: 880px) {
      grid-template-columns: 100px 1fr;
    }

    &-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      width: 100%;
      gap: 24px;

      @media screen and (max-width: 880px) {
        grid-template-columns: 1fr;
        gap: 16px;
      }   
    }

    &.form{
      .productIconWrapper {background-color: #FF6100;}
      &:hover, &:focus{
        transition: all 0.2s ease-in-out;
        transform: scale(1.05);
        border-color: #09F;
        .productButton{background-color: #FF6100;}
      }
    }
    &.app {
      .productIconWrapper {
        background-color: #9C4DD3;
        position: relative;
        padding-top: 13px;
        padding-bottom: 13px;
        & > svg {transform: translateY(10px);}
      }
      &:hover, &:focus{
        transition: all 0.2s ease-in-out;
        transform: scale(1.05);
        border-color: #09F;
        .productButton{background-color: #9C4DD3;}
      }
    }
    &.signabledocument {
      .productIconWrapper {background-color: #7BB60F;}
      @media screen and (max-width: 880px) {
        .productButton{
          padding: 8px;
        }
      }
      &:hover, &:focus{
        transition: all 0.2s ease-in-out;
        transform: scale(1.05);
        border-color: #09F;
        .productButton{background-color: #7BB60F;}
      }
    }
    &.table {
      .productIconWrapper {background-color: #049E38;}
      &:hover, &:focus{
        transition: all 0.2s ease-in-out;
        transform: scale(1.05);
        border-color: #09F;
      }
    }
    &.approval,
    &.workflow {
      .productIconWrapper {background-color: #007862;}
      &:hover, &:focus{
        transition: all 0.2s ease-in-out;
        transform: scale(1.05);
        border-color: #09F;
      }
    }
    &.report {
      .productIconWrapper {background-color: #5369AB;}
      &:hover, &:focus{
        transition: all 0.2s ease-in-out;
        transform: scale(1.05);
        border-color: #09F;
      }
    }
  }
  .productContentWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    line-height: 26px;
    width: 100%;

    @media screen and (max-width: 880px) {
      align-items: flex-start;
      text-align: left;
      gap: 8px;
      height: 100%;
      justify-content: center;
    }
  }

  .productIconWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 24px;

    @media screen and (max-width: 880px) {
      padding: 18px;
    }
  }

  .productName {
    color: #0A1551;
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;

    @media screen and (max-width: 880px) {
      line-height: 28px;      
    }
  }

  .productDescription {
    color: #343C6A;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: start;
  }

  .productButton {
    padding: 12px;
    border-radius: 4px;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    background-color: #0A1551;
    width: 100%;
    margin-top: 12px;

    @media screen and (max-width: 880px) {
      margin-top: 8px;
    }
  }

  .secondaryProducts {
    & .productContentWrapper{
      gap: 8px;
      
      & .productName{
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
      }
      
      & .productDescription{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    & .productIconWrapper{
      width: 64px;
      aspect-ratio: 1;
      padding: 10px;

      & > svg {
        width: 44px;
      }

      @media screen and (max-width: 880px) {
        width: 80px;
        & > svg {
          width: 60px;
        }
      }
    }
  }
`;
