import React, { useEffect } from 'react';
import {
  bool, object, number,
  shape,
  arrayOf,
  string
} from 'prop-types';
import classNames from 'classnames';
import { t } from '@jotforminc/translation';
import { WizardHeader } from '@jotforminc/wizard-shared';
import { Loading } from '@jotforminc/loading';
import { PRIMARY_PRODUCT_CONFIGS, SECONDARY_PRODUCT_CONFIGS, TEXTS } from './constants';
import { ScWizardBody, ScWizardItem } from './ScWizardBody';
import './style.scss';
import WatchmanRecorder from '../../utils/WatchmanRecorder';
import { LISTING_TYPES, TEAM_ENABLED_RESOURCES } from '../../constants';

const MixAssetModal = ({
  isMobileDevice = false,
  hasUserForms = false,
  user = {},
  isSideBar = false,
  userFormCountLimit = null,
  isMixPageUser = false,
  teamPermissions = {},
  userPermissions = [],
  isTeamAssetCreation = false,
  selectedFolder = '',
  isAssetsFolderActive = false
}) => {
  const isLoading = false;

  useEffect(() => {
    const assetModalContainer = document.querySelector('#create-asset-modal-container');
    assetModalContainer.setAttribute('style', 'position: absolute; top: 70px; left: 0; width: 100%; z-index: 499');
    WatchmanRecorder.trackEventForCustomProject('seen', `${isMobileDevice ? 'mobile-' : ''}mixAssetCreationModal`, 'mixAssetCreationModal', true);
  }, []);

  const handleOpenCreateProductWizard = productType => {
    window.openCreateProductWizard?.({
      productType,
      createAssetFrom: 'mixAssetModal',
      formCountLimit: userFormCountLimit,
      isMixPageUser,
      isSideBar
    });
  };

  const handleProductSelect = (type, name) => {
    if (isTeamAssetCreation) {
      WatchmanRecorder.trackEventForCustomProject('click', `teamPageSelectProduct - ${name}`, 'teams', true);
    } else {
      WatchmanRecorder.trackEventForCustomProject('click', `${isMobileDevice ? 'mobile-' : ''}${name}-mixAssetCreationModal`, 'mixAssetCreationModal', true);
    }
    handleOpenCreateProductWizard(type);
  };

  const primaryFilteredConfig = isTeamAssetCreation ? PRIMARY_PRODUCT_CONFIGS.filter(({
    name, createPermissionName, checkVisibility, userCreatePermissionType
  }) => {
    const hasPermission = isTeamAssetCreation ? teamPermissions[`create${createPermissionName || name}`] : userPermissions?.includes(userCreatePermissionType);

    if (!checkVisibility) return hasPermission;
    return hasPermission && checkVisibility({ user });
  }) : PRIMARY_PRODUCT_CONFIGS;

  const secondaryFilteredConfig = isTeamAssetCreation ? SECONDARY_PRODUCT_CONFIGS.filter(({
    name, createPermissionName, checkVisibility, userCreatePermissionType
  }) => {
    const hasPermission = isTeamAssetCreation ? teamPermissions[`create${createPermissionName || name}`] : userPermissions?.includes(userCreatePermissionType);

    if (!checkVisibility) return hasPermission;
    return hasPermission && checkVisibility({ user });
  }) : SECONDARY_PRODUCT_CONFIGS;

  return isLoading ? <Loading /> : (
    <ScWizardItem>
      <WizardHeader
        noDecodeURI
        title={t(TEXTS.HEADER_TITLE)}
        subTitle={t(TEXTS.HEADER_SUBTITLE)}
      />
      <ScWizardBody>
        <ul className="productItem-list primaryProducts">
          {primaryFilteredConfig
            .map(({
              name,
              description,
              Icon,
              type
            }) => (
              <li>
                <button
                  key={name}
                  className={classNames('productItem', name.toLowerCase().replace(' ', ''), {
                    disabled: isTeamAssetCreation && (!TEAM_ENABLED_RESOURCES.includes(type) || (
                      !!selectedFolder && type !== LISTING_TYPES.FORM && !isAssetsFolderActive
                    ))
                  })}
                  onClick={() => handleProductSelect(type, name.toLowerCase().replace(' ', ''))}
                  data-productCount={primaryFilteredConfig.length}
                  type="button"
                  tabIndex="0"
                  aria-label={t(name)}
                >
                  { Icon && <div className="productIconWrapper"><Icon className='productIcon' /></div>}
                  <div className="productContentWrapper">
                    <div className='productName'>{t(name)}</div>
                    <div className='productDescription'>{t(description)}</div>
                    <button type="button" className="productButton" aria-hidden="true">{t(`Create ${name}`)}</button>
                  </div>
                </button>
              </li>
            ))}
        </ul>
        {hasUserForms ? (
          <>
            <div className="divider" />
            <h1 class="jfWizard-header-text jfWizard-header-title secondaryProducts-header">{t('More tools for data management')}</h1>
            <ul className="productItem-list secondaryProducts">
              {secondaryFilteredConfig
                .map(({
                  name,
                  description,
                  Icon,
                  type
                }) => (
                  <li>
                    <button
                      key={name}
                      className={classNames('productItem', name.toLowerCase().replace(' ', ''), {
                        disabled: isTeamAssetCreation && (!TEAM_ENABLED_RESOURCES.includes(type) || (
                          !!selectedFolder && type !== LISTING_TYPES.FORM && !isAssetsFolderActive
                        ))
                      })}
                      onClick={() => handleProductSelect(type, name.toLowerCase().replace(' ', ''))}
                      data-productCount={secondaryFilteredConfig.length}
                      type="button"
                      tabIndex="0"
                      aria-label={t(name)}
                    >
                      { Icon && <div className="productIconWrapper"><Icon className='productIcon' /></div>}
                      <div className="productContentWrapper">
                        <div className='productName'>{t(name)}</div>
                        <div className='productDescription'>{t(description)}</div>
                      </div>
                    </button>
                  </li>
                ))}
            </ul>
          </>
        ) : null}
      </ScWizardBody>
    </ScWizardItem>
  );
};

MixAssetModal.propTypes = {
  user: object,
  isMobileDevice: bool,
  hasUserForms: bool,
  isSideBar: bool,
  userFormCountLimit: number,
  isMixPageUser: bool,
  teamPermissions: shape({}),
  userPermissions: arrayOf(string),
  isTeamAssetCreation: bool,
  selectedFolder: string,
  isAssetsFolderActive: bool
};

export default MixAssetModal;
