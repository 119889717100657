import React from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { safeLazy, lazyRender } from '@jotforminc/safe-lazy-import';

const LazyCreateNewBoardWizard = safeLazy(() => import(/* webpackChunkName: "LazyWizard_CreateNewBoardWizard" */'./LazyWizards/LazyCreateNewBoardWizard'));

export const openCreateNewBoardModal = ({ showRecommendation, showOnlineStoreRecommendation, ...props }) => {
  const containerSelector = '#modal-container';
  const root = document.querySelector(containerSelector);

  const handleClose = () => {
    window.history.replaceState({}, '', window.location.pathname);
    unmountComponentAtNode(root);
  };

  return lazyRender({
    root,
    children: (
      <LazyCreateNewBoardWizard
        {...props}
        onHide={handleClose}
        initialVisibilityState={true}
      />
    )
  });
};
