import {
  IconProductApprovalsColor, IconProductApprovalsMono, IconProductAppsColor, IconProductAppsMono, IconProductFormAnalyticsColor,
  IconProductFormAnalyticsMono, IconProductFormBuilderColor, IconProductFormBuilderMagnifyingGlassColor,
  IconProductFormBuilderMagnifyingGlassMono, IconProductFormBuilderMono, IconProductInboxColor, IconProductInboxMono,
  IconProductPdfEditorColor, IconProductPdfEditorMono, IconProductReportBuilderColor, IconProductReportBuilderFilled,
  IconProductSignColor,
  IconProductSignMono,
  IconProductTablesColor, IconProductTablesMono, IconProductTeamsColor, IconProductTeamsMono,
  IconProductPagesMono, IconProductPagesColor
} from '@jotforminc/svg-icons';

import MyContactsDefault from '../../assets/svg/appIcons/contacts_default.svg';
import MyContactsSelected from '../../assets/svg/appIcons/contacts_selected.svg';
import BoardsDefault from '../../assets/svg/appIcons/boards_default.svg';
import BoardsSelected from '../../assets/svg/appIcons/boards_selected.svg';
import AgentsDefault from '../../assets/svg/iconAppAiAgentBuilder2.svg';
import AgentsSelected from '../../assets/svg/iconAppAiAgentBuilder1.svg';

export default {
  viewForm: {
    Default: IconProductFormBuilderMagnifyingGlassMono,
    Selected: IconProductFormBuilderMagnifyingGlassColor
  },
  formBuilder: {
    Default: IconProductFormBuilderMono,
    Selected: IconProductFormBuilderColor
  },
  pdfEditor: {
    Default: IconProductPdfEditorMono,
    Selected: IconProductPdfEditorColor
  },
  tables: {
    Default: IconProductTablesMono,
    Selected: IconProductTablesColor
  },
  inbox: {
    Default: IconProductInboxMono,
    Selected: IconProductInboxColor
  },
  approvals: {
    Default: IconProductApprovalsMono,
    Selected: IconProductApprovalsColor
  },
  reports: {
    Default: IconProductReportBuilderFilled,
    Selected: IconProductReportBuilderColor
  },
  sign: {
    Default: IconProductSignMono,
    Selected: IconProductSignColor
  },
  portal: {
    Default: IconProductAppsMono,
    Selected: IconProductAppsColor
  },
  formAnalytics: {
    Default: IconProductFormAnalyticsMono,
    Selected: IconProductFormAnalyticsColor
  },
  myTeams: {
    Default: IconProductTeamsMono,
    Selected: IconProductTeamsColor
  },
  myWorkspace: {
    Default: IconProductTeamsMono,
    Selected: IconProductTeamsColor
  },
  myContacts: {
    Default: MyContactsDefault,
    Selected: MyContactsSelected
  },
  boards: {
    Default: BoardsDefault,
    Selected: BoardsSelected
  },
  signInbox: {
    Default: IconProductInboxMono,
    Selected: IconProductInboxColor
  },
  pages: {
    Default: IconProductPagesMono,
    Selected: IconProductPagesColor
  },
  myAgents: {
    Default: AgentsDefault,
    Selected: AgentsSelected
  }
};
