import React, { useState } from 'react';
import {
  arrayOf, string, shape, func
} from 'prop-types';
import classNames from 'classnames';
import { t, translationRenderer } from '@jotforminc/translation';
import { IconCaretDownBars } from '@jotforminc/svg-icons';
import { useDispatch } from 'react-redux';
import { Dropdown } from '../Dropdown';
import translate from '../../utils/translate';
import WatchmanRecorder from '../../utils/WatchmanRecorder';
import { getCookie } from '../../utils';
import { ACTION_CREATORS } from '../../store/actionCreators';
import { renderDropdownOption } from '../ListingView/Toolbar/utils';
import { DEFAULT_FOLDER_IDS } from '../../constants';

const SortMenu = ({
  sortingOptions = [], handleSortingSelect = f => f, selectedSorting = '', orderby = '', selectedFolder = ''
}) => {
  const dispatch = useDispatch();
  const [isOptionsVisible, setOptionsVisible] = useState(false);
  const sortingOptionList = selectedFolder === DEFAULT_FOLDER_IDS.SHARED_REPORTS ? sortingOptions.filter(opt => opt.view !== 'grouped') : sortingOptions;
  const defaultOption = (sortingOptionList || []).find(({ value }) => getCookie(`${selectedFolder}Sorting`) === value);
  const selectedSortingOptionText = sortingOptionList?.find(opt => opt.value === selectedSorting)?.text || selectedSorting;

  let defaultOptionValue = defaultOption?.value || orderby;
  if (selectedFolder === DEFAULT_FOLDER_IDS.SHARED_REPORTS) {
    defaultOptionValue = defaultOption ? defaultOption.value : 'updated_at';
  }

  if (defaultOptionValue && defaultOptionValue !== orderby) {
    dispatch(ACTION_CREATORS.setOrderBy(defaultOptionValue));
  }

  const handleOptionsVisible = tmpIsOptionsVisible => {
    if (isOptionsVisible === tmpIsOptionsVisible) return;

    setOptionsVisible(tmpIsOptionsVisible);
    WatchmanRecorder.trackEvent('click', `sortButton-${tmpIsOptionsVisible ? 'Opened' : 'Closed'}`, 'myformsFormToolbar');
  };

  return (
    <div className={classNames('sb-sortW', { isOpened: isOptionsVisible })}>
      <IconCaretDownBars aria-hidden="true" className={classNames('w-6 top-2 absolute left-2 pointer-events-none ', { 'color-white': isOptionsVisible, 'color-navy-700': !isOptionsVisible })} />
      <Dropdown
        defaultValue={defaultOptionValue}
        options={sortingOptionList?.map(opt => ({ ...opt, text: translate(opt.text) }))}
        onOptionSelect={({ value, key }) => handleSortingSelect(value, key)}
        OptionRenderer={renderDropdownOption('sortOption')}
        popoverProps={{
          className: 'listings-popover-modal',
          popoverOptions: {
            placement: 'bottom-end'
          }
        }}
        onOptionsVisibilityChanged={handleOptionsVisible}
        dropdownButtonAriaLabelPrefix={selectedSortingOptionText ? (
          translationRenderer('Sorted by [1[{selectedSorting}]]')({
            renderer1: () => t(selectedSortingOptionText)
          })
        ) : t('Sorting')}
        placeholder={t('Please select')}
        OptionContainerRenderer={props => <li tabIndex={0} {...props} />}
        optionsAutoFocus={true}
      />
    </div>
  );
};

SortMenu.propTypes = {
  sortingOptions: arrayOf(shape({})),
  handleSortingSelect: func,
  selectedSorting: string,
  orderby: string,
  selectedFolder: string
};

export default SortMenu;
