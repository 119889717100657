class ActivityLogger {
  constructor() {
    if (!ActivityLogger.instance) {
      this.logger = null;
      this.username = null;
      this.middlewares = [];
      this.app = null;
      ActivityLogger.instance = this;
    }

    return ActivityLogger.instance;
  }

  setLogger(logger) {
    this.logger = logger;
    return this;
  }

  getLogger() {
    return this.logger;
  }

  setMiddleware(middleware) {
    if (Array.isArray(middleware)) {
      this.middlewares = [...this.middlewares, ...middleware];
    } else {
      this.middlewares.push(middleware);
    }
    return this;
  }

  runMiddlewares() {
    const middleWareStatus = this.middlewares.reduce((result, middleware) => {
      result.push(!!middleware);
      return result;
    }, []);
    return !(middleWareStatus.indexOf(false) > -1);
  }

  setUsername(username) {
    this.username = username;
    return this;
  }

  setApp(app) {
    this.app = app;
    return this;
  }

  getUsername() {
    return this.username;
  }

  getApp() {
    return this.app;
  }

  log({ username, action, target }) {
    if (this.runMiddlewares() && this.getLogger() && this.getUsername() && this.getApp()) {
      this.logger.tick({ actor: username ? username : this.username, action: `${action}_${this.getApp()}`, target });
      return true;
    }
    return false;
  }
}

const logger = new ActivityLogger();

export default logger;
