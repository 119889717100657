import { ACTION_TYPES } from './actionTypes';

export const ACTION_CREATORS = {
  setList: list => ({
    list,
    type: ACTION_TYPES.SET_LIST
  }),
  setDeletedItems: items => ({
    items,
    type: ACTION_TYPES.SET_DELETED_ITEMS
  }),
  setFilteredList: list => ({
    list,
    type: ACTION_TYPES.SET_FILTERED_LIST
  }),
  fetchListSuccess: (list, query, assetFilterType, isMixStructureEnabled) => ({
    ...query,
    list,
    assetFilterType,
    isMixStructureEnabled,
    type: ACTION_TYPES.FETCH_LIST.SUCCESS
  }),
  setFeatures: featureList => ({
    featureList,
    type: ACTION_TYPES.SET_FEATURES
  }),
  resetFeatures: () => ({
    type: ACTION_TYPES.RESET_FEATURES
  }),
  fetchListRequest: offset => ({
    offset,
    type: ACTION_TYPES.FETCH_LIST.REQUEST
  }),
  fetchListError: () => ({
    type: ACTION_TYPES.FETCH_LIST.ERROR
  }),
  selectAll: isSelected => ({
    isSelected,
    type: ACTION_TYPES.SELECT_ALL
  }),
  selectItem: (id, isSelected, assetType) => ({
    id,
    isSelected,
    assetType,
    type: ACTION_TYPES.SELECT_ITEM
  }),
  setLastSelectedListingItem: id => ({ id, type: ACTION_TYPES.SET_LAST_SELECTED_LISTING_ITEM }),
  disableMultipleSelectWithShiftToast: () => ({ type: ACTION_TYPES.DISABLE_MULTIPLE_SELECT_SHIFT_TOAST }),
  selectSingleItem: (id, deselectable = false, assetType) => ({
    id,
    type: ACTION_TYPES.SELECT_SINGLE_ITEM,
    deselectable,
    assetType
  }),
  setContactDetail: id => ({
    id,
    type: ACTION_TYPES.SET_CONTACT_DETAIL
  }),
  setFilter: filter => ({
    filter,
    type: ACTION_TYPES.SET_FILTER
  }),
  setFullText: fullText => ({
    fullText,
    type: ACTION_TYPES.SET_FULLTEXT
  }),
  setOrderBy: orderBy => ({
    orderBy,
    type: ACTION_TYPES.SET_ORDERBY
  }),
  setTeamFilter: teamFilter => ({
    teamFilter,
    type: ACTION_TYPES.SET_TEAM_FILTER
  }),
  setSelectedFolder: (folder, folderType, currentTeamID) => ({
    folder,
    folderType,
    currentTeamID,
    type: ACTION_TYPES.SET_SELECTED_FOLDER
  }),
  folderButtonAction: folder => ({
    folder,
    type: ACTION_TYPES.FOLDER_BUTTON_ACTION
  }),
  setMobileViewFoldersVisibility: isVisible => ({
    value: isVisible,
    type: ACTION_TYPES.SET_MOBILE_VIEW_FOLDERS_VISIBLITY
  }),
  setFolders: (folders, selectedFolder = '') => ({
    folders,
    selectedFolder,
    type: ACTION_TYPES.SET_FOLDERS
  }),
  folderItemAction: ({
    actionType, itemID, folderID, isBulk, assetType
  }) => ({
    type: ACTION_TYPES.FOLDER_ITEM_ACTION.REQUEST,
    actionType,
    itemID,
    folderID,
    isBulk,
    assetType
  }),
  folderItemActionBulk: ({ actionType, items, folderIDs }) => ({
    type: ACTION_TYPES.FOLDER_ITEM_ACTION_BULK.REQUEST,
    actionType,
    items,
    folderIDs
  }),
  transferItem: ({ itemIDs, username, folders = '' }) => ({
    type: ACTION_TYPES.TRANSFER_ITEM.REQUEST,
    itemIDs,
    username,
    folders
  }),
  fetchFolders: () => ({
    type: ACTION_TYPES.FETCH_FOLDERS.REQUEST
  }),
  fetchUser: () => ({
    type: ACTION_TYPES.FETCH_USER.REQUEST
  }),
  setUser: user => ({
    user,
    type: ACTION_TYPES.FETCH_USER.SUCCESS
  }),
  fetchAllFormsSuccess: forms => ({
    forms,
    type: ACTION_TYPES.FETCH_ALL_FORMS.SUCCESS
  }),
  fetchAllFormsError: () => ({
    forms: [],
    type: ACTION_TYPES.FETCH_ALL_FORMS.ERROR
  }),
  fetchAllFormsWithPropsSuccess: (forms, teamID) => ({
    forms,
    teamID,
    type: ACTION_TYPES.FETCH_ALL_FORMS_WITH_PROPS.SUCCESS
  }),
  fetchAllFormsWithPropsError: () => ({
    forms: [],
    type: ACTION_TYPES.FETCH_ALL_FORMS_WITH_PROPS.ERROR
  }),
  updateUserProperty: changes => ({
    type: ACTION_TYPES.UPDATE_USER_PROPERTY,
    changes
  }),
  resetList: () => ({
    type: ACTION_TYPES.RESET_LIST
  }),
  setFavorite: ({
    id, isNumeric = true, favoriteKey = 'favorite', assetType
  }) => ({
    id,
    isNumeric,
    favoriteKey,
    assetType,
    type: ACTION_TYPES.SET_FAVORITE
  }),
  setArchived: ({ id, refetchList, assetType }) => ({
    id,
    refetchList,
    assetType,
    type: ACTION_TYPES.SET_ARCHIVED
  }),
  cloneItemRequest: ({
    id, withForm, portalRoot, assetType
  }) => ({
    id,
    withForm,
    portalRoot,
    assetType,
    type: ACTION_TYPES.CLONE_ITEM.REQUEST
  }),
  cloneItemError: id => ({
    id,
    type: ACTION_TYPES.CLONE_ITEM.ERROR
  }),
  deleteItemSuccess: (id, assetType) => ({
    id,
    assetType,
    type: ACTION_TYPES.DELETE_ITEM.SUCCESS
  }),
  deleteItemRequest: ({ id, customPath, assetType }) => ({
    id,
    customPath,
    assetType,
    type: ACTION_TYPES.DELETE_ITEM.REQUEST
  }),
  deleteItemError: id => ({
    id,
    type: ACTION_TYPES.DELETE_ITEM.ERROR
  }),
  deleteTaskItemRequest: ({ id, assetType }) => ({
    id,
    assetType,
    type: ACTION_TYPES.DELETE_TASK_ITEM.REQUEST
  }),
  updateItemRequest: ({
    id, payload, resolveReportUpdate, assetType
  }) => ({
    id,
    payload,
    resolveReportUpdate,
    assetType,
    type: ACTION_TYPES.UPDATE_ITEM.REQUEST
  }),
  updateItemSuccess: ({
    id, payload, resolveReportUpdate, result, assetType
  }) => ({
    id,
    payload,
    resolveReportUpdate,
    result,
    assetType,
    type: ACTION_TYPES.UPDATE_ITEM.SUCCESS
  }),
  updateItemError: id => ({
    id,
    type: ACTION_TYPES.UPDATE_ITEM.ERROR
  }),
  renameItemRequest: ({ id, payload, assetType }) => ({
    id,
    payload,
    assetType,
    type: ACTION_TYPES.RENAME_ITEM.REQUEST
  }),
  renameItemSuccess: (id, payload, result, assetType) => ({
    id,
    payload,
    result,
    assetType,
    type: ACTION_TYPES.RENAME_ITEM.SUCCESS
  }),
  renameItemError: id => ({
    id,
    type: ACTION_TYPES.RENAME_ITEM.ERROR
  }),
  updateFolderRequest: ({
    folderID,
    teamID,
    attributes,
    ...data
  }) => ({
    folderID,
    teamID,
    attributes,
    type: ACTION_TYPES.UPDATE_FOLDERS.REQUEST,
    ...data
  }),
  updateFolderSuccess: ({
    folderID,
    teamID,
    attributes,
    ...data
  }) => ({
    folderID,
    teamID,
    attributes,
    type: ACTION_TYPES.UPDATE_FOLDERS.SUCCESS,
    ...data
  }),
  folderContextAction: data => ({
    ...data,
    type: ACTION_TYPES.FOLDER_CONTEXT_ACTION
  }),
  addFolderRequest: ({ teamID, parent, ...data }) => ({
    teamID,
    type: ACTION_TYPES.ADD_FOLDER.REQUEST,
    ...data
  }),
  addFolderSuccess: ({ teamID, ...data }) => ({
    teamID,
    type: ACTION_TYPES.ADD_FOLDER.SUCCESS,
    ...data
  }),
  addFolderError: () => ({
    type: ACTION_TYPES.ADD_FOLDER.ERROR
  }),
  addAutomatedSDREmail: source => ({
    source,
    type: ACTION_TYPES.ADD_AUTOMATED_SDR_EMAIL
  }),
  deleteFolder: (folderID, teamID) => ({
    folderID,
    teamID,
    type: ACTION_TYPES.DELETE_FOLDER.REQUEST
  }),
  initShare: (sharedID, shareData, assetType) => ({
    sharedID,
    shareData,
    assetType,
    type: ACTION_TYPES.INIT_SHARE
  }),
  initShareError: sharedID => ({
    sharedID,
    type: ACTION_TYPES.INIT_SHARE.ERROR
  }),
  generateShareLinkRequest: ({ id, firstTime, assetType }) => ({
    id,
    firstTime,
    assetType,
    type: ACTION_TYPES.GENERATE_SHARE_LINK.REQUEST
  }),
  generateShareLinkSuccess: (id, shareLink, assetType) => ({
    id,
    shareLink,
    assetType,
    type: ACTION_TYPES.GENERATE_SHARE_LINK.SUCCESS
  }),
  generateShareLinkError: id => ({
    id,
    type: ACTION_TYPES.GENERATE_SHARE_LINK.ERROR
  }),
  onRevokeUserRequest: ({ id, shareID, assetType }) => ({
    id,
    shareID,
    assetType,
    type: ACTION_TYPES.ON_REVOKE_USER.REQUEST
  }),
  onRevokeUserSuccess: (id, shareID, assetType) => ({
    id,
    shareID,
    assetType,
    type: ACTION_TYPES.ON_REVOKE_USER.SUCCESS
  }),
  onRevokeUserError: id => ({
    id,
    type: ACTION_TYPES.ON_REVOKE_USER.ERROR
  }),
  onResendInvitationRequest: (id, resolve) => ({
    id,
    resolve,
    type: ACTION_TYPES.ON_RESEND_INVITATION.REQUEST
  }),
  onResendInvitationSuccess: id => ({
    id,
    type: ACTION_TYPES.ON_RESEND_INVITATION.SUCCESS
  }),
  onResendInvitationError: id => ({
    id,
    type: ACTION_TYPES.ON_RESEND_INVITATION.ERROR
  }),
  onAssigneePermissionChangeRequest: ({ id, shareValue, assetType }) => ({
    id,
    shareValue,
    assetType,
    type: ACTION_TYPES.ON_ASSIGNEE_PERMISSION.REQUEST
  }),
  onAssigneePermissionChangeSuccess: (id, shareID, submissionPermission, assetType) => ({
    id,
    shareID,
    submissionPermission,
    assetType,
    type: ACTION_TYPES.ON_ASSIGNEE_PERMISSION.SUCCESS
  }),
  onAssigneePermissionChangeError: id => ({
    id,
    type: ACTION_TYPES.ON_ASSIGNEE_PERMISSION.ERROR
  }),
  onRevokeMultipleUserRequest: ({ id, shareIDs, assetType }) => ({
    id,
    shareIDs,
    assetType,
    type: ACTION_TYPES.ON_REVOKE_MULTIPLE_USER.REQUEST
  }),
  onRevokeMultipleUserSuccess: (id, shareIDs, assetType) => ({
    id,
    shareID: shareIDs,
    assetType,
    type: ACTION_TYPES.ON_REVOKE_MULTIPLE_USER.SUCCESS
  }),
  onRevokeMultipleUserError: id => ({
    id,
    type: ACTION_TYPES.ON_REVOKE_MULTIPLE_USER.ERROR
  }),
  onSendRequest: ({ id, props, assetType }) => ({
    id,
    props,
    assetType,
    type: ACTION_TYPES.ON_SEND.REQUEST
  }),
  onSendSuccess: (id, assetType, result) => ({
    id,
    assetType,
    result,
    type: ACTION_TYPES.ON_SEND.SUCCESS
  }),
  onSendError: id => ({
    id,
    type: ACTION_TYPES.ON_SEND.ERROR
  }),
  onPropertyChangeRequest: ({
    sharedID, shareID, shareData, assetType
  }) => ({
    sharedID,
    shareID,
    shareData,
    assetType,
    type: ACTION_TYPES.ON_PROPERTY_CHANGE.REQUEST
  }),
  onPropertyChangeSuccess: (sharedID, shareData, assetType) => ({
    sharedID,
    shareData,
    assetType,
    type: ACTION_TYPES.ON_PROPERTY_CHANGE.SUCCESS
  }),
  onPropertyChangeError: shareID => ({
    shareID,
    type: ACTION_TYPES.ON_PROPERTY_CHANGE.ERROR
  }),
  deleteSignDocumentSuccess: (id, assetType) => ({
    id,
    assetType,
    type: ACTION_TYPES.DELETE_SIGN_DOCUMENT.SUCCESS
  }),
  deleteSignDocumentRequest: ({ id, assetType }) => ({
    id,
    assetType,
    type: ACTION_TYPES.DELETE_SIGN_DOCUMENT.REQUEST
  }),
  deleteSignDocumentError: id => ({
    id,
    type: ACTION_TYPES.DELETE_SIGN_DOCUMENT.ERROR
  }),
  cloneSignDocumentSuccess: id => ({
    id,
    type: ACTION_TYPES.CLONE_SIGN_DOCUMENT.SUCCESS
  }),
  cloneSignDocumentRequest: ({ id, assetType }) => ({
    id,
    assetType,
    type: ACTION_TYPES.CLONE_SIGN_DOCUMENT.REQUEST
  }),
  cloneSignDocumentError: id => ({
    id,
    type: ACTION_TYPES.CLONE_SIGN_DOCUMENT.ERROR
  }),
  renameSignDocumentRequest: ({ id, props, assetType }) => ({
    id,
    props,
    assetType,
    type: ACTION_TYPES.RENAME_SIGN_DOCUMENT.REQUEST
  }),
  renameSignDocumentSuccess: (id, props, assetType) => ({
    id,
    payload: props,
    assetType,
    type: ACTION_TYPES.RENAME_SIGN_DOCUMENT.SUCCESS
  }),
  renameSignDocumentError: id => ({
    id,
    type: ACTION_TYPES.RENAME_SIGN_DOCUMENT.ERROR
  }),
  deleteAgentRequest: ({ id, assetType }) => ({
    id,
    assetType,
    type: ACTION_TYPES.DELETE_AGENT.REQUEST
  }),
  deleteAgentSuccess: ({ id, assetType }) => ({
    id,
    assetType,
    type: ACTION_TYPES.DELETE_AGENT.SUCCESS
  }),
  deleteAgentError: ({ id, assetType }) => ({
    id,
    assetType,
    type: ACTION_TYPES.DELETE_AGENT.ERROR
  }),
  cloneAgentRequest: ({ id, assetType }) => ({
    id,
    assetType,
    type: ACTION_TYPES.CLONE_AGENT.REQUEST
  }),
  cloneAgentSuccess: (id, assetType) => ({
    id,
    assetType,
    type: ACTION_TYPES.CLONE_AGENT.SUCCESS
  }),
  cloneAgentError: (id, assetType) => ({
    id,
    assetType,
    type: ACTION_TYPES.CLONE_AGENT.ERROR
  }),
  renameAgentRequest: ({ id, props, assetType }) => ({
    id,
    assetType,
    props,
    type: ACTION_TYPES.RENAME_AGENT.REQUEST
  }),
  renameAgentSuccess: (id, props, assetType) => ({
    id,
    payload: props,
    assetType,
    type: ACTION_TYPES.RENAME_AGENT.SUCCESS
  }),
  renameAgentError: (id, assetType) => ({
    id,
    assetType,
    type: ACTION_TYPES.RENAME_AGENT.ERROR
  }),
  restoreAgentRequest: ({ id, refetchList, assetType }) => ({
    id,
    refetchList,
    assetType,
    type: ACTION_TYPES.RESTORE_AGENT.REQUEST
  }),
  archiveAgentRequest: ({ id, archive, assetType }) => ({
    id,
    archive,
    assetType,
    type: ACTION_TYPES.ARCHIVE_AGENT.REQUEST
  }),
  archiveAgentSuccess: (id, archive, assetType) => ({
    id,
    archive,
    assetType,
    type: ACTION_TYPES.ARCHIVE_AGENT.SUCCESS
  }),
  archiveAgentError: (id, assetType) => ({
    id,
    assetType,
    type: ACTION_TYPES.ARCHIVE_AGENT.ERROR
  }),
  enableAgentRequest: ({ id, assetType }) => ({
    id,
    assetType,
    type: ACTION_TYPES.ENABLE_AGENT.REQUEST
  }),
  enableAgentSuccess: (id, payload, assetType) => ({
    id,
    payload,
    assetType,
    type: ACTION_TYPES.ENABLE_AGENT.SUCCESS
  }),
  enableAgentError: (id, assetType) => ({
    id,
    assetType,
    type: ACTION_TYPES.ENABLE_AGENT.ERROR
  }),
  disableAgentRequest: ({ id, assetType }) => ({
    id,
    assetType,
    type: ACTION_TYPES.DISABLE_AGENT.REQUEST
  }),
  disableAgentSuccess: (id, payload, assetType) => ({
    id,
    payload,
    assetType,
    type: ACTION_TYPES.DISABLE_AGENT.SUCCESS
  }),
  disableAgentError: (id, assetType) => ({
    id,
    assetType,
    type: ACTION_TYPES.DISABLE_AGENT.ERROR
  }),
  openInboxPanel: formID => ({
    type: ACTION_TYPES.OPEN_INBOX_PANEL,
    formID
  }),
  toggleInboxPanel: status => ({
    type: ACTION_TYPES.TOGGLE_INBOX_PANEL,
    status
  }),
  changeInboxPanel: formID => ({
    type: ACTION_TYPES.CHANGE_INBOX_PANEL,
    formID
  }),
  portalUpdateSuccess: cloneID => ({
    cloneID,
    type: ACTION_TYPES.PORTAL_UPDATE.SUCCESS
  }),
  portalUpdateError: id => ({
    id,
    type: ACTION_TYPES.PORTAL_UPDATE.ERROR
  }),
  onDeleteMultiplePortalsRequest: () => ({
    type: ACTION_TYPES.ON_DELETE_MULTIPLE_PORTALS.REQUEST
  }),
  onDeleteMultiplePortalsError: () => ({
    type: ACTION_TYPES.ON_DELETE_MULTIPLE_PORTALS.ERROR
  }),
  onDeleteMultiplePortalsSuccess: deletedItemList => ({
    deletedItemList,
    type: ACTION_TYPES.ON_DELETE_MULTIPLE_PORTALS.SUCCESS
  }),
  onDeleteMultipleAgentsRequest: () => ({
    type: ACTION_TYPES.ON_DELETE_MULTIPLE_AGENTS.REQUEST
  }),
  onDeleteMultipleAgentsError: () => ({
    type: ACTION_TYPES.ON_DELETE_MULTIPLE_AGENTS.ERROR
  }),
  onDeleteMultipleAgentsSuccess: deletedItemList => ({
    deletedItemList,
    type: ACTION_TYPES.ON_DELETE_MULTIPLE_AGENTS.SUCCESS
  }),
  bulkUpdateListRequest: (prop, value, withUndo, selectedList) => ({
    prop,
    value,
    withUndo,
    selectedList,
    type: ACTION_TYPES.BULK_UPDATE_LIST.REQUEST
  }),
  bulkUpdateListError: () => ({
    type: ACTION_TYPES.BULK_UPDATE_LIST.ERROR
  }),
  bulkDeleteRequest: excludedItems => ({
    excludedItems,
    type: ACTION_TYPES.BULK_DELETE.REQUEST
  }),
  bulkDeleteError: () => ({
    type: ACTION_TYPES.BULK_DELETE.ERROR
  }),
  bulkDeleteSuccess: deletedItemList => ({
    deletedItemList,
    type: ACTION_TYPES.BULK_DELETE.SUCCESS
  }),
  discardDraftRequest: (ID, isOldSAC = false, formID) => ({
    ID,
    isOldSAC,
    formID,
    type: ACTION_TYPES.DISCARD_DRAFT.REQUEST
  }),
  purgeItem: ({ id, assetType }) => ({
    id,
    assetType,
    type: ACTION_TYPES.PURGE_ITEM
  }),
  restoreItem: ({ id, assetType }) => ({
    id,
    assetType,
    type: ACTION_TYPES.RESTORE_ITEM
  }),
  bulkMarkAsReadRequest: () => ({
    type: ACTION_TYPES.BULK_MARK_AS_READ
  }),
  createApp: () => ({
    type: ACTION_TYPES.CREATE_APP
  }),
  createDigest: payload => ({
    ...payload,
    type: ACTION_TYPES.CREATE_DIGEST
  }),
  updateDigest: payload => ({
    ...payload,
    type: ACTION_TYPES.UPDATE_DIGEST
  }),
  createWizard: payload => ({
    ...payload,
    type: ACTION_TYPES.CREATE_WIZARD
  }),
  createReportWizard: payload => ({
    ...payload,
    type: ACTION_TYPES.CREATE_REPORT_WIZARD
  }),
  updateReportWizard: (
    reportType,
    formID,
    reportID,
    reportData,
    setReportList,
    overrideForms
  ) => ({
    reportType,
    formID,
    reportID,
    reportData,
    setReportList,
    overrideForms,
    type: ACTION_TYPES.UPDATE_REPORT_WIZARD
  }),
  openLimitDialog: () => ({
    type: ACTION_TYPES.OPEN_LIMIT_DIALOG
  }),
  formDeleteReport: (reportID, setReportList, fetchList) => ({
    type: ACTION_TYPES.FORM_DELETE_REPORT.REQUEST,
    reportID,
    setReportList,
    fetchList
  }),
  reorderFolders: ({ folderID, referenceFolderID, position }) => ({
    folderID,
    position,
    referenceFolderID,
    type: ACTION_TYPES.REORDER_FOLDERS
  }),
  reorderTeams: ({ teamID, referenceTeamID, position }) => ({
    teamID,
    referenceTeamID,
    position,
    type: ACTION_TYPES.REORDER_TEAMS
  }),
  reorderTeamSubfolders: ({
    teamID, folderId, targetPath, newSubfolders
  }) => ({
    teamID,
    folderId,
    targetPath,
    newSubfolders,
    type: ACTION_TYPES.REORDER_TEAM_SUBFOLDERS
  }),
  updateFolderLayoutRequest: folderLayout => ({
    folderLayout,
    type: ACTION_TYPES.UPDATE_FOLDER_LAYOUT.REQUEST
  }),
  updateFolderLayoutSuccess: folderLayout => ({
    folderLayout,
    type: ACTION_TYPES.UPDATE_FOLDER_LAYOUT.SUCCESS
  }),
  updateFolderLayoutBackupSuccess: folderLayoutBackup => ({
    folderLayoutBackup,
    type: ACTION_TYPES.UPDATE_FOLDER_LAYOUT_BACKUP.SUCCESS
  }),
  setFilterID: filterID => ({
    filterID,
    type: ACTION_TYPES.SET_FILTER_ID
  }),
  setFilterForm: filterForm => ({
    filterForm,
    type: ACTION_TYPES.SET_FILTER_FORM
  }),
  showCreateTeamModal: (redirectAfterCreation, source) => ({
    type: ACTION_TYPES.SHOW_CREATE_TEAM_MODAL,
    redirectAfterCreation,
    source
  }),
  deleteTeamSuccess: teamID => ({
    teamID,
    type: ACTION_TYPES.DELETE_TEAM.SUCCESS
  }),
  updateTeamRoles: teamRoles => ({
    teamRoles,
    type: ACTION_TYPES.UPDATE_TEAM_ROLES
  }),
  updateTeams: teams => ({
    teams,
    type: ACTION_TYPES.UPDATE_TEAMS
  }),
  isTeamsFetched: isFetched => ({
    isFetched,
    type: ACTION_TYPES.IS_TEAMS_FETCHED
  }),
  updateTeamProperties: (teamID, newProperties, currentPage, isAssetsFolderActive) => ({
    teamID,
    newProperties,
    currentPage,
    isAssetsFolderActive,
    type: ACTION_TYPES.UPDATE_TEAM_PROPERTIES
  }),
  showInviteMemberModal: (inviteOnly, teamID, isAdmin) => ({
    type: ACTION_TYPES.SHOW_INVITE_MEMBER_MODAL,
    inviteOnly,
    teamID,
    isAdmin
  }),
  setURLID: id => ({
    type: ACTION_TYPES.SET_URL_ID,
    id
  }),
  setCurrentTeamID: id => ({
    type: ACTION_TYPES.SET_CURRENT_TEAM_ID,
    id
  }),
  toggleTeamActivityPanel: status => ({
    type: ACTION_TYPES.TOGGLE_TEAM_ACTIVITY_PANEL,
    status
  }),
  controlMoveToOperation: (selectedFolder, items) => ({
    type: ACTION_TYPES.CONTROL_MOVE_TO_OPERATION.REQUEST,
    selectedFolder,
    items
  }),
  moveToTeamRequest: (selectedTeam, items, isRetry = false) => ({
    type: ACTION_TYPES.MOVE_TO_TEAM.REQUEST,
    selectedTeam,
    items,
    isRetry
  }),
  moveToTeamSuccess: (selectedTeam, items, isNewMoveTo = false, movedAssetsInfo = null) => ({
    type: ACTION_TYPES.MOVE_TO_TEAM.SUCCESS,
    selectedTeam,
    items,
    isNewMoveTo,
    movedAssetsInfo
  }),
  moveToTeamError: () => ({
    type: ACTION_TYPES.MOVE_TO_TEAM.ERROR
  }),
  moveFromTeamRequest: (items, isRetry) => ({
    type: ACTION_TYPES.MOVE_FROM_TEAM.REQUEST,
    items,
    isRetry
  }),
  moveFromTeamSuccess: (items, isNewMoveTo, details) => ({
    type: ACTION_TYPES.MOVE_FROM_TEAM.SUCCESS,
    items,
    isNewMoveTo,
    details
  }),
  moveFromTeamError: () => ({
    type: ACTION_TYPES.MOVE_FROM_TEAM.ERROR
  }),
  showNoAccessToTeamView: () => ({
    type: ACTION_TYPES.SHOW_NO_ACCESS_TO_TEAM_VIEW
  }),
  handleTeamError: (error, teamID, reloadOnDeletion) => ({
    type: ACTION_TYPES.HANDLE_TEAM_ERROR,
    error,
    teamID,
    reloadOnDeletion
  }),
  toggleAddToFolderMenuVisibility: isVisible => ({
    type: ACTION_TYPES.TOGGLE_ADD_TO_FOLDER_MENU_VISIBILITY,
    isVisible
  }),
  toggleMoveToTeamMenuVisibility: isVisible => ({
    type: ACTION_TYPES.TOGGLE_MOVE_TO_TEAM_MENU_VISIBILITY,
    isVisible
  }),
  listenItemMovingStatus: () => {
    return {
      type: ACTION_TYPES.LISTEN_MOVE_JOBS
    };
  },
  preventFetchingMoveJobs: prevent => ({
    type: ACTION_TYPES.PREVENT_FETCHING_MOVE_JOBS,
    prevent
  }),
  setTeamNotificationCount: teamNotificationCount => ({
    teamNotificationCount,
    type: ACTION_TYPES.SET_TEAM_NOTIFICATION_COUNT
  }),
  requestEditAction: (assetID, props, resourceType) => ({
    assetID,
    resourceType,
    props,
    type: ACTION_TYPES.REQUEST_EDIT
  }),
  handleEditResource: (resourceID, resourceType) => ({
    resourceID,
    resourceType,
    type: ACTION_TYPES.EDIT_RESOURCE
  }),
  setServerTeamSettings: serverTeamSettings => ({
    serverTeamSettings,
    type: ACTION_TYPES.SET_SERVER_TEAM_SETTINGS
  }),
  resetTeamPermissions: (currentPage, isAssetsFolderActive) => ({
    currentPage,
    isAssetsFolderActive,
    type: ACTION_TYPES.RESET_ALL_TEAM_PERMISSIONS
  }),
  cancelMoveStatus: (id, assetType) => ({
    type: ACTION_TYPES.CANCEL_MOVE_JOBS.REQUEST,
    id,
    assetType
  }),
  cancelMoveStatusSuccess: (id, assetType) => ({
    type: ACTION_TYPES.CANCEL_MOVE_JOBS.SUCCESS,
    id,
    assetType
  }),
  fetchMoveJobsSuccess: (jobs, assetFilterType) => ({
    type: ACTION_TYPES.FETCH_MOVE_JOBS.SUCCESS,
    jobs,
    assetFilterType
  }),
  retryMoveJob: (id, assetType) => ({
    type: ACTION_TYPES.RETRY_MOVE_JOB,
    id,
    assetType
  }),
  deletePage: id => ({
    type: ACTION_TYPES.DELETE_PAGE,
    id
  }),
  allowReorderTeams: isAllowed => ({
    type: ACTION_TYPES.ALLOW_REORDER_TEAMS,
    isAllowed
  }),
  allowReorderFolders: isAllowed => ({
    type: ACTION_TYPES.ALLOW_REORDER_FOLDERS,
    isAllowed
  }),
  updateAllFoldersVisibility: show => ({
    type: ACTION_TYPES.UPDATE_ALL_FOLDERS_VISIBILITY,
    show
  }),
  setMobileDevice: isMobile => ({
    type: ACTION_TYPES.SET_MOBILE_DEVICE,
    isMobile
  }),
  addExpandedFolders: newExpandedFolder => ({
    type: ACTION_TYPES.ADD_EXPANDED_FOLDERS,
    newExpandedFolder
  }),
  removeExpandedFolders: collapsedFolder => ({
    type: ACTION_TYPES.REMOVE_EXPANDED_FOLDERS,
    collapsedFolder
  }),
  updateFormStatusRequest: (formID, properties) => ({
    type: ACTION_TYPES.UPDATE_FORM_STATUS.REQUEST,
    formID,
    properties
  }),
  updateFormStatusSuccess: (formID, newStatus, isMixAssetFilter) => ({
    formID,
    newStatus,
    isMixAssetFilter,
    type: ACTION_TYPES.UPDATE_FORM_STATUS.SUCCESS
  }),
  updateFormStatusError: () => ({
    forms: [],
    type: ACTION_TYPES.UPDATE_FORM_STATUS.ERROR
  }),
  openProductCreateWizard: ({ productType, ...rest }) => ({
    type: ACTION_TYPES.OPEN_PRODUCT_CREATE_WIZARD,
    productType,
    ...rest
  }),
  setCurrentPage: newCurrentPage => ({
    type: ACTION_TYPES.SET_CURRENT_PAGE,
    newCurrentPage
  }),
  setAssetFilter: newAssetFilter => ({
    type: ACTION_TYPES.SET_ASSET_FILTER,
    newAssetFilter
  }),
  templateSuggestionModal: payload => ({
    ...payload,
    type: ACTION_TYPES.TEMPLATE_SUGGESTION_MODAL
  }),
  updateUser: payload => ({
    changes: { ...payload },
    type: ACTION_TYPES.UPDATE_USER
  }),
  fetchUserPermissionsSuccess: () => ({
    type: ACTION_TYPES.FETCH_USER_PERMISSIONS.SUCCESS
  }),
  fetchUserPermissionsError: () => ({
    type: ACTION_TYPES.FETCH_USER_PERMISSIONS.ERROR
  }),
  fetchSystemPlansSuccess: () => ({
    type: ACTION_TYPES.FETCH_SYSTEM_PLANS.SUCCESS
  }),
  fetchSystemPlansError: () => ({
    type: ACTION_TYPES.FETCH_SYSTEM_PLANS.ERROR
  }),
  openMyworkspaceFeedbackModal: () => ({
    type: ACTION_TYPES.OPEN_MYWORKSPACE_FEEDBACK_MODAL
  })
};
