import { arrayOf, shape, string } from 'prop-types';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ACTION_CREATORS } from '../../../store/actionCreators';
import { SELECTORS } from '../../../store/selectors';
import { getFolderByID } from '../../../utils';
import ContextMenuItemMap from './ContextMenuItems';
import FolderContextMenuContainer from './FolderContextMenuContainer';

export const ContextMenuItems = ({ folderID, menuItems = [] }) => {
  const items = menuItems.length > 0 ? menuItems : useSelector(SELECTORS.getFolderContextItems);
  const folderList = useSelector(SELECTORS.getFolders);
  const dispatch = useDispatch();
  const teamID = getFolderByID(folderList, folderID).team_id || (useSelector(SELECTORS.findTeamByID(folderID)) && folderID);
  const handleClick = id => value => {
    dispatch(ACTION_CREATORS.folderContextAction({
      folderID,
      id,
      value,
      teamID
    }));
  };
  return items.map(item => {
    const { id, type = 'TEXT', itemIcon: ItemIcon } = item;
    const Component = ContextMenuItemMap[type];

    return (
      <li
        key={id}
        onClick={handleClick(id)}
        className={`context-menu-item icon icon-${item.id}`}
        role="option"
        aria-selected={false}
        tabIndex={0}
      >
        {ItemIcon && <ItemIcon />}
        <Component {...item} onClick={handleClick(id)} />
      </li>
    );
  });
};

const FolderContextMenu = props => {
  const { folderID, menuItems = [] } = props;
  return (
    <FolderContextMenuContainer {...props}>
      <ul className="context-menu-list context-menu-root" role="listbox" aria-label="options">
        <ContextMenuItems folderID={folderID} menuItems={menuItems} />
      </ul>
    </FolderContextMenuContainer>
  );
};

FolderContextMenu.propTypes = {
  folderID: string.isRequired,
  menuItems: arrayOf(shape({}))
};

export default FolderContextMenu;
