import { createRoot } from 'react-dom/client';
import { Wizard } from '@jotforminc/wizard-shared';
import React from 'react';
import CreateFlowModal from '.';

const root = document.getElementById('create-asset-modal-container');

export const openCreateFlowModal = ({
  logAbTestAction, hasUserForms, userFormCountLimit, isSideBar, isMobileDevice
}) => {
  const rootNode = createRoot(root);

  return new Promise(resolve => {
    const handleClose = () => {
      resolve({ closed: true });
      rootNode.unmount();
    };
    rootNode.render(<Wizard
      usePortal
      targetContainer="#create-asset-modal-container"
      Navigation={{ CreateFlowModal }}
      DefaultView='CreateFlowModal'
      logAbTestAction={logAbTestAction}
      initialVisibilityState={true}
      onHide={handleClose}
      hasUserForms={hasUserForms}
      userFormCountLimit={userFormCountLimit}
      isSideBar={isSideBar}
      isMobileDevice={isMobileDevice}
    />);
  });
};
