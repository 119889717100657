import React from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { safeLazy, lazyRender } from '@jotforminc/safe-lazy-import';

import WatchmanRecorder from '../utils/WatchmanRecorder';

const CreateNewAgentWizard = safeLazy(() => import(/* webpackChunkName: "LazyWizard_CreateNewAgentWizard" */'./LazyWizards/LazyCreateNewAgentWizard'));

export const openCreateNewAgentModal = props => {
  return new Promise((resolve, reject) => {
    const containerSelector = '#modal-container';
    const root = document.querySelector(containerSelector);

    const disabledContainers = [
      '#listing-utils-root',
      '#enterprise-promotions-modal-root',
      '#healthcare-report-modal-root',
      '#myforms-whitelabeling-root',
      '#app_wizards',
      '#jingle-submission',
      '#reply-content',
      '#preview-content',
      '#share-modal-container',
      '#listing-portal-root',
      '#headlessui-portal-root',
      "[data-uikit-modal-wrapper='true']",
      "[data-uikitgeneratedportalcontainer='true']"
    ];

    disabledContainers.forEach(selector => {
      const currentElement = document.querySelector(selector);
      if (currentElement) {
        currentElement.style.display = 'none';
      }
    });

    const onCreateAgent = () => {
      WatchmanRecorder.trackEvent('click-create-agent-in-wizard');
    };

    const handleClose = () => {
      reject();
      window.history.replaceState({}, '', window.location.pathname);
      disabledContainers.forEach(selector => {
        const currentElement = document.querySelector(selector);
        if (currentElement) {
          currentElement.style.display = 'block';
        }
      });
      unmountComponentAtNode(root);
    };

    lazyRender({
      root,
      children: (
        <CreateNewAgentWizard
          {...props}
          initialVisibilityState={true}
          onCreateAgent={onCreateAgent}
          onHide={handleClose}
        />
      )
    });
  });
};
