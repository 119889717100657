import React, {
  useEffect, useCallback
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useParams } from 'react-router-dom';
import { t } from '@jotforminc/translation';
import { useIsMobile } from '@jotforminc/hooks';
import ListingView from '../../components/ListingView';

import { SELECTORS } from '../../store/selectors';
import { ACTION_CREATORS } from '../../store/actionCreators';
import {
  ASSET_TYPE_MAP, FEATURE_LIST, FOLDER_TYPES, getSortingOptions, LISTING_TYPES, FOLDER_ITEM_ACTION_TYPES,
  ADD_TO_FOLDER_DISABLED_FOLDERS, MOVE_TO_TEAM_DISABLED_FOLDERS, STATUS_FOLDERS, ASSETS_FOLDER_LISTINGS, MORE_MENU_BREAKDOWN_WIDTH, DEFAULT_FOLDER_IDS, LISTING_TYPES_REVERT,
  TYPE_MAP
} from '../../constants';
import WatchmanRecorder from '../../utils/WatchmanRecorder';
import { findItemByIDAndType, getIsMoveToTeamEnabledForProduct, getURLParam } from '../../utils';
import { bulkUpdateToastCall, multipleSelectWithShiftToastCall, trashToastCall } from '../../components/ListItem/toastForItemAction';
import Feature from '../Feature';
import TeamActivityPanel from '../../components/TeamActivityPanel';
import { getDigest } from '../../api';
import { ListActionToast } from '../../contexts/list/utils';
import ConfirmationDialog from '../../utils/Confirmation';
import translate from '../../utils/translate';
import FeedbackButton from '../../components/FeedbackButton';

// TODO: needs a good refactor
const ReduxListingContainer = () => {
  const { id: urlID } = useParams();
  const dispatch = useDispatch();

  const list = useSelector(SELECTORS.getList);
  const filter = useSelector(SELECTORS.getFilter);
  const orderBy = useSelector(SELECTORS.getOrderBy);
  const userEmail = useSelector(SELECTORS.getUserEmail);
  const filterForm = useSelector(SELECTORS.getFilterForm);
  const isAllSelected = useSelector(SELECTORS.isAllSelected);
  const totalCount = useSelector(SELECTORS.getTotalListCount);
  const selectedItems = useSelector(SELECTORS.getSelectedItemsWithInfo);
  const selectedIds = useSelector(SELECTORS.getSelectedItemIDs);
  const lastSelectedListingItemId = useSelector(SELECTORS.getLastSelectedListingItemId);
  const showMultipleSelectWithShiftToast = useSelector(SELECTORS.getShowMultipleSelectWithShiftToast);
  const currentPage = useSelector(SELECTORS.getCurrentPage);
  const assetFilterType = useSelector(SELECTORS.getAssetFilterType);
  const isNewSheetUser = useSelector(SELECTORS.getIsNewSheetsUser);
  const isDigestAllowed = useSelector(SELECTORS.getUserAllowDigest);
  const newCreatedItems = useSelector(SELECTORS.getNewCreatedItems);
  const selectedFolderObject = useSelector(SELECTORS.getSelectedFolderObject);
  const isOwnerOfSelectedFolder = useSelector(SELECTORS.isOwnerOfSelectedFolder);
  const isSharedWithMeFolder = useSelector(SELECTORS.isSharedWithMeFolder);
  const lastOpenedInboxPanelId = useSelector(SELECTORS.getLastOpenedInboxPanelId);
  const isInboxPanelOpen = useSelector(SELECTORS.getIsInboxPanelOpen);
  const selectedFolderID = selectedFolderObject?.id;
  const folderType = useSelector(SELECTORS.getFolderType);
  const teamFilter = useSelector(SELECTORS.getTeamFilter);
  const isTeamsActive = useSelector(SELECTORS.isActiveFeature(FEATURE_LIST.JOTFORM_TEAMS));
  const isTeamMember = useSelector(SELECTORS.getIsTeamMember);
  const isCreateTeamButtonInvisible = useSelector(SELECTORS.getIsTeamCreateButtonInvisible);
  const flattenedFolders = useSelector(SELECTORS.getFlattenedFolders) || [];
  const visibleFlattenedFolders = useSelector(SELECTORS.getVisibleFlattenedFolders);
  const isTablet = useIsMobile(MORE_MENU_BREAKDOWN_WIDTH);
  const hasAnyTeamToMove = flattenedFolders.filter(folder => folder?.permissions?.moveTo && folder.folderType === 'team').length > 0;
  const sortingOptions = getSortingOptions(currentPage, assetFilterType, selectedFolderID);

  const handleSelectSingleItem = ({ id, checked = false, assetType }) => {
    dispatch(ACTION_CREATORS.selectSingleItem(id, checked, assetType));
    dispatch(ACTION_CREATORS.setLastSelectedListingItem(id));
  };
  const handleSelectAll = isSelected => dispatch(ACTION_CREATORS.selectAll(isSelected));
  const handleFullTextChange = fullText => dispatch(ACTION_CREATORS.setFullText(fullText));
  const handleOrderByChange = tmpOrderBy => dispatch(ACTION_CREATORS.setOrderBy(tmpOrderBy));
  const handleSelectItem = (id, checked, assetType) => dispatch(ACTION_CREATORS.selectItem(id, checked, assetType));
  const handleSetLastSelectedListingItem = id => dispatch(ACTION_CREATORS.setLastSelectedListingItem(id));
  const handleDisableMultipleSelectWithShiftToast = () => dispatch(ACTION_CREATORS.disableMultipleSelectWithShiftToast());
  const onDeleteMultiplePortals = () => dispatch(ACTION_CREATORS.onDeleteMultiplePortalsRequest());
  const onDeleteMultipleAgents = () => dispatch(ACTION_CREATORS.onDeleteMultipleAgentsRequest());
  const bulkUpdate = ({ prop = {}, value }) => dispatch(ACTION_CREATORS.bulkUpdateListRequest(prop, value));
  const bulkRestoreArchivedItems = ({ selectedList }) => dispatch(ACTION_CREATORS.bulkUpdateListRequest('status', 'ARCHIVED', true, selectedList));
  const bulkRestoreItemCall = selectedFolderID === DEFAULT_FOLDER_IDS.ARCHIVE ? bulkRestoreArchivedItems : bulkUpdate;
  const bulkDelete = excludedItems => dispatch(ACTION_CREATORS.bulkDeleteRequest(excludedItems));
  const bulkMarkAsRead = () => dispatch(ACTION_CREATORS.bulkMarkAsReadRequest());
  const clearFormFilter = () => {
    dispatch(ACTION_CREATORS.setFilterForm(null));
    dispatch(ACTION_CREATORS.setFilterID(''));
  };

  const getSelectedItemsWithShiftKey = id => {
    let itemsBetween = [];
    if (!selectedItems.length) {
      itemsBetween = [list.find(item => item.id === id)];
    } else {
      const firstSelectedIndex = list.findIndex(item => item.id === lastSelectedListingItemId);
      const lastSelectedIndex = list.findIndex(item => item.id === id);
      itemsBetween = lastSelectedIndex > firstSelectedIndex ? list.slice(firstSelectedIndex, lastSelectedIndex + 1) : list.slice(lastSelectedIndex, firstSelectedIndex + 1);
    }
    return itemsBetween;
  };

  const handleMultipleSelectWithShift = ({
    id, checked, isShiftKey, assetType
  }) => {
    if (!id) {
      return;
    }
    handleSetLastSelectedListingItem(id);
    const isItemSelected = findItemByIDAndType({ list: selectedItems, assetID: id, assetType });

    if (isShiftKey) {
      if (showMultipleSelectWithShiftToast) handleDisableMultipleSelectWithShiftToast();
      const listToSelect = getSelectedItemsWithShiftKey(id);
      listToSelect.forEach(item => handleSelectItem(item.id, checked !== undefined ? checked : !isItemSelected, item.assetType || TYPE_MAP[currentPage]));
    } else {
      if (selectedItems.length === 1 && !isItemSelected && showMultipleSelectWithShiftToast && !isTablet) {
        handleDisableMultipleSelectWithShiftToast();
        multipleSelectWithShiftToastCall();
      }
      handleSelectItem(id, checked, assetType);
    }
  };

  const closeInboxPanel = (type/* , value */) => {
    switch (type) {
      case 'closeClicked':
        dispatch(ACTION_CREATORS.toggleInboxPanel(false));
        break;
      default: break;
    }
  };

  const renewList = () => {
    dispatch(ACTION_CREATORS.fetchListRequest(1));
  };

  const handleFetchList = startIndex => {
    if (!startIndex) {
      return;
    }

    dispatch(ACTION_CREATORS.fetchListRequest(startIndex));
  };
  const toggleFoldersVisibility = isVisible => dispatch(ACTION_CREATORS.setMobileViewFoldersVisibility(isVisible));
  const selectedForBulkUpdate = useSelector(SELECTORS.getSelectedItemIDs);
  const isActionButtonsAllowed = useSelector(SELECTORS.canViewToolbarActions);
  const selectedItemsListingType = useSelector(SELECTORS.getSelectedItemsListingType);
  const currentTeamID = useSelector(SELECTORS.getCurrentTeamID);
  const isAssetsFolderActive = useSelector(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT));
  const isFormListing = selectedItemsListingType === LISTING_TYPES.FORM;
  const isMoveToTeamEnabledForProduct = getIsMoveToTeamEnabledForProduct(selectedItemsListingType);
  const folderActions = {
    // eslint-disable-next-line no-unused-vars
    openAddToFolderMenu: () => () => dispatch(ACTION_CREATORS.toggleAddToFolderMenuVisibility(true)),
    removeFolder: ({ assetType }) => (id, folderID) => dispatch(ACTION_CREATORS.folderItemAction({
      actionType: FOLDER_ITEM_ACTION_TYPES.REMOVE, folderID, itemID: id, assetType
    }))
  };
  const moveToActions = {
    openMoveToTeamMenu: () => () => dispatch(ACTION_CREATORS.toggleMoveToTeamMenuVisibility(true)),
    openMoveFromTeamModal: () => id => dispatch(ACTION_CREATORS.moveFromTeamRequest([id]))
  };
  const sheetAndReportActions = {
    ...folderActions,
    ...moveToActions,
    cloneItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.cloneItemRequest({ id, assetType })),
    deleteItem: ({ assetType }) => (id, custom) => dispatch(ACTION_CREATORS.deleteItemRequest({ id: custom ? custom.id : id, customPath: custom?.path, assetType })),
    onSend: ({ assetType }) => (id, props) => dispatch(ACTION_CREATORS.onSendRequest({ id, props, assetType })),
    generateShareLink: ({ assetType }) => (id, firstTime) => dispatch(ACTION_CREATORS.generateShareLinkRequest({ id, firstTime, assetType })),
    onResendInvitation: () => (id, resolve) => dispatch(ACTION_CREATORS.onResendInvitationRequest(id, resolve)),
    onRevokeUser: ({ assetType }) => (id, shareID) => dispatch(ACTION_CREATORS.onRevokeUserRequest({ id, shareID, assetType })),
    onRevokeMultipleUser: ({ assetType }) => (id, shareIDs) => dispatch(ACTION_CREATORS.onRevokeMultipleUserRequest({ id, shareIDs, assetType })),
    onAssigneePermissionChange: ({ assetType }) => (id, shareValue) => dispatch(ACTION_CREATORS.onAssigneePermissionChangeRequest({ id, shareValue, assetType })),
    onPropertyChange: ({ assetType }) => (sharedID, shareID, shareData) => dispatch(ACTION_CREATORS.onPropertyChangeRequest({
      sharedID, shareID, shareData, assetType
    })),
    trashItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.updateItemRequest({ id, payload: { status: 'TRASHED' }, assetType })),
    restoreItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.updateItemRequest({ id, payload: { status: 'ENABLED' }, assetType })),
    archiveItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.updateItemRequest({ id, payload: { status: 'ARCHIVED' }, assetType })),
    renameItem: ({ assetType }) => (id, payload) => dispatch(ACTION_CREATORS.renameItemRequest({ id, payload, assetType })),
    restoreArchivedItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.bulkUpdateListRequest('status', 'ARCHIVED', true, [{ id, assetType }])),
    star: ({ assetType }) => (id, favoriteKey = 'properties.star') => dispatch(ACTION_CREATORS.setFavorite({ id, favoriteKey, assetType })),
    starSheet: ({ assetType }) => id => dispatch(ACTION_CREATORS.setFavorite({ id, assetType }))
  };
  const itemActionsByListing = {
    [LISTING_TYPES.PAGES]: {
      cloneItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.cloneItemRequest({ id, assetType })),
      purgeItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.deleteItemRequest({ id, assetType })),
      restoreItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.restoreItem({ id, assetType })),
      trashItem: () => id => dispatch(ACTION_CREATORS.deletePage(id)),
      favoriteItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.setFavorite({ id, assetType }))
    },
    [LISTING_TYPES.CONTACTS]: {
      favoriteItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.setFavorite({ id, assetType }))
    },
    [LISTING_TYPES.TASK]: {
      ...folderActions,
      ...moveToActions,
      restoreArchivedItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.bulkUpdateListRequest('status', 'ARCHIVED', true, [{ id, assetType }])),
      favoriteItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.setFavorite({ id, assetType })),
      cloneItem: ({ assetType }) => (id, withForm) => dispatch(ACTION_CREATORS.cloneItemRequest({ id, withForm, assetType })),
      archiveItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.updateItemRequest({ id, payload: { status: 'ARCHIVED' }, assetType })),
      restoreItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.updateItemRequest({ id, payload: { status: 'ENABLED' }, assetType })),
      purgeItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.purgeItem({ id, assetType })),
      deleteItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.deleteTaskItemRequest({ id, assetType })),
      updateWorkflowName: ({ assetType }) => (id, newTitle) => dispatch(ACTION_CREATORS.renameItemRequest({ id, payload: { title: newTitle }, assetType }))
    },
    [LISTING_TYPES.PORTAL]: {
      ...folderActions,
      ...moveToActions,
      favoriteItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.setFavorite({ id, assetType })),
      updateItem: ({ assetType }) => (id, payload) => dispatch(ACTION_CREATORS.updateItemRequest({ id, payload, assetType })),
      trashItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.updateItemRequest({ id, payload: { status: 'TRASHED' }, assetType })),
      deleteItem: ({ assetType }) => (id, custom, itemType) => dispatch(ACTION_CREATORS.deleteItemRequest({
        id: custom ? custom.id : id, customPath: custom?.path, assetType: itemType || assetType
      })),
      archiveItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.updateItemRequest({ id, payload: { status: 'ARCHIVED' }, assetType })),
      restoreItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.updateItemRequest({ id, payload: { status: 'ENABLED' }, assetType })),
      cloneItem: ({ assetType }) => (id, withForm = false, portalRoot) => dispatch(ACTION_CREATORS.cloneItemRequest({
        id, withForm, portalRoot, assetType
      })),
      showCreateDigestModal: ({ assetType }) => selectedID => dispatch(ACTION_CREATORS.createDigest({ id: selectedID, assetType })),
      showEditDigestModal: ({ assetType }) => (id, digestID) => dispatch(ACTION_CREATORS.updateDigest({ id, digestID, assetType }))
    },
    [LISTING_TYPES.REPORT]: sheetAndReportActions,
    [LISTING_TYPES.SHEET]: sheetAndReportActions,
    [LISTING_TYPES.BOARD]: sheetAndReportActions,
    [LISTING_TYPES.DOCUMENT]: {
      ...folderActions,
      ...moveToActions,
      deleteItem: ({ assetType }) => id => {
        const sendEventTrack = target => WatchmanRecorder.trackEvent('click', `${target}`);
        const trackSignEvent = (docId, actionDetails) => sendEventTrack(`${docId}-${actionDetails}`);

        ConfirmationDialog({
          content: translate('All in progress documents will be canceled.'),
          title: translate('Delete Sign Document'),
          backText: translate('No Keep'),
          confirmText: translate('Delete Sign Document'),
          customClass: 'flex justify-center'
        }).then(() => {
          bulkUpdateToastCall('status', 'TRASHED', selectedForBulkUpdate, bulkRestoreItemCall, selectedItems);
          trackSignEvent(id, 'signTrashed');
          return dispatch(ACTION_CREATORS.deleteSignDocumentRequest({ id, assetType }));
        }).catch(() => {});
      },
      cloneItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.cloneSignDocumentRequest({ id, assetType })),
      renameItem: ({ assetType }) => (id, props) => dispatch(ACTION_CREATORS.renameSignDocumentRequest({ id, props, assetType })),
      favoriteItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.setFavorite({ id, assetType }))
    },
    [LISTING_TYPES.AGENT]: {
      ...folderActions,
      ...moveToActions,
      deleteAgent: ({ assetType }) => id => {
        ConfirmationDialog({
          content: translate('Are you sure you want to delete this agent?'),
          title: translate('Delete Agent'),
          backText: translate('No Keep'),
          confirmText: translate('Delete Agent'),
          customClass: 'flex justify-center'
        }).then(() => {
          trashToastCall(id, () => dispatch(ACTION_CREATORS.restoreAgentRequest({ id, refetchList: () => dispatch(ACTION_CREATORS.fetchListRequest()), assetType })));
          return dispatch(ACTION_CREATORS.deleteAgentRequest({ id, assetType }));
        }).catch(() => {});
      },
      cloneAgent: ({ assetType }) => id => dispatch(ACTION_CREATORS.cloneAgentRequest({ id, assetType })),
      renameAgent: ({ assetType }) => (id, props) => dispatch(ACTION_CREATORS.renameAgentRequest({ id, props, assetType })),
      favoriteItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.setFavorite({ id, assetType })),
      archiveAgent: ({ assetType }) => (id, archive) => dispatch(ACTION_CREATORS.archiveAgentRequest({ id, archive, assetType })),
      restoreAgent: ({ assetType }) => id => dispatch(ACTION_CREATORS.restoreAgentRequest({ id, refetchList: () => dispatch(ACTION_CREATORS.fetchListRequest()), assetType })),
      enableAgent: ({ assetType }) => id => dispatch(ACTION_CREATORS.enableAgentRequest({ id, assetType })),
      disableAgent: ({ assetType }) => id => dispatch(ACTION_CREATORS.disableAgentRequest({ id, assetType })),
      purgeAgent: ({ assetType }) => id => dispatch(ACTION_CREATORS.purgeItem({ id, assetType }))
    },
    [LISTING_TYPES.FORM]: {
      ...folderActions,
      ...moveToActions,
      purgeItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.purgeItem({ id, assetType })),
      archiveItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.setArchived({
        id, refetchList: () => dispatch(ACTION_CREATORS.fetchListRequest()), assetType
      })),
      restoreItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.restoreItem({ id, assetType })),
      cloneItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.cloneItemRequest({ id, assetType })),
      deleteItem: ({ assetType }) => id => {
        ConfirmationDialog({
          title: translate('Delete Form'),
          content: translate('Are you sure you want to delete this form?'),
          backText: translate('No Keep'),
          confirmText: translate('Delete Form'),
          customClass: 'text-center'
        }).then(() => {
          bulkUpdateToastCall('status', 'TRASHED', selectedForBulkUpdate, bulkRestoreItemCall, selectedItems);
          return dispatch(ACTION_CREATORS.deleteItemRequest({ id, assetType }));
        }).catch(() => {});
      },
      updateItem: ({ assetType }) => (id, payload) => dispatch(ACTION_CREATORS.updateItemRequest({ id, payload, assetType })),
      renameItem: ({ assetType }) => (id, payload) => dispatch(ACTION_CREATORS.renameItemRequest({ id, payload, assetType })),
      showCreateReportModal: () => selectedID => dispatch(ACTION_CREATORS.createReportWizard({ selectedID })),
      showCreateDigestModal: ({ assetType }) => selectedID => dispatch(ACTION_CREATORS.createDigest({ id: selectedID, assetType })),
      favoriteItem: ({ assetType }) => id => dispatch(ACTION_CREATORS.setFavorite({
        id, favoriteKey: 'favorited', isNumeric: false, assetType
      })),
      updateFormUrl: ({ assetType }) => (formID, newUrl) => dispatch(ACTION_CREATORS.updateItemSuccess({ id: formID, payload: { url: newUrl }, assetType })),
      updateFormStatusRequest: () => (formID, properties) => dispatch(ACTION_CREATORS.updateFormStatusRequest(formID, properties))
    }
  };

  const getItemActionListByAssetType = useCallback(assetType => {
    const teamFavorite = () => id => dispatch(ACTION_CREATORS.setFavorite({
      id, favoriteKey: 'teamFavorite', isNumeric: false, assetType
    }));
    const teamActions = Object.keys(itemActionsByListing).reduce((acc, listingType) => {
      return {
        ...acc,
        [listingType]: {
          ...itemActionsByListing[listingType],
          favoriteItem: teamFavorite,
          starSheet: teamFavorite,
          star: teamFavorite
        }
      };
    }, {});
    const allActionNames = Object.values(teamActions).reduce((prevNames, actions) => {
      return [...new Set([...prevNames, ...Object.keys(actions)])];
    }, []);

    return allActionNames.reduce((prevActionList, actionName) => ({
      ...prevActionList,
      [actionName]: (...args) => {
        let action;
        let currAssetType;
        if (Object.keys(ASSET_TYPE_MAP).indexOf(assetType) > -1) { // Is team asset or mix enabled
          const typeOfAsset = ASSET_TYPE_MAP[assetType] || LISTING_TYPES.FORM;
          const allActions = currentTeamID ? teamActions : itemActionsByListing;
          action = allActions[typeOfAsset][actionName] || (() => () => {});
          currAssetType = assetType;
        } else {
          action = itemActionsByListing[assetFilterType][actionName] || (() => () => {});
          currAssetType = LISTING_TYPES_REVERT[assetFilterType] || '';
        }
        return action({ assetType: currAssetType })(...args);
      }
    }), {});
  }, [itemActionsByListing, assetFilterType, currentTeamID]);

  const handleDigest = async () => {
    try {
      if (isDigestAllowed) {
        const digestUuid = getURLParam('edit-digest');
        const digestFormID = getURLParam('create-digest');
        if (digestUuid) {
          const digestReportFormId = list.find(item => item.id === digestUuid)?.form_id;
          const digest = await getDigest(digestUuid);
          dispatch(ACTION_CREATORS.updateReportWizard('digest', digestReportFormId, digestUuid, { digest }));
        } else if (digestFormID !== null) {
          setTimeout(() => {
            if (isDigestAllowed && digestFormID !== null) {
              // Open wizard action
              dispatch(ACTION_CREATORS.createReportWizard({
                ...digestFormID ? { selectedID: digestFormID } : {},
                reportType: 'digest'
              }));
            }
          }, 1000);
        }
      }
    } catch (error) {
      const errorToast = new ListActionToast('report')?.errorToast;
      errorToast(t(`Digest modal init fail: ${error?.response?.data?.content || error}`));
      // eslint-disable-next-line no-console
      console.error('Digest modal init fail: ', error);
    }
  };

  /* Digest Related URL Param Actions Start */
  useEffect(() => {
    handleDigest();
  }, [isDigestAllowed]);
  /* Digest Related URL Param Actions End */

  // discoverabilityTracking
  useEffect(() => {
    WatchmanRecorder.trackEventOnceForCustomProject('visited', `${currentPage}`, 'discoverabilityTracking', true);
  }, []);

  useEffect(() => { if (urlID) dispatch(ACTION_CREATORS.setURLID(urlID)); }, []);

  useEffect(() => {
    const isMobileApp = global.navigator.userAgent.indexOf('JotForm Mobile') > -1 || global.navigator.userAgent.indexOf('JFCEMobile') > -1;
    if (isMobileApp && currentPage === LISTING_TYPES.DOCUMENT) {
      dispatch(ACTION_CREATORS.createWizard());
    }
  }, []);

  return (
    <>
      <ListingView
        filter={filter}
        orderby={orderBy}
        listSource={list}
        userEmail={userEmail}
        totalCount={totalCount}
        filterForm={filterForm}
        allSelected={isAllSelected}
        selectedItems={selectedItems}
        sortingOptions={sortingOptions}
        isNewSheetUser={isNewSheetUser}
        newCreatedItems={newCreatedItems}
        selectedFolderObject={selectedFolderObject}
        isOwnerOfSelectedFolder={isOwnerOfSelectedFolder}
        selectAll={handleSelectAll}
        selectItem={handleMultipleSelectWithShift}
        setOrderby={handleOrderByChange}
        fetchListSource={handleFetchList}
        setFullText={handleFullTextChange}
        clearFormFilter={clearFormFilter}
        getItemActionListByAssetType={getItemActionListByAssetType}
        selectSingleItem={handleSelectSingleItem}
        toggleFoldersVisibility={toggleFoldersVisibility}
        bulkUpdate={bulkUpdate}
        bulkDelete={bulkDelete}
        bulkMarkAsRead={bulkMarkAsRead}
        onDeleteMultiplePortals={onDeleteMultiplePortals}
        onDeleteMultipleAgents={onDeleteMultipleAgents}
        selectedForBulkUpdate={selectedForBulkUpdate}
        bulkRestoreItemCall={bulkRestoreItemCall}
        isActionButtonsAllowed={isActionButtonsAllowed}
        lastOpenedInboxPanelId={lastOpenedInboxPanelId}
        selectedIds={selectedIds}
        isInboxPanelOpen={isInboxPanelOpen}
        closeInboxPanel={closeInboxPanel}
        renewList={renewList}
        isSharedWithMeFolder={isSharedWithMeFolder}
        isTeamFolder={[FOLDER_TYPES.TEAM, FOLDER_TYPES.ALL_TEAM_RESOURCES, FOLDER_TYPES.TEAM_FOLDER].includes(folderType)}
        teamFilter={teamFilter}
        visibleFlattenedFolders={visibleFlattenedFolders}
        isAddToFolderEnabled={
          Boolean(
            ADD_TO_FOLDER_DISABLED_FOLDERS.indexOf(selectedFolderID) === -1
            && (isFormListing || (isAssetsFolderActive && [LISTING_TYPES.TEAM_PAGE, LISTING_TYPES.MIX, ...ASSETS_FOLDER_LISTINGS].indexOf(currentPage) > -1))
            && !isSharedWithMeFolder
          )
        }
        isMoveToTeamEnabled={
          Boolean(
            isMoveToTeamEnabledForProduct
            && !isSharedWithMeFolder
            && !currentTeamID
            && MOVE_TO_TEAM_DISABLED_FOLDERS.indexOf(selectedFolderID) === -1
            && isTeamsActive
            && !(!hasAnyTeamToMove && isCreateTeamButtonInvisible)
          )
        }
        isMoveFromTeamEnabled={
          Boolean(
            isMoveToTeamEnabledForProduct
            && !isSharedWithMeFolder
            && currentTeamID
            && STATUS_FOLDERS.indexOf(selectedFolderID) === -1
            && currentPage !== LISTING_TYPES.TEAM_PAGE
          )
        }
      />
      <Feature
        name={FEATURE_LIST.JOTFORM_TEAMS}
        activeComponent={<TeamActivityPanel />}
        extraRequirements={!!isTeamMember && currentPage === LISTING_TYPES.TEAM_PAGE}
        inactiveComponent={null}
      />
      {currentPage === LISTING_TYPES.MIX ? <FeedbackButton /> : null}
    </>
  );
};

export default ReduxListingContainer;
