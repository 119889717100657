import { AppIcons } from '@jotforminc/header-components';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { LISTING_TYPES } from '../../constants';
import ModalFormIcon from './assets/ModalFormIcon.svg';
import ModalAppIcon from './assets/ModalAppIcon.svg';
import ModalSignIcon from './assets/ModalSignIcon.svg';
import { ALL_PERMISSIONS } from '../../utils/permissions';

export const TEXTS = {
  HEADER_TITLE: 'How would you like to start?',
  HEADER_SUBTITLE: 'Everything you can look for forms and data collection in one place'
};

export const PRIMARY_PRODUCT_CONFIGS = [
  {
    name: 'Form',
    description: 'Start collecting data with one or multiple page forms',
    Icon: ModalFormIcon,
    type: LISTING_TYPES.FORM,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_FORM
  },
  {
    name: 'App',
    description: 'Make a downloadable mobile app with no coding',
    Icon: ModalAppIcon,
    type: LISTING_TYPES.PORTAL,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_APP
  },
  {
    name: 'Signable Document',
    description: 'Turn your documents into signable documents',
    Icon: ModalSignIcon,
    type: LISTING_TYPES.DOCUMENT,
    createPermissionName: 'Document',
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_DOCUMENT,
    checkVisibility: ({ user }) => {
      return !isEnterprise() || user?.allowSign;
    }
  }
];

export const SECONDARY_PRODUCT_CONFIGS = [
  {
    name: 'Table',
    description: 'Track and manage data in sheets, calendars, reports and more',
    Icon: AppIcons?.tables?.Selected,
    type: LISTING_TYPES.SHEET,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_SHEET
  },
  {
    name: window?.isWorkflowReleased ? 'Workflow' : 'Approval',
    description: 'Automate your approvals within your workflow',
    Icon: AppIcons?.approvals?.Selected,
    type: LISTING_TYPES.WORKFLOW,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_FLOW,
    createPermissionName: 'Approval'
  },
  {
    name: 'Report',
    description: 'Automatically generate reports using your online form data',
    Icon: AppIcons?.reports?.Selected,
    type: LISTING_TYPES.REPORT,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_REPORT
  }
];
