/* eslint-disable @jotforminc/no-native-button */
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import { IconFunnelFilled } from '@jotforminc/svg-icons';
import { Checkbox } from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';
import { Hooks } from '@jotforminc/uikit';

import { SELECTORS } from '../../../store/selectors';
import { ACTION_CREATORS } from '../../../store/actionCreators';
import WatchmanRecorder from '../../../utils/WatchmanRecorder';

import '../../../styles/multipleAssetsDropdown.scss';

const MultipleAssetFilterDropdown = () => {
  const dispatch = useDispatch();
  const buttonRef = useRef();
  const containerRef = useRef();
  const [isOptionsVisible, setOptionsVisibility] = Hooks.useClickOutsideState(false, [containerRef, buttonRef]);
  const assetFilter = useSelector(SELECTORS.getAssetFilter);
  const options = useSelector(SELECTORS.getVisibleAssetFilterOptions);
  const isAllAssetFilterTypesSelected = useSelector(SELECTORS.getIsAllAssetFilterTypesSelected);
  const isMultipleAssetFilterVisible = useSelector(SELECTORS.getIsMultipleAssetFilterVisible);

  Hooks.useEffectIgnoreFirst(() => {
    WatchmanRecorder.trackEvent('click', `multipleAssetFilter${isOptionsVisible ? 'Opened' : 'Closed'}`, 'mixAssetFilter', true);
  }, [isOptionsVisible]);

  const handleAssetFilterChange = (value, isChecked) => {
    WatchmanRecorder.trackEvent('click', `${value.replace('LISTING@', '').toLowerCase()}${isChecked ? 'Selected' : 'Removed'}`, 'mixAssetFilter', true);
    const newAssetFilter = isChecked ? [...assetFilter, value] : assetFilter.filter(assetType => assetType !== value);
    dispatch(ACTION_CREATORS.setAssetFilter(newAssetFilter));
  };

  return isMultipleAssetFilterVisible ? (
    <div
      className={classNames('multipleAssetFilter-wrapper', { active: isOptionsVisible, filtered: !isAllAssetFilterTypesSelected })}
      data-testid="multipleAssetFilterWrapper"
    >
      <button
        className='multipleAssetFilter-cta'
        type='button'
        onClick={() => setOptionsVisibility(!isOptionsVisible)}
        ref={buttonRef}
        data-testid="multipleAssetFilter-button"
      >
        <IconFunnelFilled />
        {t('Filter')}
      </button>
      {isOptionsVisible && (
        <ul className='multipleAssetFilter-list' ref={containerRef}>
          {options.map(({ text, ItemIcon, value }) => {
            const isChecked = assetFilter.includes(value);
            return (
              <li>
                <button
                  className='multipleAssetFilter-button customButton'
                  type='button'
                  onClick={() => handleAssetFilterChange(value, !isChecked)}
                  data-testid={value}
                >
                  <ItemIcon />
                  {text}
                  <Checkbox
                    size="small"
                    checked={isChecked}
                    data-testid={`multipleAssetFilter-${value}-checkboxInput`}
                  />
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  ) : null;
};

export default MultipleAssetFilterDropdown;
