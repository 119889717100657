import { createRoot } from 'react-dom/client';
import React from 'react';
import { t } from '@jotforminc/translation';
import { string } from 'prop-types';

// import useInterval from '../hooks/useInterval';
import Label from '../assets/campaigns/2023/black-friday-2023/label.svg';
import LabelMobile from '../assets/campaigns/2023/black-friday-2023/label-mobile.svg';
import './styles/black-friday-2023.scss';
import { CampaignCountDown } from './utils/CampaignCountDown';

export const BlackFriday2023 = ({ utmSource, accountTypeName }) => {
//   const getDistance = () => {
//     // Get today's date and time
//     const now = new Date().getTime();
//     // Find the distance between now and the count down date
//     const distance = expirationDate - now;
//     return distance;
//   };
//   const [countdown, setCountdown] = useState(getDistance() < 0 ? '00 : 00 : 00' : '24 : 00 : 00');
//   const [delay, setDelay] = useState(getDistance() < 0 ? null : 1000);
//   useInterval(() => {
//     const distance = getDistance();
//     if (distance < 0) {
//       setCountdown('00 : 00 : 00');
//       setDelay(null);
//     }
//     // Time calculations for days, hours, minutes and seconds
//     let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
//     let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
//     let seconds = Math.floor((distance % (1000 * 60)) / 1000);

  //     if (hours < 10) hours = `0${hours}`;

  //     if (minutes < 10) minutes = `0${minutes}`;

  //     if (seconds < 10) seconds = `0${seconds}`;

  //     // Output the result in an element with id="demo"
  //     setCountdown(`${hours}h : ${minutes}m : ${seconds}s `);
  //   }, delay);
  return (
    <section className='blackfriday-2023-wrapper'>
      <div className='blackfriday-2023-container'>
        <div className="label">
          <Label />
        </div>
        <div className="label-mobile">
          <LabelMobile />
        </div>
        <div className="title-container">
          <h5 className="first-title">{t('Black Friday')}</h5>
          <h5 className="second-title">{t('Save 50%')}</h5>
        </div>
        <CampaignCountDown />
        <a className="locale upgrade-now-button" href={`/pricing?utm_campaign=bf2023&utm_medium=banner&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header`}>
          {t('Upgrade Now')}
        </a>
      </div>
    </section>
  );
};

BlackFriday2023.propTypes = {
  utmSource: string.isRequired,
  //   expirationDate: number.isRequired,
  accountTypeName: string.isRequired
};

export const renderBlackFriday2023 = ({
  container, expirationDate, accountTypeName, utmSource
}) => {
  const root = createRoot(container);
  root.render(<BlackFriday2023 utmSource={utmSource} expirationDate={expirationDate} accountTypeName={accountTypeName} />);
};
