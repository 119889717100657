import React, { useEffect, useState } from 'react';
import { bool, func, number } from 'prop-types';
import classNames from 'classnames';
import { t } from '@jotforminc/translation';
import { WizardHeader } from '@jotforminc/wizard-shared';
// import Tracking from '@jotforminc/tracking';
import { PRODUCT_CONFIGS } from './constants';
import { ScWizardBody, ScWizardItem } from './ScWizardBody';

const CreateFlowModal = ({
  logAbTestAction = f => f, hasUserForms = false, userFormCountLimit = null, isSideBar = false, isMobileDevice = false
}) => {
  const defaultSelectedProduct = PRODUCT_CONFIGS[0];
  const [selectedProduct, setSelectedProduct] = useState(defaultSelectedProduct);

  const mobileText = isMobileDevice ? 'mobile-' : '';
  const sidebarText = isSideBar ? 'sidebar-' : '';
  const targetText = !hasUserForms ? 'onlyThree-' : '';

  const handleOpenCreateProductWizard = productType => {
    window.openCreateProductWizard({
      productType,
      createAssetFrom: 'createFlowModal',
      formCountLimit: userFormCountLimit,
      isSideBar,
      newRootSelector: 'createFlowModal-MainStep',
      isEmbeddedToMixedCreationFlow: true,
      targetText
    });
  };

  useEffect(() => {
    logAbTestAction?.({ action: 'seen', target: `${mobileText}${sidebarText}${targetText}CreateFlowModal` });
    handleOpenCreateProductWizard(defaultSelectedProduct.type);
  }, []);

  const handleProductSelect = (item, name) => {
    logAbTestAction?.({ action: 'click', target: `${mobileText}${name}-${sidebarText}${targetText}CreateFlowModal` });
    setSelectedProduct(item);
    handleOpenCreateProductWizard(item.type);
  };

  return (
    <ScWizardItem id="createFlowModal-wrapper">
      <WizardHeader
        noDecodeURI
        title={t(selectedProduct?.title || '')}
        subTitle={t(selectedProduct?.description || '')}
      />
      <ScWizardBody>
        <>
          <ul className="productItem-list createFlowModal-products">
            {PRODUCT_CONFIGS
              .map(item => {
                const {
                  name, Icon, isHiddenForNoFormUsers, SelectedIcon, type
                } = item;
                if (!hasUserForms && isHiddenForNoFormUsers) return null;
                const isSelected = selectedProduct.type === type;

                return (
                  <li clasS>
                    <button
                      key={name}
                      className={classNames('productItem', name.toLowerCase().replace(' ', ''))}
                      onClick={() => handleProductSelect(item, name.toLowerCase().replace(' ', ''))}
                      data-productCount={PRODUCT_CONFIGS.length}
                      type="button"
                      tabIndex="0"
                      aria-label={t(name)}
                    >
                      <div className={classNames('productIconWrapper', isSelected && 'isSelected')}>
                        {isSelected && SelectedIcon && <SelectedIcon className='productIcon' />}
                        {!isSelected && Icon && <Icon className='productIcon' />}
                        <div className='productContentWrapper'>
                          <div className='productName'>{t(name)}</div>
                        </div>
                      </div>
                    </button>
                  </li>
                );
              })}
          </ul>
          <div className="productItem-list" id="createFlowModal-MainStep" />
        </>
      </ScWizardBody>
    </ScWizardItem>
  );
};

CreateFlowModal.propTypes = {
  logAbTestAction: func,
  hasUserForms: bool,
  userFormCountLimit: number,
  isSideBar: bool,
  isMobileDevice: bool
};

export default CreateFlowModal;
