import React, { useRef, useEffect } from 'react';
import { Modal } from '@jotforminc/uikit';
import { func, object } from 'prop-types';
import { t, translationRenderer } from '@jotforminc/translation';
import {
  PrefilledLeadFormAside, ALL_TEXTS, userPropType, GA_FORM_TRACKING_ITEMS, ASSET_IDENTIFIER, GA_AD_FORM_TRACKING_ITEMS,
  GA_SUFFIXES, createGaAttributes, gaDataLayerPushClose, hasAdwordsModeOn, mapCallbacksToGaTrackingItems, setEPActions
} from '@jotforminc/enterprise-promotions-utils';
import { ContentRenderer, DialogRenderer, HeaderRenderer } from './Renderers';
import EnterpriseIcon from '../../../../assets/svg/enterpriseRocketMini.svg';
import '../../../../styles/folderLimitModal.scss';

const FolderLimitModal = (({
  user,
  modalProps,
  onClose,
  ...props
}) => {
  const { PRODUCT: { FOLDER_LIMIT_MODAL } } = ASSET_IDENTIFIER;
  const target = FOLDER_LIMIT_MODAL;

  const isAdForm = hasAdwordsModeOn();
  let { [target]: gaFormTrackingItems } = isAdForm ? GA_AD_FORM_TRACKING_ITEMS : GA_FORM_TRACKING_ITEMS;

  const gaAttributes = createGaAttributes(target);
  const gaAttributesClose = createGaAttributes(target, GA_SUFFIXES.CLOSE);

  useEffect(() => {
    // registerEnterprisePromotionsAction({ action: 'seen', target });
    setEPActions({ asset: target, target, action: 'seen' });
  }, []);

  const handleClose = () => {
    gaDataLayerPushClose(gaAttributes);
    // registerEnterprisePromotionsAction({ action: 'close', target });
    setEPActions({ asset: target, target, action: 'close' });
    onClose();
  };

  const handleContactSalesClick = () => {
    // registerEnterprisePromotionsAction({ action: 'click', target: `applyNowButton-${target}` }); // It has been updated. The old target was "contactSalesButton-${target}"
    setEPActions({ asset: target, target: 'applyNowButton', action: 'click' });
  };

  const handleLearnMoreClick = () => {
    // registerEnterprisePromotionsAction({ action: 'click', target: `learnMoreButton-${target}` });
    setEPActions({ asset: target, target: 'learnMoreButton', action: 'click' });
  };

  const handleFormSubmit = () => {
    // registerEnterprisePromotionsAction({ action: 'formSubmit', target });
    setEPActions({ asset: target, target, action: 'formSubmit' });
  };

  const uikitModalRef = useRef(null);

  const handleCloseClick = () => {
    uikitModalRef.current?.hide();
    // registerEnterprisePromotionsAction({ action: 'click', target: `closeButton-${target}` });
    setEPActions({ asset: target, target: 'closeButton', action: 'click' });
  };

  gaFormTrackingItems = mapCallbacksToGaTrackingItems(gaFormTrackingItems, [handleContactSalesClick, handleLearnMoreClick]);

  return (
    <Modal
      ref={uikitModalRef}
      defaultVisible={true}
      closeOnEscPress={true}
      closeOnOutsideClick={false}
      DialogRenderer={DialogRenderer}
      ContentRenderer={prps => <ContentRenderer {...gaAttributes} {...prps} />}
      HeaderRenderer={() => <HeaderRenderer {...gaAttributesClose} onCloseClick={handleCloseClick} />}
      onModalClose={handleClose}
      {...modalProps}
    >
      <div className="row">
        <div className="left-col direct-visible">
          <span className='badge'>
            <EnterpriseIcon className="icon" />
            {t(ALL_TEXTS.JOTFORM_ENTERPRISE_CAPITALIZED)}
          </span>
          <h2 className='title'>
            {translationRenderer(ALL_TEXTS.REACHED_FOLDER_LIMIT)({
              renderer1: lineText => (
                <span className='new-line-on-desktop'>{lineText}</span>
              )
            })}
          </h2>
          <p className='description'>
            {translationRenderer(ALL_TEXTS.APPLY_FOR_ENTERPRISE_FOLDER)({
              renderer1: lineText => (
                <span className='new-line-on-desktop'>{lineText}</span>
              )
            })}
          </p>
          <img src='//cdn.jotfor.ms/assets/img/enterprise/folder-limit-modal/limit.png' alt={t(ALL_TEXTS.APPLY_FOR_ENTERPRISE_FOLDER)} className="graph-preview" />
        </div>
        {/* aside */}
        <div className="right-col">
          <PrefilledLeadFormAside
            formUiModifier={FOLDER_LIMIT_MODAL}
            user={user}
            gaAttributes={gaAttributes}
            gaFormTrackingItems={gaFormTrackingItems}
            title={t(ALL_TEXTS.SHORT_LEAD_FORM_TITLE)}
            onFormSubmit={handleFormSubmit}
            isAdForm={isAdForm}
            {...props}
          />
        </div>
      </div>
    </Modal>
  );
});

FolderLimitModal.propTypes = {
  user: userPropType.isRequired,
  modalProps: object,
  onClose: func
};

FolderLimitModal.defaultProps = {
  modalProps: {},
  onClose: f => f
};

export default FolderLimitModal;
