import {
  all, spawn, put, call
} from 'redux-saga/effects';
import { rootUIFlow } from '../main/ui';
import { rootUrlFlow } from '../main/url';
import { rootListFlow } from '../main/list';
import { rootFolderFlow } from '../main/folder';
import { rootFeatureFlow } from '../main/feature';
import { rootUserFlow, initializeUser } from '../main/user';
import { rootJotformExperienceFlows } from '../main/jotformExperience';
import { rootPermissionFlow } from '../main/permission';
import { rootTeamFlow } from '../main/team';
import { rootMixFlow } from '../main/mix';
import { rootNavigationFlow } from '../main/navigation';

import { ACTION_CREATORS } from '../../store/actionCreators';
import { rootJotformEnterpriseExperienceFlows } from '../main/jotformEnterpriseExperience';

export const flowList = [
  rootUserFlow,
  rootFolderFlow,
  rootUIFlow,
  window.JOTFORM_ENV === 'ENTERPRISE' ? null : rootJotformExperienceFlows,
  window.JOTFORM_ENV === 'ENTERPRISE' ? rootJotformEnterpriseExperienceFlows : null,
  rootFeatureFlow,
  rootListFlow,
  rootPermissionFlow,
  rootUrlFlow,
  rootTeamFlow,
  rootMixFlow,
  rootNavigationFlow
].filter(flow => !!flow);

export function* rootSagaFlow() {
  const flowSpawnList = flowList.map(flow => spawn(flow));
  yield all(flowSpawnList);

  yield call(initializeUser);
  yield put(ACTION_CREATORS.fetchFolders());
}
