import { AppIcons } from '@jotforminc/header-components';
import { LISTING_TYPES } from '../../constants';

// todo :: AI & document visibility checks
export const PRODUCT_CONFIGS = [
  {
    name: 'Form',
    title: 'Create form',
    description: 'Powerful forms that use conditional logic, accept payments, generate reports, and automate workflows.',
    Icon: AppIcons?.formBuilder?.Default,
    SelectedIcon: AppIcons?.formBuilder?.Selected,
    type: LISTING_TYPES.FORM
  },
  {
    name: 'App',
    title: 'Create app',
    description: 'No-code app builder that lets you add forms, widgets, data and your branding to one fully customized app that can be downloaded onto any mobile device or computer.',
    Icon: AppIcons?.portal?.Default,
    SelectedIcon: AppIcons?.portal?.Selected,
    type: LISTING_TYPES.PORTAL
  },
  {
    name: 'E-signature',
    title: 'Create signable document',
    description: 'Collect e-signatures',
    Icon: AppIcons?.sign?.Default,
    SelectedIcon: AppIcons?.sign?.Selected,
    type: LISTING_TYPES.DOCUMENT,
    createPermissionName: 'Document'
  },
  {
    name: 'Table',
    title: 'Create table',
    description: 'Track and manage data in powerful Spreadsheets, Calendars, Reports and more',
    Icon: AppIcons?.tables?.Default,
    SelectedIcon: AppIcons?.tables?.Selected,
    type: LISTING_TYPES.SHEET,
    isHiddenForNoFormUsers: true
  },
  {
    name: 'Approval',
    title: 'Create approval',
    description: 'Create an approval flow to automate your approval process',
    Icon: AppIcons?.approvals?.Default,
    SelectedIcon: AppIcons?.approvals?.Selected,
    type: LISTING_TYPES.TASK,
    isHiddenForNoFormUsers: true
  },
  {
    name: 'Report',
    title: 'Create report',
    description: 'Generate reports for your data',
    Icon: AppIcons?.reports?.Default,
    SelectedIcon: AppIcons?.reports?.Selected,
    type: LISTING_TYPES.REPORT,
    isHiddenForNoFormUsers: true,
    createPermissionName: 'Document'
  }
];
