import React from 'react';
import PropTypes, { string, bool } from 'prop-types';
import { onClickOutsideHOC } from '@jotforminc/account-box';
import { ActionManager } from '@jotforminc/abtest-manager';

import { appConfigPropsTypes } from './navigationConstants';
import Navigation from '.';
import NavigationTrigger from './NavigationTrigger';
import '../../styles/_jfAppPicker.scss';
import '../../styles/_jfAppPickerSideMode.scss';

class NavigationWrapper extends React.PureComponent {
  constructor(props) {
    super(props);

    // discoverabilityTracking
    const { user = {} } = this.props;
    this.discoverabilityTracking = new ActionManager({ user, projectName: 'discoverabilityTracking', trackOnce: true });
  }

  handleClickOutside() {
    const { isNavigationOpened = false, toggleNavigation = f => f } = this.props;
    if (isNavigationOpened) { toggleNavigation(); }
  }

  render() {
    const {
      appList = [],
      appID = '',
      formIDs = [],
      appName = '',
      appTag = '',
      form = {},
      forms = [],
      isNavigationOpened = false,
      toggleNavigation = () => {},
      backToMyFormsText = 'Go Back to My Forms',
      backToMyFormsLink = '/myforms',
      onMyformsClick = null,
      showBackToMyForms = true,
      summary = {},
      visibleNavigationApps = [],
      title = '',
      hideIfEmpty = false,
      isAppPickerSideModeActive = false,
      isAppPickerTooltipActive = false,
      onAppPickerTooltipOverlayClicked = f => f,
      onAppPickerTooltipCloseIconClicked = f => f,
      onAppPickerTooltipRendered = f => f,
      darkMode = false,
      isInSsrHeader = false,
      teamID = '',
      teams = [],
      openCreateTeamModal = () => {},
      showTeamSection = false,
      isTeamPage = false,
      isInListings = false,
      isTeamCreationDisabled = false,
      isTeamBrowsingDisabled = false,
      createForm = f => f,
      appConfig = {},
      user
    } = this.props;

    return (
      <div className={`jNewHeader-appSelector iBlock ${isInSsrHeader ? 'SsrHeader' : ''}`}>
        <NavigationTrigger
          toggleNavigation={toggleNavigation}
          appTitle={title ? title : appConfig.title}
          appName={appName}
          appTag={appTag}
          summary={summary}
          logDiscoverabilityActions={this.discoverabilityTracking.registerJotformAction}
          isAppPickerSideModeActive={isAppPickerSideModeActive}
          isAppPickerTooltipActive={isAppPickerTooltipActive}
          onAppPickerTooltipOverlayClicked={onAppPickerTooltipOverlayClicked}
          onAppPickerTooltipCloseIconClicked={onAppPickerTooltipCloseIconClicked}
          onAppPickerTooltipRendered={onAppPickerTooltipRendered}
          darkMode={darkMode}
          isNavigationOpened={isNavigationOpened}
        />
        <Navigation
          toggleNavigation={toggleNavigation}
          summary={summary}
          showBackToMyForms={showBackToMyForms}
          backToMyFormsText={backToMyFormsText}
          backToMyFormsLink={backToMyFormsLink}
          onMyformsClick={onMyformsClick}
          form={form}
          forms={forms}
          appID={appID}
          formIDs={formIDs}
          appConfig={appConfig}
          appName={appName}
          appList={appList}
          visibleNavigationApps={visibleNavigationApps}
          user={user}
          hideIfEmpty={hideIfEmpty}
          logDiscoverabilityActions={this.discoverabilityTracking.registerJotformAction}
          isAppPickerSideModeActive={isAppPickerSideModeActive}
          teamID={teamID}
          teams={teams}
          openCreateTeamModal={openCreateTeamModal}
          showTeamSection={showTeamSection}
          isTeamPage={isTeamPage}
          isInListings={isInListings}
          isTeamCreationDisabled={isTeamCreationDisabled}
          isTeamBrowsingDisabled={isTeamBrowsingDisabled}
          createForm={createForm}
        />
      </div>
    );
  }
}

NavigationWrapper.propTypes = {
  appConfig: appConfigPropsTypes.isRequired,
  appList: PropTypes.arrayOf(PropTypes.string),
  appID: PropTypes.string,
  formIDs: PropTypes.arrayOf(PropTypes.string),
  appName: PropTypes.string,
  appTag: PropTypes.string,
  showBackToMyForms: PropTypes.bool,
  form: PropTypes.shape({}),
  forms: PropTypes.arrayOf(PropTypes.shape({})),
  isNavigationOpened: PropTypes.bool,
  toggleNavigation: PropTypes.func,
  backToMyFormsText: string,
  backToMyFormsLink: string,
  onMyformsClick: PropTypes.func,
  summary: PropTypes.shape({}),
  visibleNavigationApps: PropTypes.arrayOf(PropTypes.string),
  title: string,
  user: PropTypes.shape({
    account_type: PropTypes.shape({
      name: PropTypes.string
    })
  }).isRequired,
  hideIfEmpty: bool,
  isAppPickerSideModeActive: bool,
  isAppPickerTooltipActive: bool,
  onAppPickerTooltipOverlayClicked: PropTypes.func,
  onAppPickerTooltipCloseIconClicked: PropTypes.func,
  onAppPickerTooltipRendered: PropTypes.func,
  darkMode: PropTypes.bool,
  isInSsrHeader: PropTypes.bool,
  teamID: PropTypes.string,
  teams: PropTypes.arrayOf(PropTypes.shape({})),
  openCreateTeamModal: PropTypes.func,
  showTeamSection: PropTypes.bool,
  isTeamPage: PropTypes.bool,
  isInListings: PropTypes.bool,
  isTeamCreationDisabled: PropTypes.bool,
  isTeamBrowsingDisabled: PropTypes.bool,
  createForm: PropTypes.func
};

export default onClickOutsideHOC(NavigationWrapper);
