import { ACTION_TYPES } from '../actionTypes';

const initialState = {
  type: '',
  credentials: {
    account_type: { name: '' },
    language: 'en-US',
    email: '',
    spreadsheets: ''
  },
  forms: [],
  systemPlans: {},
  permissions: [],
  campaingType: 'NONE',
  availableCampaingList: [],
  logAbTestAction: null,
  isEmbedMainStepTestUser: false,
  isCreationModalTestUser: false,
  formsWithProperties: [],
  teamFormsWithProperties: {}
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_USER.SUCCESS: {
      return { ...state, ...action.user };
    }
    case ACTION_TYPES.UPDATE_USER_PROPERTY: {
      return {
        ...state,
        credentials: {
          ...state.credentials,
          ...action.changes
        }
      };
    }
    case ACTION_TYPES.FETCH_ALL_FORMS.SUCCESS: {
      return {
        ...state,
        forms: action.forms
      };
    }
    case ACTION_TYPES.UPDATE_USER: {
      return { ...state, ...action.changes };
    }
    case ACTION_TYPES.FETCH_ALL_FORMS_WITH_PROPS.SUCCESS: {
      const { teamID, forms } = action;

      if (teamID) {
        return {
          ...state,
          teamFormsWithProperties: {
            ...state.teamFormsWithProperties,
            [teamID]: forms
          }
        };
      }

      return {
        ...state,
        formsWithProperties: forms
      };
    }
    default:
      return state;
  }
}
