import {
  select, call, put, all, take
} from 'redux-saga/effects';

import { SELECTORS } from '../../store/selectors';
import { ACTION_CREATORS } from '../../store/actionCreators';

import { LISTING_TYPES, DEFAULT_FOLDER_IDS } from '../../constants';
import { ACTION_TYPES } from '../../store/actionTypes';
import { ALL_PERMISSIONS } from '../../utils/permissions';
import { flattenFolders, getLastSelectedFolder } from '../../utils';

export function* handleFolders() {
  const permissions = yield select(SELECTORS.getUserPermissions);
  const currentPage = yield select(SELECTORS.getCurrentPage);
  const currentTeamID = yield select(SELECTORS.getCurrentTeamID);
  const isDataOnlyUser = yield select(SELECTORS.getDataOnlyUser);

  switch (currentPage) {
    case LISTING_TYPES.FORM: {
      if (!permissions.includes(ALL_PERMISSIONS.CREATE_FORM) && !currentTeamID) {
        const lastSelectedFolder = getLastSelectedFolder(currentPage);
        const teamsRelatedFolders = yield select(SELECTORS.getTeamRelatedFolders);
        const shareRelatedFolders = yield select(SELECTORS.getShareRelatedFolders);
        const visibleFolders = isDataOnlyUser ? [...teamsRelatedFolders, ...shareRelatedFolders] : shareRelatedFolders;
        yield put(ACTION_CREATORS.setFolders(visibleFolders));
        const doesLastSelectedFolderExist = flattenFolders(visibleFolders).some(({ id }) => id === lastSelectedFolder);

        if (!lastSelectedFolder || !doesLastSelectedFolderExist) {
          yield put(ACTION_CREATORS.setSelectedFolder(DEFAULT_FOLDER_IDS.SHARED_WITH_ME));
        }
      }
      break;
    }
    default:
      break;
  }
}

export function* rootPermissionFlow() {
  yield all([
    take(ACTION_TYPES.FETCH_USER.SUCCESS),
    take(ACTION_TYPES.SET_SELECTED_FOLDER)
  ]);
  yield call(handleFolders);
}
