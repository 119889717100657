import React, { useCallback } from 'react';
import {
  bool, number, string, oneOfType
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { useDispatch } from 'react-redux';
import { ACTION_CREATORS } from '../../store/actionCreators';
import WatchmanRecorder from '../../utils/WatchmanRecorder';

const TeamMembersCountButton = ({
  teamID = '', memberCount = 1, isAdmin = false, disabled = false
}) => {
  const dispatch = useDispatch();
  const showInviteMemberModal = useCallback((e, inviteOnly) => {
    e.stopPropagation();
    dispatch(ACTION_CREATORS.showInviteMemberModal(inviteOnly, teamID, isAdmin));
    WatchmanRecorder.trackEventForCustomProject('click', 'myFormsMemberCount', 'teams', true);
  }, [isAdmin]);

  return (
    <button
      type="button"
      onClick={e => showInviteMemberModal(e, false)}
      disabled={disabled}
    >
      {memberCount}
      &nbsp;
      {memberCount > 1 ? t('members') : t('member')}
    </button>
  );
};

TeamMembersCountButton.propTypes = {
  teamID: string,
  memberCount: oneOfType([number, string]),
  isAdmin: bool,
  disabled: bool
};

export default TeamMembersCountButton;
