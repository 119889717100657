import {
  all, select, put, call, takeLeading, spawn, take
} from 'redux-saga/effects';
import isNil from 'lodash/isNil';
import { t } from '@jotforminc/translation';
import { handleCustomNavigation } from '@jotforminc/utils';

import { channel } from 'redux-saga';
import askConfirmation from '../../modals/ConfirmationModal';
import ConfirmationDialog from '../../utils/Confirmation';
import { openCreateNewFormModal } from '../../wizards/CreateNewFormModal';

import * as API from '../../api';
import { SELECTORS } from '../../store/selectors';
import { ACTION_CREATORS } from '../../store/actionCreators';

import { ACTION_TYPES } from '../../store/actionTypes';
import {
  ASSET_TYPES, FOLDER_ITEM_ACTION_TYPES, FOLDER_TYPES, LISTING_TYPES
} from '../../constants';
import { ListActionToast } from '../../contexts/list/utils';
import { registerUniqueAction, removeListDuplications, removeListDuplicationsWithType } from '../utils';
import { TEAM_PERMISSIONS } from '../../utils/permissions';
import { PRODUCT_CONFIGS } from '../../modals/CreateAssetModal/constants';
import { archiveToastCall, restoreToastCall } from '../../components/ListItem/toastForItemAction';
import { openCreateNewReportModal } from '../../wizards/CreateNewReportModal';
import { getAssetTypeFromListingType, findItemByIDAndType } from '../../utils';
import WatchmanRecorder from '../../utils/WatchmanRecorder';
import { openCreateFlowModal } from '../../modals/CreateFlowModal/utils';
import { openMixAssetModal } from '../../modals/MixAssetModal/utils';

export function* getUserFormLimitExceeded() {
  let { userFormCountLimit } = global.window;

  try {
    const response = yield call(API.getUserLimitExceeded);
    if (typeof response?.content === 'boolean') {
      userFormCountLimit = response.content;
    }
    if (typeof response === 'boolean') {
      userFormCountLimit = response;
    }
  } catch (error) {
    console.log('API.getUserLimitExceeded error:', error);
  }

  return userFormCountLimit;
}

export function* handleFormClone({ id }) {
  try {
    const userFormCountLimit = yield call(getUserFormLimitExceeded);
    if (userFormCountLimit) {
      yield put(ACTION_CREATORS.openLimitDialog());
    } else {
      const username = yield select(SELECTORS.getUserName);
      const { newID } = yield call(API.cloneForm, { formID: id, username });
      handleCustomNavigation(`/build/${newID}`, '_blank');
      yield put(ACTION_CREATORS.fetchListRequest());
    }
  } catch {
    yield put(ACTION_CREATORS.cloneItemError());
  }
}

export function* handleFetchAllForms() {
  try {
    // Check if we fetched all forms already
    let forms = yield select(SELECTORS.getUserForms);

    if (!forms.length) {
      forms = yield call(API.fetchAllFormsInfo);

      yield put(ACTION_CREATORS.fetchAllFormsSuccess(forms));
    }

    return forms;
  } catch (e) {
    yield put(ACTION_CREATORS.fetchAllFormsError());
    console.log('handleFetchAllForms error:', e);
    return [];
  }
}

export function* handleFavoriteItem({ id }) {
  const { favorited } = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.FORM));
  yield call(API.favoriteForm, id, favorited);
}

export function* handleArchiveItem({ id, refetchList }) {
  const { archived } = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.FORM));
  const newArchived = !archived;
  yield all([
    call(API.archiveForm, id, newArchived),
    yield put(ACTION_CREATORS.deleteItemSuccess(id, ASSET_TYPES.FORM))
  ]);
  const restoreCall = async formID => {
    await API.archiveForm(formID);
    refetchList();
  };
  if (newArchived) {
    yield call(archiveToastCall, id, restoreCall);
  } else {
    yield call(restoreToastCall, id);
  }
}

function* handleFolderActionsBulk({ actionType, folderIDs, items }) {
  // Currently we handle it one by one but in future if a bulk item update endpoint is implemented
  // This saga should be changed to use that.
  const folders = removeListDuplications(folderIDs);
  const itemList = removeListDuplicationsWithType(items);
  const currentTeamID = yield select(SELECTORS.getCurrentTeamID);
  const selectedItems = yield select(SELECTORS.getItemsByList(itemList));

  if (folders.includes(currentTeamID)) {
    for (let i = 0; i < selectedItems.length; i++) {
      const item = selectedItems[i];
      const itemFolders = item.folders?.split?.(',')?.filter?.(f => f) || [];
      for (let j = 0; j < itemFolders.length; j++) {
        const folderID = itemFolders[j];
        yield put(ACTION_CREATORS.folderItemAction({
          actionType, itemID: item.id, folderID, isBulk: true, assetType: LISTING_TYPES.FORM
        }));
      }
    }
  } else {
    for (let i = 0; i < folders.length; i++) {
      const folderID = folders[i];
      for (let j = 0; j < itemList.length; j++) {
        const item = itemList[j];
        yield put(ACTION_CREATORS.folderItemAction({
          actionType, itemID: item.id, folderID, isBulk: true, assetType: LISTING_TYPES.FORM
        }));
      }
    }
  }
}

function* handleFolderActions({
  actionType, folderID, itemID, isBulk
}) {
  let apiMethod;
  const teamID = yield select(SELECTORS.getCurrentTeamID);
  const type = 'form';
  const filter = yield select(SELECTORS.getFilter);
  const teamPermissions = yield select(SELECTORS.getTeamPermissions);
  const isCreateTeamFolderEnabled = teamPermissions[TEAM_PERMISSIONS.CREATE_FOLDER];
  if (!isCreateTeamFolderEnabled && teamID) {
    return;
  }
  switch (actionType) {
    case FOLDER_ITEM_ACTION_TYPES.ADD:
      // Prevent duplicate
      const form = yield select(SELECTORS.getItemByIDAndType(itemID, ASSET_TYPES.FORM));
      if (form?.folders?.includes(folderID)) {
        return;
      }
      apiMethod = teamID ? API.addToTeamFolder : API.addFormToFolder;
      break;
    case FOLDER_ITEM_ACTION_TYPES.REMOVE:
      apiMethod = teamID ? API.removeItemFromTeamFolder : API.removeFormFromFolder;
      break;
    default:
      return;
  }
  yield put({
    type: ACTION_TYPES.FOLDER_ITEM_ACTION_PROCESS, folderID, itemID, actionType
  });
  try {
    yield call(apiMethod, {
      folderID,
      formID: itemID,
      teamID,
      type
    });
    const selectedFolderID = yield select(SELECTORS.getSelectedFolder);
    if (actionType === FOLDER_ITEM_ACTION_TYPES.REMOVE && folderID === selectedFolderID) {
      yield put(ACTION_CREATORS.setFilter(filter));
    }
  } catch (err) {
    if (isBulk) {
      yield put(ACTION_CREATORS.setFilter(filter));
    } else {
      yield put({
        type: ACTION_TYPES.FOLDER_ITEM_ACTION_PROCESS, folderID, itemID, actionType: actionType === FOLDER_ITEM_ACTION_TYPES.ADD ? FOLDER_ITEM_ACTION_TYPES.REMOVE : FOLDER_ITEM_ACTION_TYPES.ADD
      });
    }
    const errorToast = new ListActionToast('form')?.errorToast;
    errorToast(t('Something went wrong.'));
  }
}

export function* handleFormTransfer({ username, itemIDs, folders }) {
  const description = t('Are you sure you would like to transfer ownership of this form to {username}? This action cannot be reversed.').replace('{username}', username);
  try {
    yield call(askConfirmation, {
      title: t('Change Form Ownership'),
      description,
      confirmButtonColor: 'primary'
    });
    let movedItemIDs = [];
    let errorMessage;

    for (let i = 0; i < itemIDs.length; i++) {
      try {
        const response = yield call(API.transferForm, { formID: itemIDs[i], username, folders });
        if (response?.message?.includes?.('HIPAA')) {
          errorMessage = 'This feature disabled for HIPAA users.';
        } else {
          movedItemIDs = [...movedItemIDs, { id: itemIDs[i], assetType: ASSET_TYPES.FORM }];
        }
      } catch (err) {
        errorMessage = err?.response?.data?.error || 'Something went wrong.';
      }
    }
    if (errorMessage) {
      const errorToast = new ListActionToast('form')?.errorToast;
      errorToast(t(errorMessage));
    }
    const deletedItemList = yield select(SELECTORS.getItemsByList(movedItemIDs));
    yield put(ACTION_CREATORS.bulkDeleteSuccess(deletedItemList));
  } catch (err) {
    // Modal closed
  }
}

export function* handlePurgeItem({ id }) {
  try {
    yield call(ConfirmationDialog, {
      title: t('Delete Form'),
      content: t('All your submissions, reports and other stuff related to this form will be gone forever. This operation cannot be undone.')
    });

    yield all([
      call(API.purgeFormItem, id),
      yield put(ACTION_CREATORS.deleteItemSuccess(id, ASSET_TYPES.FORM))
    ]);
  } catch (err) {
    // Modal closed
  }
}

export function* handleRestoreItem({ id }) {
  yield all([
    call(API.restoreFormItem, id),
    put(ACTION_CREATORS.deleteItemSuccess(id, ASSET_TYPES.FORM))
  ]);
}

export function* handleMarkSelectedRead() {
  const [list, selectedItems] = yield all([
    select(SELECTORS.getList),
    select(SELECTORS.getSelectedItemsWithInfo)
  ]);

  const newList = list.map(item => {
    if (!findItemByIDAndType({ list: selectedItems, assetID: item?.id, assetType: item?.assetType })) {
      return item;
    }
    return { ...item, new: '0' };
  });
  yield all([
    call(API.bulkMarkAsReadForm, selectedItems.map(sItem => sItem.id)),
    put(ACTION_CREATORS.setList(newList))
  ]);
}

export function* handleCreateApp() {
  const selectedIDList = yield select(SELECTORS.getSelectedItemIDs);
  const currentTeamID = yield select(SELECTORS.getCurrentTeamID);
  handleCustomNavigation(`/app/create/${selectedIDList.join(',')}${currentTeamID ? `?teamID=${currentTeamID}` : ''}`, '_blank');
}

export function* handleCreateDigest() {
  try {
    const userCredentials = yield select(SELECTORS.getUserCredentials);
    const selectedIDList = yield select(SELECTORS.getSelectedItemIDs);
    const currentTeamID = yield select(SELECTORS.getCurrentTeamID);

    let forms;
    if (currentTeamID) {
      const [
        assetFilter,
        currentPage,
        isAllAssetFilterSelected
      ] = yield all([
        select(SELECTORS.getAssetFilter),
        select(SELECTORS.getCurrentPage),
        select(SELECTORS.getIsAllAssetFilterTypesSelected)
      ]);
      forms = yield call(API.fetchTeamAssets, {
        selectedFolder: currentTeamID,
        type: getAssetTypeFromListingType(LISTING_TYPES.FORM),
        disableJotFormNormalize: false,
        isAllAssetFilterSelected,
        currentPage,
        assetFilter,
        folderType: FOLDER_TYPES.TEAM,
        skipV2: true
      });
    } else {
      forms = yield call(handleFetchAllForms);
    }

    const { report } = yield call(openCreateNewReportModal, {
      forms: forms.filter(form => !['DELETED', 'PURGED'].includes(form.status)),
      user: userCredentials,
      formId: selectedIDList,
      reportType: 'digest',
      teamID: currentTeamID
    });

    if (report?.type === 'digest') {
      if (userCredentials?.digestBetaAccepted !== '1') {
        yield put(ACTION_CREATORS.updateUserProperty({ digestBetaAccepted: '1' }));
      }
      yield put(ACTION_CREATORS.fetchListRequest());
      yield put(ACTION_CREATORS.selectItem(report.id, true, ASSET_TYPES.REPORT));
    }
  } catch (e) {
    // error
    console.log({ e });
  }
}

const fetchAllFormsChannel = channel();

// eslint-disable-next-line complexity
export function* handleFormCreateWizard({
  canClose = true, skipMixAssetModal = false, disableCreateFlowLightBox = false, createAssetFrom, callback = f => f, onWizardMount = f => f, formCountLimit, isSideBar, targetText = '',
  isMixPageUser, ...rest
}) {
  try {
    const team = yield select(SELECTORS.getCurrentTeam);
    const folderID = yield select(SELECTORS.getSelectedFolderIDForCreation);
    const teamPermissions = yield select(SELECTORS.getTeamPermissions) || {};
    const signConfig = PRODUCT_CONFIGS.find(config => config?.type === LISTING_TYPES.DOCUMENT);
    const canCreateSignDocument = (signConfig && signConfig.createPermissionName) ? teamPermissions[`create${signConfig.createPermissionName}`] : false;
    const userFormCountLimit = !isNil(formCountLimit) ? formCountLimit : yield call(getUserFormLimitExceeded);
    const logAbTestAction = yield select(SELECTORS.getUserLogAbTestAction);
    const isMobileDevice = yield select(SELECTORS.getIsMobileDevice);
    const isCreationModalTestUser = yield select(SELECTORS.getIsCreationModalTestUser);

    const isFormPickerInfiniteLoadingEnabled = window.location.search.includes('formPickerInfiniteLoadingEnabled');

    if (userFormCountLimit) {
      yield put(ACTION_CREATORS.openLimitDialog());
    } else {
      const forms = yield select(SELECTORS.getUserForms);
      const preparedProps = {
        isMixAssetCreationModal: (isCreationModalTestUser || isMixPageUser) && !folderID,
        backToMixModalActionLogger: () => WatchmanRecorder.trackEventForCustomProject('click', `${isMobileDevice ? 'mobile-' : ''}back-to-mix-modal-from-create-forms`, 'mixAssetCreationModal', true),
        fetchFormsCallback: isFormPickerInfiniteLoadingEnabled ? f => f : API.fetchAllFormsInfo,
        fetchFormsSuccess: isFormPickerInfiniteLoadingEnabled ? f => f : fetchedForms => fetchAllFormsChannel.put({ fetchedForms }),
        isFormPickerInfiniteLoadingEnabled,
        fetchInfiniteFormsCallback: API.fetchAllFormsWithSearch,
        forms,
        canClose,
        ...folderID ? { folderID } : {},
        ...(!!team && { teamID: team.id, teamName: team.name, folderID }),
        disableSignCreation: team && team.id && !canCreateSignDocument,
        creationLogger: actionEvent => {
          WatchmanRecorder.trackEvent('click', `create-form-button${isSideBar ? '-sideBar' : ''}-${actionEvent}-form-created`, 'forms', true);
          if (createAssetFrom && logAbTestAction) {
            logAbTestAction({ action: 'click', target: `${isMobileDevice ? 'mobile-' : ''}${isSideBar ? 'sideBar-' : ''}${targetText}${createAssetFrom}-${actionEvent}-form-created` });
            logAbTestAction({ action: 'click', target: 'actTest-formCreated' });
          }
        },
        ...rest
      };
      const isEmbedMainStepTestUser = yield select(SELECTORS.getIsEmbedMainStepTestUser);
      const currentPage = yield select(SELECTORS.getCurrentPage);
      if (!folderID && isEmbedMainStepTestUser && currentPage === LISTING_TYPES.FORM && !disableCreateFlowLightBox) {
        const userForms = yield call(API.fetchUserForms, {
          filter: { archived: '0', 'status:ne': ['DELETED', 'PURGED'] },
          offset: 0,
          orderby: 'created_at',
          limit: 1,
          isCreationTest: true,
          disableTeamIDHeader: true
        });
        import('../../wizards/LazyWizards/LazyCreateNewFormWizard');
        import('../../wizards/LazyWizards/LazyCreateNewPortalWizard');
        import('../../wizards/LazyWizards/LazyCreateNewDocumentWizard');
        import('../../wizards/LazyWizards/LazyCreateNewTableWizard');
        import('../../wizards/LazyWizards/LazyCreateNewWorkflowWizard');
        import('../../wizards/LazyWizards/LazyCreateNewReportWizard');
        yield call(openCreateFlowModal, {
          logAbTestAction, hasUserForms: !!userForms?.data?.content?.length, userFormCountLimit, isSideBar, isMobileDevice
        });
      } else if (!folderID && isCreationModalTestUser && currentPage === LISTING_TYPES.FORM && !skipMixAssetModal) {
        const user = yield select(SELECTORS.getUserCredentials);
        const userForms = yield call(API.fetchUserForms, {
          filter: { archived: '0', 'status:ne': ['DELETED', 'PURGED'] },
          offset: 0,
          orderby: 'created_at',
          limit: 1,
          isCreationTest: true,
          disableTeamIDHeader: true
        });
        import('../../wizards/LazyWizards/LazyCreateNewFormWizard');
        import('../../wizards/LazyWizards/LazyCreateNewPortalWizard');
        import('../../wizards/LazyWizards/LazyCreateNewDocumentWizard');
        import('../../wizards/LazyWizards/LazyCreateNewTableWizard');
        import('../../wizards/LazyWizards/LazyCreateNewWorkflowWizard');
        import('../../wizards/LazyWizards/LazyCreateNewReportWizard');
        yield call(openMixAssetModal, {
          user, hasUserForms: !!userForms?.data?.content?.length, isMobileDevice, userFormCountLimit, isSideBar
        });
      } else {
        yield call(openCreateNewFormModal, { ...preparedProps, onWizardMount }, callback);
      }
    }
  } catch (e) {
    console.log('error:', e);
  }
}

export function* watchFetchAllFormsChannel() {
  while (true) {
    const { fetchedForms } = yield take(fetchAllFormsChannel);
    yield put(ACTION_CREATORS.fetchAllFormsSuccess(fetchedForms));
  }
}

/**
 * Process a form status update request, for now, this only works when the
 * publish data and status changes, it triggers a network fetch to get the new
 * status.
 */
export function* handleUpdateFormStatusRequest({ formID, properties }) {
  try {
    const updatedKeys = Object.keys(properties);
    const forms = yield call(API.fetchUserForms, { filter: { 'id:eq': formID } });
    if (forms.data.content.length !== 1) {
      yield put(ACTION_CREATORS.updateFormStatusError());
    } else if (updatedKeys.includes('status') && updatedKeys.includes('publishData')) {
      const { id, status } = forms.data.content[0];
      const isMixAssetFilter = yield select(SELECTORS.getIsMixAssetFilter);
      yield put(ACTION_CREATORS.updateFormStatusSuccess(id, status, isMixAssetFilter));
    }
  } catch (e) {
    yield put(ACTION_CREATORS.updateFormStatusError());
  }
}

export function* rootFormFlow() {
  yield spawn(registerUniqueAction, ACTION_TYPES.CREATE_WIZARD, handleFormCreateWizard);
  yield spawn(registerUniqueAction, ACTION_TYPES.TRANSFER_ITEM.REQUEST, handleFormTransfer, takeLeading);
  yield spawn(registerUniqueAction, ACTION_TYPES.FOLDER_ITEM_ACTION.REQUEST, handleFolderActions);
  yield spawn(registerUniqueAction, ACTION_TYPES.FOLDER_ITEM_ACTION_BULK.REQUEST, handleFolderActionsBulk);
  yield spawn(registerUniqueAction, ACTION_TYPES.SET_FAVORITE, handleFavoriteItem);
  yield spawn(registerUniqueAction, ACTION_TYPES.CLONE_ITEM.REQUEST, handleFormClone);
  yield spawn(registerUniqueAction, ACTION_TYPES.SET_ARCHIVED, handleArchiveItem);
  yield spawn(registerUniqueAction, ACTION_TYPES.PURGE_ITEM, handlePurgeItem);
  yield spawn(registerUniqueAction, ACTION_TYPES.RESTORE_ITEM, handleRestoreItem);
  yield spawn(registerUniqueAction, ACTION_TYPES.BULK_MARK_AS_READ, handleMarkSelectedRead);
  yield spawn(registerUniqueAction, ACTION_TYPES.CREATE_APP, handleCreateApp);
  yield spawn(registerUniqueAction, ACTION_TYPES.CREATE_DIGEST, handleCreateDigest);
  yield spawn(registerUniqueAction, ACTION_TYPES.UPDATE_FORM_STATUS.REQUEST, handleUpdateFormStatusRequest);
  yield spawn(watchFetchAllFormsChannel);
}
// use registerUniqueAction for listBased sagas, otherwise they will be duplicated because of handleFolderSelect function in main/folder.js file
