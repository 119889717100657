import React, { Suspense } from 'react';
import { Loading } from '@jotforminc/loading';
import { safeLazy } from '@jotforminc/safe-lazy-import';
import { createRoot } from 'react-dom/client';
import { UXRSurveyModal } from '@jotforminc/uxr-survey-modal';
import { EducationBdmFlow } from '@jotforminc/enterprise-promotions';
import { enterprisePromotionSingleton } from '@jotforminc/enterprise-promotions-utils';
import { setCookie } from '@jotforminc/utils';
import { StorageHelper } from '@jotforminc/storage-helper';
import { MODAL_TYPES } from '../constants';
import BuilderUtils from '../utils/BuilderUtils';
import { filterPlansForUserType } from './campaignUtils';
import { getPortalRoot, isProduction } from '../utils';
import { renderNotificationCenter } from './NotificationCenter';
import AgentBetaLaunchModal from '../modals/AgentBetaLaunchModal';
import { updateUserSettings } from '../api';

const FormCopilotModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_HealthcareFutureWebinarModal" */'../modals/FormCopilotModal'));
const IndustryModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_IndustryModal" */'./LazyWizards/LazyIndustryModal'));
const BouncedEmailDialogModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_BouncedEmailDialogModal" */'./LazyWizards/LazyBouncedEmailDialogModal'));
const LaunchModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_LaunchModal" */'./LazyWizards/LazyLaunchModal'));
const HipaaEnforcementWrapper = safeLazy(() => import(/* webpackChunkName: "LazyModal_HipaaEnforcementWrapper" */'../modals/HipaaEnforcementWrapper'));
const EmailNotVerifiedContainerModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_EmailNotVerifiedContainerModal" */'../modals/EmailNotVerifiedContainerModal'));
const KvkkConsent = safeLazy(() => import(/* webpackChunkName: "LazyModal_KvkkConsentModal" */'../modals/KvkkConsentModal/KvkkConsent'));
const MyformsBanner = safeLazy(() => import(/* webpackChunkName: "LazyModal_MyformsBanner" */'../modals/MyformsBanner/MyformsBanner'));
const BonusCampaignPopup = safeLazy(() => import(/* webpackChunkName: "LazyModal_BonusCampaignPopup" */'../modals/BonusCampaignPopup'));
const HealthcareInfoModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_HealthcareInfoModal" */'../modals/HealthcareInfoModal'));
const AustralianDataResidencyAbTester = safeLazy(() => import(/* webpackChunkName: "LazyModal_AustralianDataResidencyAbTester" */'../modals/AustralianDataResidencyModal'));
const ReactivationAnnouncement = safeLazy(() => import(/* webpackChunkName: "LazyModal_ReactivationAnnouncement" */'../modals/ReactivationAnnouncement'));
const Lightbox = safeLazy(() => import(/* webpackChunkName: "LazyModal_Lightbox" */'./Lightbox'));
const EnterpriseGovernmentModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_EnterpriseGovernmentModal" */'../modals/EnterpriseGovernmentModal'));
const HealthcareReportBanner = safeLazy(() => import(/* webpackChunkName: "LazyModal_HealthcareReportBanner" */'../modals/HealthcareReportBanner'));
const EarthquakeDonationModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_EarthquakeDonationModal" */'../modals/EarthquakeDonationModal/EarthquakeDonationModal'));
const MobileAppQRCode = safeLazy(() => import(/* webpackChunkName: "LazyModal_MobileAppQRCode" */'../modals/MobileAppQRCodeModal'));
const EnterpriseDiscountModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_EnterpriseDiscountModal" */'../modals/EnterpriseDiscountModal'));
const ReactivationModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_ReactivationModal" */'../modals/ReactivationModal'));
export const TemplateSuggestionModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_TemplateSuggestionModal" */'../modals/TemplateSuggestionModal'));
const MyworkspaceSuggestionModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_MyworkspaceSuggestionModal" */'../modals/MyworkspaceSuggestionModal'));
const AdvertisementLeadFlow = safeLazy(() => import(/* webpackChunkName: "LazyModal_AdvertisementLeadFlow" */'../modals/AdvertisementLeadFlow'));
const AnnouncingModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_Announcing" */'../modals/AnnouncingModal'));
const SalesforceEarlyAccessModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_SalesforceEarlyAccessModal" */'../modals/SalesforceEarlyAccessModal'));
const SalesforceRatingModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_SalesforceRatingModal" */'../modals/SalesforceRatingModal'));
const JotformersTeamModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_JotformersTeamModal" */'../modals/JotformersTeamModal'));
const AbandonedUserModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_AbandonedUserModal" */'../modals/AbandonedUserModal'));
const CvForceSignupModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_EdTechWebinarModal" */'../modals/CvForceSignupModal'));
const NhsModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_NhsModal" */'../modals/NhsModal'));
const HrWebinarModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_NhsModal" */'../modals/HrWebinarModal'));
const ListingsIncreaseLimitsModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_ListingsIncreaseLimitsModal" */'../modals/ListingsIncreaseLimitsModal'));
const HealthcareTechnologyWebinarModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_HealthcareTechnologyWebinarModal" */'../modals/HealthcareTechnologyWebinarModal'));
const HongKongDataResidencyModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_HongKongDataResidencyModal" */'../modals/HongKongDataResidencyModal'));
const SingaporeDataResidencyModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_SingaporeDataResidencyModal" */'../modals/SingaporeDataResidencyModal'));
const HealthcareFutureWebinarModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_HealthcareFutureWebinarModal" */'../modals/HealthcareFutureWebinarModal'));

// eslint-disable-next-line complexity
export async function renderExperienceModals(modalType, credentials, campaignData, systemPlans, list) {
  const props = {};
  const portalRoot = getPortalRoot();
  const root = createRoot(portalRoot);
  const { username } = credentials;

  if (window.location.href.includes('debugJfExperience=1')) {
    console.log({
      modalType, credentials, campaignData, systemPlans
    });
  }
  const renderLazy = RenderedComponent => root.render(
    <Suspense fallback={<Loading />}>
      {RenderedComponent}
    </Suspense>
  );

  if (typeof window?.JotFormActions !== 'function') {
    window.JotFormActions = () => {
      return { tick: () => { } };
    };
  }

  switch (modalType) {
    case MODAL_TYPES.FORM_COPILOT_CTA_MODAL:
      renderLazy(<FormCopilotModal list={list} onClose={() => root.unmount()} user={credentials} />);
      break;
    case MODAL_TYPES.EXPERIENCE_TEMPLATE_SUGGESTION_MODAL:
      let templateSuggestionTracker = f => f;
      if (window.JotFormActions) {
        ({ tick: templateSuggestionTracker } = window.JotFormActions('myformsTemplateSuggestionModal'));
      }
      renderLazy(
        <TemplateSuggestionModal
          user={credentials}
          tracker={templateSuggestionTracker}
          currentPage={campaignData?.currentPage}
          handleClose={() => root.unmount()}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_MYWORKSPACE_SUGGESTION_MODAL:
      let myworkspaceSuggestionTracker = f => f;
      if (window.JotFormActions) {
        ({ tick: myworkspaceSuggestionTracker } = window.JotFormActions('myworkspaceSuggestionModal'));
      }
      renderLazy(
        <MyworkspaceSuggestionModal
          user={credentials}
          tracker={myworkspaceSuggestionTracker}
          handleClose={() => root.unmount()}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_HIPAA_ENFORCEMENT:
      const hipaaWizardElement = document.createElement('div');
      hipaaWizardElement.id = 'hipaa-enforcement-section';
      document.body.appendChild(hipaaWizardElement);
      BuilderUtils.openHIPAAWizard();
      props.credentials = credentials;
      renderLazy(<HipaaEnforcementWrapper {...props} />);
      break;
    case MODAL_TYPES.EXPERIENCE_ANNOUNCING_LIGHTBOX:
      const announcingGroupDigestTracker = window.JotFormActions('announcing-salesforce-lightbox');
      const handleCloseActionAnnouncingLightbox = () => {
        announcingGroupDigestTracker.tick({ action: 'close', target: 'announcing-salesforce-lightbox', actor: username });
        const ttl = 1000 * 60 * 60 * 24 * 365;
        StorageHelper.setLocalStorageItem({ key: 'announcing-salesforce-lightbox', value: '1', ttl: ttl });
        root.unmount();
      };

      renderLazy(
        <AnnouncingModal
          username={username}
          logger={announcingGroupDigestTracker.tick}
          accountType={credentials?.account_type?.name}
          handleClose={handleCloseActionAnnouncingLightbox}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_HEALTHCARE_INFO:
      const { tick } = window.JotFormActions('healthcareInfoModal');
      const handleCloseAction = () => {
        tick({ action: 'close', target: 'healthcare-info-modal', actor: username });
        setCookie('closeHealthcareInfoModal', '1', 10000);
        root.unmount();
      };
      renderLazy(
        <HealthcareInfoModal
          logger={tick}
          username={username}
          handleClose={handleCloseAction}
          accountType={credentials?.account_type?.name}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_ABANDONED_USER:
      renderLazy(
        <AbandonedUserModal />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_INDUSTRY:
      renderLazy(
        <IndustryModal
          {...props}
          credentials={credentials}
          onClose={() => root.unmount()}
          shuffleIndList={false}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_NOT_VERIFIED: {
      const { email } = credentials;
      renderLazy(<EmailNotVerifiedContainerModal email={email} username={username} />);
      break;
    }
    case MODAL_TYPES.EXPERIENCE_BOUNCE:
      const { email } = credentials;
      props.email = email;
      renderLazy(
        <BouncedEmailDialogModal
          modalProps={{
            defaultVisible: true,
            usePortal: true
          }}
          onClose={() => root.unmount()}
          {...props}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_UXR_SURVEY:
      const uxrSurveyFormID = window?.uxrSurveyFormID;
      renderLazy(<UXRSurveyModal formID={uxrSurveyFormID} username={username} />);
      break;
    case MODAL_TYPES.EXPERIENCE_KVKK_CONSENT:
      renderLazy(
        <KvkkConsent
          handleClose={() => root.unmount()}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_ENTERPRISE_AUSTRALIAN_MODAL:
      renderLazy(
        <AustralianDataResidencyAbTester
          user={credentials}
          seenAtKey={enterprisePromotionSingleton.seenAtKey}
          onClose={() => root.unmount()}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_CAMPAIGN_LIGHTBOX: {
      const campaignLightboxTracker = window.JotFormActions(`${campaignData?.activeCampaign?.campaignKey}${new Date().getFullYear()}`);
      const { tick: tracker = f => f } = campaignLightboxTracker;
      const filteredPlans = filterPlansForUserType(
        campaignData?.plans,
        campaignData?.userAccountType,
        campaignData?.activeCampaign?.campaignName
      );
      const isCustomCampaign = ['CHURCH', 'ANIMAL-SHELTER'].includes(credentials?.campaign?.industryCampaign || '');
      if (!isCustomCampaign && filteredPlans.length === 0) {
        break;
      }

      const target = 'lightbox';

      const handleCloseLightbox = (tickAction = true) => {
        if (tickAction) {
          tracker({ action: 'click-outside', target, actor: username });
        }
        document.body.classList.remove('lightboxOpened');
        root.unmount();
      };

      renderLazy(
        <Lightbox
          target={target}
          tracker={tracker}
          user={credentials}
          username={username}
          plans={filteredPlans}
          systemPlans={systemPlans}
          campaignData={campaignData}
          onClose={handleCloseLightbox}
        />
      );
      break;
    }
    case MODAL_TYPES.EXPERIENCE_MYFORMS_BANNER:
      let loggerFunction = f => f;
      if (window.JotFormActions) {
        ({ tick: loggerFunction } = window.JotFormActions('myforms-banner'));
      }
      renderLazy(
        <MyformsBanner
          username={username}
          loggerFunction={loggerFunction}
          closeBanner={() => root.unmount()}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_BONUS_CAMPAIGN_POPUP:
      renderLazy(
        <BonusCampaignPopup
          username={username}
          campaignData={campaignData}
          closeBanner={() => root.unmount()}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_AGENT_BETA_LAUNCH_MODAL:
      let agentBetaLaunchTracker = f => f;
      if (window.JotFormActions) ({ tick: agentBetaLaunchTracker } = window.JotFormActions('agent-beta-launch-modal'));
      renderLazy(
        <AgentBetaLaunchModal
          username={username}
          tracker={agentBetaLaunchTracker}
          handleClose={() => root.unmount()}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_SALESFORCE_EARLY_ACCESS:
      let trackerFunction = f => f;
      if (window.JotFormActions) ({ tick: trackerFunction } = window.JotFormActions('salesforce-early-access-modal'));
      renderLazy(
        <SalesforceEarlyAccessModal
          username={username}
          tracker={trackerFunction}
          handleClose={() => root.unmount()}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_SALESFORCE_RATING_MODAL:
      let salesforceRatingModalTracker = f => f;
      ({ tick: salesforceRatingModalTracker } = (
        (isProduction() && window.JotFormActions)
          ? window.JotFormActions('salesforce-rating-modal')
          : {
            tick: payload => console.log('salesforceRatingModalTracker', payload)
          })
      );
      renderLazy(
        <SalesforceRatingModal
          username={username}
          tracker={salesforceRatingModalTracker}
          handleClose={async (tickAction = true) => {
            await updateUserSettings({ salesforceRatingModal: '2' });
            if (tickAction) {
              salesforceRatingModalTracker({ action: 'click-outside', target: 'salesforceRatingModal', actor: username });
            }
            root.unmount();
          }}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_REACTIVATION_ANNOUNCEMENT:
      renderLazy(
        <ReactivationAnnouncement
          handleClose={() => root.unmount()}
          username={username}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_LAUNCH_MODAL:
      if (window.location.search === '?createTeam=1') return;
      const { lightboxName, loggerName } = campaignData || {};
      const launchModalLogger = window.JotFormActions(loggerName);
      const setLaunchLocalStorage = () => {
        const ttl = 1000 * 60 * 60 * 24 * 365;
        StorageHelper.setLocalStorageItem({ key: `seen-${lightboxName}`, value: '1', ttl: ttl });
      };
      const handleClose = (event, rememberChoice = false) => {
        setLaunchLocalStorage();
        launchModalLogger.tick({ action: rememberChoice ? 'close' : 'close-outside', target: lightboxName, actor: username });
        root.unmount();
      };

      renderLazy(
        <LaunchModal
          username={username}
          onClose={handleClose}
          logger={launchModalLogger.tick}
          accountType={credentials?.account_type?.name}
          setLaunchLocalStorage={setLaunchLocalStorage}
          modalProps={{ exceptionalSelectorList: ['#CookieBanner'] }}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_JOTFORMERS_TEAM:
      const normalizeName = name => {
        const space = name.indexOf(' ');
        const normalizedName = name.substring(0, space);
        return normalizedName;
      };

      const { name = '' } = credentials;
      const parsedName = (name === '') ? username : normalizeName(name);
      const { jotformersTeamLaunchCode = '' } = campaignData;

      if (!jotformersTeamLaunchCode) return;

      renderLazy(
        <JotformersTeamModal username={parsedName} jotformersTeamLaunchCode={jotformersTeamLaunchCode} />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_ENTERPRISE_GOVERNMENT:
      renderLazy(
        <EnterpriseGovernmentModal
          user={credentials}
          seenAtKey={enterprisePromotionSingleton.seenAtKey}
          onClose={() => root.unmount()}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_HEALTHCARE_REPORT:
      renderLazy(
        <HealthcareReportBanner
          user={credentials}
          seenAtKey={enterprisePromotionSingleton.seenAtKey}
          onClose={() => root.unmount()}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_EARTHQUAKE_DONATION_MODAL:
      renderLazy(
        <EarthquakeDonationModal
          user={credentials}
          plans={campaignData.plans}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_MOBILE_APP_QR_CODE:
      const logger = window.JotFormActions('myformsMobileQRModal').tick;
      renderLazy(
        <MobileAppQRCode user={credentials} loggerFunction={logger} />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_NOTIFICATION_CENTER:
      renderNotificationCenter({
        user: credentials,
        initInAutoVisibleMode: true
      });
      break;
    case MODAL_TYPES.EXPERIENCE_ENTERPRISE_DISCOUNT_MODAL:
      renderLazy(
        <EnterpriseDiscountModal
          user={credentials}
          industry={enterprisePromotionSingleton.details.industry}
          seenAtKey={enterprisePromotionSingleton.seenAtKey}
          onClose={() => root.unmount()}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_NHS_MODAL:
      renderLazy(
        <NhsModal
          user={credentials}
          seenAtKey={enterprisePromotionSingleton.seenAtKey}
          onClose={() => root.unmount()}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_REACTIVATION_MODAL:
      const { modalData } = campaignData;
      renderLazy(
        <ReactivationModal modalData={modalData} />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_ADVERTISEMENT_LEAD_FLOW:
      renderLazy(
        <AdvertisementLeadFlow
          user={credentials}
          isExitIntentModalAvailable={enterprisePromotionSingleton.details.isAdLeadFlowExitIntentModalAvailable}
          seenAtKey={enterprisePromotionSingleton.seenAtKey}
          onClose={() => root.unmount()}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_CV_FORCE_SIGNUP_MODAL:
      renderLazy(
        <CvForceSignupModal
          user={credentials}
          variationCode={campaignData}
          onClose={() => root.unmount()}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_EDUCATION_BDM_FLOW:
      renderLazy(
        <EducationBdmFlow
          user={credentials}
          onClose={() => root.unmount()}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_LISTINGS_INCREASE_LIMITS_MODAL:
      renderLazy(
        <ListingsIncreaseLimitsModal user={credentials} />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_HR_WEBINAR_MODAL:
      renderLazy(
        <HrWebinarModal
          user={credentials}
          seenAtKey={enterprisePromotionSingleton.seenAtKey}
          onClose={() => root.unmount()}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_NONPROFIT_DONATION_WEBINAR_MODAL:
      renderLazy(
        <HealthcareTechnologyWebinarModal
          user={credentials}
          seenAtKey={enterprisePromotionSingleton.seenAtKey}
          onClose={() => root.unmount()}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_HONGKONG_DATA_RESIDENCY_MODAL:
      renderLazy(
        <HongKongDataResidencyModal
          user={credentials}
          seenAtKey={enterprisePromotionSingleton.seenAtKey}
          onClose={() => root.unmount()}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_SINGAPORE_DATA_RESIDENCY_MODAL:
      renderLazy(
        <SingaporeDataResidencyModal
          user={credentials}
          seenAtKey={enterprisePromotionSingleton.seenAtKey}
          onClose={() => root.unmount()}
        />
      );
      break;
    case MODAL_TYPES.EXPERIENCE_HEALTHCARE_FUTURE_WEBINAR_MODAL:
      renderLazy(
        <HealthcareFutureWebinarModal
          user={credentials}
          seenAtKey={enterprisePromotionSingleton.seenAtKey}
          onClose={() => root.unmount()}
        />
      );
      break;
    default:
      return false;
  }
  return modalType;
}
