import { useState, useEffect } from 'react';
import sortBy from 'lodash/sortBy';
import {
  checkLanguageSwitch, checkHipaaCompliance, checkHipaaEnforcement, checkPaymentOverdue,
  checkUxrSurvey, checkNewsletter, checkAppRecommendation, checkStoreRecommendation,
  checkSignAnnouncement, checkUnreadSupportAnswers, checkSignTry,
  checkHubspotMilestone, checkLunchAndLearn, checkEnterprisePriceAdjustment, checkUpToFiftyPercentDiscount,
  checkResellerLaunch
} from '../notifications';
import { isInHubspotAvoidedList, getUserNotifications, notificationSingleton } from '../utils';
import { notificationConfig } from '../constants';

const addButtonCallbacks = notifications => {
  const notificationCheckers = [
    checkAppRecommendation, checkHipaaCompliance, checkHipaaEnforcement, checkLanguageSwitch, checkNewsletter,
    checkPaymentOverdue, checkSignAnnouncement, checkSignTry, checkStoreRecommendation, checkUnreadSupportAnswers,
    checkUxrSurvey, checkHubspotMilestone, checkLunchAndLearn, checkEnterprisePriceAdjustment, checkUpToFiftyPercentDiscount,
    checkResellerLaunch
  ];

  let allNotifications = notificationCheckers.reduce((currentNotifications, currentChecker) => currentChecker(currentNotifications), notifications);

  // update isSeen flag from local storage
  allNotifications = allNotifications.map(notif => {
    const seenNotifs = JSON.parse(window.localStorage.getItem('ncseen')) || {};
    return { ...notif, isSeen: seenNotifs[notif.name]?.isSeen || false };
  });

  return sortBy(allNotifications, [notification => notification.priority]);
};

export const useNotifications = user => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (notificationSingleton.notifications.length > 0) {
      setNotifications(notificationSingleton.notifications);
      return;
    }

    getUserNotifications()
      .then(notifcs => addButtonCallbacks(notifcs))
      .then(async notifcs => {
        const hasHubspotMilestone = notifcs.find(notif => notif.name === 'hubspotMilestone');
        let filterdNotifications = notifcs;
        notificationSingleton.notifications = notifcs;

        // check milestone
        if (hasHubspotMilestone) {
          const { content: isAvoided = true } = await isInHubspotAvoidedList();
          const { hubspotMilestone: { urlParam } } = notificationConfig;
          const hasUrlParam = global.location.href.indexOf(urlParam) > -1;
          if (isAvoided && !hasUrlParam) {
            filterdNotifications = notifcs.filter(notif => notif.name !== 'hubspotMilestone');
          }
        }

        setNotifications(filterdNotifications);
      }).catch(e => console.log(e));
  }, [user]);

  return [notifications, setNotifications];
};
