import React from 'react';
import { createSelector } from 'reselect';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import { useSelector } from 'react-redux';
import { translationRenderer } from '@jotforminc/translation';
import { canCreateTeam, isServerTeamSettingsEnabled } from '@jotforminc/enterprise-components';
import {
  flattenFolders, makeFolderGroup, constructFolderListByLayout, getLastSelectedFolder, prettyDate, findItemByIDAndType, showAssetFilterOnTeamPage, canAccessMyAgentsPage
} from '../utils';

import {
  DEFAULT_FOLDER_IDS, LIST_FETCH_LIMIT, LISTING_TYPES, FOLDER_TYPES, ASSET_TYPE_MAP, getFolderContextMenuItems, ASSET_CREATION_DISABLED_FOLDERS, ASSET_FILTER_OPTIONS_WITHOUT_ALL,
  FEATURE_LIST, ASSETS_FOLDER_LISTINGS, ASSET_CREATION_MENU_ITEM, teamFolderContextMenuItems, ALL_ASSETS_ID, LISTING_TYPES_REVERT, MIX_FILTER_ENABLED_LISTING_TYPES, ASSET_FILTER_DROPDOWN_OPTIONS,
  BULK_ACTION_RESTRICTEDS, STATUS_FOLDERS, ENABLE_MOVE_AGENT_TO_TEAM
} from '../constants';

import { ALL_PERMISSIONS } from '../utils/permissions';

const getFeatures = state => state.feature;
const isActiveFeature = featureName => createSelector(getFeatures, features => !!features[featureName]);

const getListState = state => state.list;
const getIsListInitialState = createSelector(getListState, listState => listState.isInitialState);
const getList = createSelector(getListState, listState => listState.list);
const getBaseList = createSelector(getListState, listState => listState.baseList);
const getListCount = createSelector(getList, list => list.length);
const getLastResultSetCount = createSelector(getListState, listState => listState.lastResultSetCount);
const getDeletedItems = createSelector(getListState, list => list.deletedItems);

const getTotalListCount = createSelector(
  [getListCount, getLastResultSetCount],
  (count, lastResultSetCount) => {
    if (lastResultSetCount >= LIST_FETCH_LIMIT) {
      return count + LIST_FETCH_LIMIT;
    }
    return count;
  }
);
const getItemByIDAndType = (id, assetType) => createSelector(getList, list => findItemByIDAndType({ list, assetID: id, assetType }));
const getItemByID = id => createSelector(getList, list => list.find(item => id === item.id));
const getItemsByList = itemList => createSelector(getList, list => {
  return itemList.map(listItem => findItemByIDAndType({ list, assetID: listItem.id, assetType: listItem.assetType }));
});
const getNewCreatedItems = createSelector(getListState, listState => listState.newCreatedItems);
// share
const getGeneratedShareByAssetType = (id, assetType) => createSelector(getItemByIDAndType(id, assetType), item => item?.share);

const getUI = state => state.ui;
export const getFilter = createSelector(getUI, ui => ui.filter);
export const getIsFilterSet = createSelector(getUI, ui => ui.isFilterSet);
export const getFilterForm = createSelector(getUI, ui => ui.filterForm);
const getOrderBy = createSelector(getUI, ui => ui.orderBy);
const getFilterID = createSelector(getUI, ui => ui.filterID);
const isAllSelected = createSelector(getUI, ui => ui.isAllSelected);
const getSelectionStatus = createSelector(getUI, ui => ui.selectedItems);
const getLastOpenedInboxPanelId = createSelector(getUI, ui => ui.lastOpenedInboxPanelId);
const getIsInboxPanelOpen = createSelector(getUI, ui => ui.isInboxPanelOpen);
const getIsTeamActivityPanelOpen = createSelector(getUI, ui => ui.isTeamActivityPanelOpen);
const getIsAddToFolderMenuVisible = createSelector(getUI, ui => ui.isAddToFolderMenuVisible);
const getIsMoveToTeamMenuVisible = createSelector(getUI, ui => ui.isMoveToTeamMenuVisible);
const getIsAllFoldersVisible = createSelector(getUI, ui => ui.isAllFoldersVisible);
const getIsMobileDevice = createSelector(getUI, ui => ui.isMobileDevice);
const getExpandedFolders = folderType => createSelector(getUI, ui => ui.expandedFolders.filter(folder => folder.folderType === folderType).map(folder => folder.id));
const getSelectedItems = createSelector(getSelectionStatus, selectedItems => Object.keys(selectedItems).reduce((acc, selectedItemKey) => {
  if (selectedItems[selectedItemKey] === true) acc[selectedItemKey] = selectedItems[selectedItemKey];
  return acc;
}, {}));

const getSelectedItemIDs = createSelector(getSelectedItems, selectedItems => {
  return Object.keys(selectedItems).map(selectedItemKey => selectedItemKey.split('_')?.[0] || selectedItemKey);
});

export const getLastSelectedListingItemId = createSelector(getUI, ui => ui.lastSelectedListingItemId);
export const getShowMultipleSelectWithShiftToast = createSelector(getUI, ui => ui.showMultipleSelectWithShiftToast);

const getSelectedItemsWithInfo = createSelector(
  [getList, getSelectedItems],
  (items, selectedItems) => Object.keys(selectedItems)
    .map(selectedItemKey => {
      const separatedKey = selectedItemKey.split('_');
      const selectedItemID = separatedKey.length > 0 ? separatedKey[0] : selectedItemKey;
      const selectedItemAssetType = separatedKey.length > 1 ? separatedKey[1] : '';
      return findItemByIDAndType({ list: items, assetID: selectedItemID, assetType: selectedItemAssetType });
    })
    .filter(val => val)
);

const isAnyUnreadItemSelected = createSelector(getSelectedItemsWithInfo, selectedItems => Boolean(selectedItems.find(item => item.new > 0)));
const isAnyEnabledItemSelected = createSelector(getSelectedItemsWithInfo, selectedItems => Boolean(selectedItems.find(item => !item.isDisabled)));
const isAnyDisabledItemSelected = createSelector(getSelectedItemsWithInfo, selectedItems => Boolean(selectedItems.find(item => item.isDisabled)));
const isAnyArchivedItemSelected = createSelector(getSelectedItemsWithInfo, selectedItems => Boolean(selectedItems.find(item => item.archive === '1' || item.archived === true)));
const isAnyFavoriteItemSelected = createSelector(getSelectedItemsWithInfo, selectedItems => Boolean(selectedItems.find(item => item.favorite === '1' || item.favorited === true)));
const isAnyEncryptedFormSelected = createSelector(getSelectedItemsWithInfo, selectedItems => Boolean(selectedItems.find(item => item.type === 'LEGACY' && item.isEncrypted === true)));
const selectedFormAccessType = createSelector(getSelectedItemsWithInfo, selectedItems => selectedItems.map(item => item?.access_type));

const getCurrentPage = createSelector(getUI, ui => ui.currentPage);
const getAssetFilter = createSelector(getUI, ui => ui.assetFilter);

const getAssetFilterType = createSelector(getAssetFilter, assetFilter => {
  // when asset filter is a string
  // todo :: remove string assetFilter cases when the multi-select asset filter feature is enabled
  if (!isArray(assetFilter)) return assetFilter;

  // to handle multi-select asset filters
  return assetFilter.length === 1 ? assetFilter[0] : LISTING_TYPES.MIX;
});

const getIsMixStructureEnabled = createSelector(getUI, ui => ui.isMixStructureActive);
const getIsAssetFiltersDisabled = createSelector(getUI, ui => ui.disableAssetFilterDropdown);

const getIsMixAssetFilter = createSelector(getAssetFilterType, assetFilterType => MIX_FILTER_ENABLED_LISTING_TYPES.indexOf(assetFilterType) > -1);
const getIsMixEnabledPage = createSelector(getCurrentPage, currentPage => MIX_FILTER_ENABLED_LISTING_TYPES.indexOf(currentPage) > -1);

const hasMoveDisabledSelectedItems = createSelector([getAssetFilterType, getSelectedItemsWithInfo], (assetFilterType, selectedItems) => {
  if (!ENABLE_MOVE_AGENT_TO_TEAM && assetFilterType === LISTING_TYPES.AGENT) return true;

  if ([...MIX_FILTER_ENABLED_LISTING_TYPES, LISTING_TYPES.DOCUMENT].indexOf(assetFilterType) === -1) return false;

  const selectedDocuments = assetFilterType === LISTING_TYPES.DOCUMENT ? selectedItems : selectedItems.filter(item => item.assetType && ASSET_TYPE_MAP[item.assetType] === LISTING_TYPES.DOCUMENT);
  return Boolean(selectedDocuments.find(item => !!item?.countByStatus?.IN_PROGRESS));
});
const hasPurgeDisabledSelectedItems = createSelector([getAssetFilterType, getSelectedItemsWithInfo, getIsMixAssetFilter], (assetFilterType, selectedItems, isMixAssetFilter) => {
  return assetFilterType === LISTING_TYPES.DOCUMENT || (isMixAssetFilter && Boolean(selectedItems.find(item => item?.assetType === LISTING_TYPES_REVERT[LISTING_TYPES.DOCUMENT]))
  );
});
const getMobileViewFoldersVisibility = createSelector(getUI, ui => ui.isMobileViewFoldersVisible);
const getIsMobileApp = createSelector(getUI, ui => ui.isMobileApp);
const getUtmSourceSuffix = createSelector(getCurrentPage, currentPage => {
  switch (currentPage) {
    case LISTING_TYPES.FORM:
      return 'mfhb';
    case LISTING_TYPES.ASSIGNED_FORM:
      return 'assigned-forms-listings';
    case LISTING_TYPES.DRAFT:
      return 'draft-listings';
    case LISTING_TYPES.PORTAL:
      return 'app-listings';
    case LISTING_TYPES.DOCUMENT:
      return 'sign-listings';
    case LISTING_TYPES.REPORT:
      return 'report-listings';
    case LISTING_TYPES.UPLOAD:
      return 'upload-listings';
    case LISTING_TYPES.SHEET:
      return 'tables-listings';
    case LISTING_TYPES.TASK:
      return 'approval-listings';
    case LISTING_TYPES.TEAMS:
      return 'teams-listings';
    case LISTING_TYPES.MIX:
      return 'workspace-listings';
    default:
      return 'undefined-listings';
  }
});

const isFolderFeatureActive = createSelector([getAssetFilterType, isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT)], (assetFilterType, isAssetFolderActive) => {
  return ASSETS_FOLDER_LISTINGS.indexOf(assetFilterType) > -1 ? isAssetFolderActive : true;
});

const getTeamFolderMenuItems = createSelector([getAssetFilterType, isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT)], (assetFilterType, isAssetFolderActive) => {
  return [
    ASSET_CREATION_MENU_ITEM[isAssetFolderActive ? assetFilterType : LISTING_TYPES.FORM],
    ...teamFolderContextMenuItems
  ];
});

const getAllFolderMenuItems = createSelector([isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT)], isAssetFolderActive => {
  return getFolderContextMenuItems(isAssetFolderActive);
});

const getUser = state => state.user;
const getUserType = createSelector(getUser, user => user.type);
const getIsNewSheetsUser = createSelector(getUser, user => user.newSheets);
const getUserCredentials = createSelector(getUser, user => user.credentials);
const getUserPermissions = createSelector(getUser, user => user.permissions);
const getUserForms = createSelector(getUser, user => user.forms || []);
const getUserEmail = createSelector(getUserCredentials, credentials => credentials.email);
const getIsHipaa = createSelector(getUserCredentials, credentials => credentials.isHIPAA === '1');
const getUserCampaing = createSelector(getUserCredentials, ({ campaign = {} }) => campaign);
const getUserName = createSelector(getUserCredentials, credentials => credentials.username);
const getUserAllowDigest = createSelector(getUserCredentials, credentials => credentials.allowDigest);
const getUserLanguage = createSelector(getUserCredentials, credentials => credentials.language);
const getNewBrandingExipiration = createSelector(getUserCampaing, ({ expires = {} }) => expires);
const getAvailableCampaingList = createSelector(getUser, user => user.availableCampaingList || {});
const getUserLogAbTestAction = createSelector(getUser, user => user.logAbTestAction);
const getAssetsAvailability = createSelector(getUserCredentials, credentials => credentials?.campaign?.assetsAvailable);
const getUserAccountType = createSelector(getUserCredentials, ({ account_type: accountType = {} }) => accountType);
const getUserAccountTypeName = createSelector(getUserAccountType, ({ name }) => name);
const getUserIsAppFirst = createSelector(getUserCredentials, user => user.isAppFirstUser); // A/B Test: ctAppWizardStartFromBasic
const getAccountType = createSelector(getUserCredentials, credentials => {
  const userAccountType = credentials?.accountType || credentials?.account_type;
  return userAccountType && isObject(userAccountType) ? userAccountType.name : userAccountType;
});
const getUserReactivationData = createSelector(getUserCredentials, ({ reactivationWarning }) => reactivationWarning);
const getIsGuestUser = createSelector(getAccountType, accountType => accountType === 'GUEST');
const getNewUserCampaignExpirationDate = createSelector(getNewBrandingExipiration, expires => expires?.new_users_campaign);
const getNewUserCampaignAssetType = createSelector(getUserCampaing, (campaignData = {}) => (campaignData?.assetsVersion || ''));
const getNewUserCampaignAvailability = createSelector(getAvailableCampaingList, ({ newUserCampaign = false }) => newUserCampaign);
const getBlackFridayCampaignAvailability = createSelector(getAvailableCampaingList, ({ blackFriday = false }) => blackFriday);
const getEndOfYearCampaignAvailability = createSelector(getAvailableCampaingList, ({ endOfYearSaleMode = false }) => endOfYearSaleMode);
const getSummerSaleCampaignAvailability = createSelector(getAvailableCampaingList, ({ summerSaleMode = false }) => summerSaleMode);
const getNonprofitCampaignAvailability = createSelector(getAvailableCampaingList, () => window.location.href.includes('enableNonprofitCampaign=1'));
const getEducationCampaignAvailability = createSelector(getAvailableCampaingList, ({ educationSaleMode = false }) => educationSaleMode || window.location.href.includes('education=1')
|| window.location.href.includes('educationB=1') || window.location.href.includes('educationApp=1'));
const getOverquotaCampaignAvailability = createSelector(getAvailableCampaingList, ({ overQuotaCampaign = false }) => overQuotaCampaign);
const getOverquotaCampaignExpirationDate = createSelector(getNewBrandingExipiration, expires => expires?.over_quota_campaign);
const getBackToPaidCampaignAvailability = createSelector(getAvailableCampaingList, ({ backToPaidCampaign = false }) => backToPaidCampaign);
const getBackToPaidCampaignExpirationDate = createSelector(getNewBrandingExipiration, expires => expires?.back_to_paid_campaign);
const getSpringSaleCampaignAvailability = createSelector(getAvailableCampaingList, ({ springSaleCampaign = false }) => springSaleCampaign);
const getCampaignUtm = createSelector(getUserCampaing, (campaignData = {}) => (campaignData?.utm_campaign || ''));
const getLaunchAvailability = createSelector([getAvailableCampaingList, getAssetsAvailability], ({ productLaunch = false }, isAssetsAvailable) => {
  if (isAssetsAvailable === false) return false;
  return productLaunch || window?.location.href.includes('enterpriseLaunchAvailable=1');
});
const hasUserPermission = permissionName => createSelector(getUserPermissions, permissions => permissions.indexOf(permissionName) > -1);
const getUserSystemPlans = createSelector(getUser, user => user.systemPlans);
const getDataOnlyUser = createSelector(getUserCredentials, credentials => credentials.isDataOnlyUser === '1');
const getStoreRecommendation = createSelector(getUserCredentials, credentials => credentials.showStoreRecommendation);
const getAppRecommendation = createSelector(getUserCredentials, credentials => credentials.showRecommendation);
const getIsEnableNowButtonAvailable = createSelector(getUserCredentials, credentials => {
  return credentials.intentionalPublishNewUserABupdated === '22231' || credentials.intentionalPublishExistingUserABupdated === '22261'; // C Test Variant for both AB test
});
const getUserSystemPlansForLimitDialog = createSelector(
  getUserSystemPlans,
  systemPlans => [
    systemPlans.FREE,
    systemPlans.BRONZE,
    systemPlans.SILVER,
    systemPlans.GOLD
  ]
    .filter(val => val)
);

const canAccessMixedListing = createSelector(getUserCredentials, credentials => !!credentials.allowMixedListing);

const getVisibleAssetFilterOptions = createSelector([getUserCredentials, getAssetFilter, getCurrentPage], (user, assetFilter, currentPage) => {
  const baseArray = isArray(assetFilter) ? ASSET_FILTER_OPTIONS_WITHOUT_ALL : ASSET_FILTER_DROPDOWN_OPTIONS;
  return baseArray.filter(opt => !opt.checkVisibility || opt.checkVisibility({ user, canAccessMyAgentsPage: canAccessMyAgentsPage(user), currentPage }));
});

const getIsAllAssetFilterTypesSelected = createSelector([getAssetFilter, getVisibleAssetFilterOptions], (assetFilter, visibleAssetFilterOptions) => {
  return isArray(assetFilter) && assetFilter.length === visibleAssetFilterOptions.length;
});

const isFormUser = createSelector(getUserType, userType => userType && userType === 'FORM_USER');

const getFolder = state => state.folder;
const getFolders = createSelector(getFolder, folder => folder.folders);
const getFolderType = createSelector(getFolder, folder => folder.folderType);
const getSelectedFolder = createSelector(getFolder, folder => folder.selectedFolder);
const getFolderLayout = createSelector(getFolder, folder => folder.folderLayout);
const getFolderLayoutBackup = createSelector(getFolder, folder => folder.folderLayoutBackup);
const getFolderLayoutIDs = createSelector(getFolder, folder => folder.folderLayoutIDs);
const getIsReorderFoldersAllowed = createSelector(getFolder, folder => folder.isReorderFoldersAllowed);

const findFolderByID = folderID => (folders = []) => {
  const folder = flattenFolders(folders).find(({ id }) => folderID === id);
  return folder;
};
const getFlattenedFolders = createSelector(getFolders, flattenFolders);
const getFolderByID = id => createSelector(getFolders, findFolderByID(id));
const getSelectedFolderObject = createSelector([getFolders, getSelectedFolder], (folders, selectedFolderID) => findFolderByID(selectedFolderID)(folders));
const getFolderContextItems = createSelector([getCurrentPage, getAllFolderMenuItems, isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT)], (
  currentPage, allFolderMenuItems, isAssetFolderActive
) => {
  const items = allFolderMenuItems[currentPage] || [];
  return (ASSETS_FOLDER_LISTINGS.indexOf(currentPage) > -1 && !isAssetFolderActive) ? [] : items;
});

const getTitleSectionIndex = createSelector(getFolders, folderList => {
  return folderList.findIndex(({ isDefault }) => isDefault === true) + 1;
});

const getCreateButtonSectionIdx = createSelector(getFolders, folderList => {
  return folderList.findIndex(({ id }) => id === DEFAULT_FOLDER_IDS.CREATE);
});

const getOrderedUserFolderList = createSelector([getFolders, getFolderLayout, getTitleSectionIndex, getCreateButtonSectionIdx], (
  folderList, folderLayout, titleSectionIndex, createButtonSectionIdx
) => {
  const userFolderList = folderList.slice(titleSectionIndex, createButtonSectionIdx);
  const flattenedUserFolderList = flattenFolders(userFolderList || [], true);
  const rootFolderLayout = [...folderLayout.values()][0];
  return rootFolderLayout ? constructFolderListByLayout(rootFolderLayout, flattenedUserFolderList) : userFolderList;
});

const getVisibleFlattenedFolders = createSelector([getFolders, getFolderLayout, getFolderLayoutIDs, getTitleSectionIndex, getCreateButtonSectionIdx], (
  folderList, folderLayout, folderLayoutIDs, titleSectionIndex, createButtonSectionIdx
) => {
  const userFolderList = folderList.slice(titleSectionIndex, createButtonSectionIdx);
  const flattenedUserFolderList = flattenFolders(userFolderList || []);
  const rootFolderLayout = [...folderLayout.values()][0];
  return rootFolderLayout ? flattenedUserFolderList.filter(folder => folderLayoutIDs.indexOf(folder.id) > -1) : flattenedUserFolderList;
});

// eslint-disable-next-line max-statements
const getFolderGroups = createSelector([getFolders, getDataOnlyUser, getCurrentPage, getTitleSectionIndex, getCreateButtonSectionIdx, getOrderedUserFolderList, isFormUser], (
  folderList, isDataOnlyUser, currentPage, titleSectionIndex, createButtonSectionIdx, orderedUserFolderList, formUser
) => {
  switch (currentPage) {
    case LISTING_TYPES.TEAM_PAGE: {
      const seperatorIndex = folderList.findIndex(({ className }) => className === 'isSeperator');
      const teamItemGroup = makeFolderGroup(DEFAULT_FOLDER_IDS.TEAM_PAGE_ALL_FOLDER, folderList.slice(1, seperatorIndex + 1));
      const favoritesGroup = makeFolderGroup('forActions', folderList.slice(seperatorIndex + 1));
      return [teamItemGroup, favoritesGroup];
    }
    case LISTING_TYPES.TEAMS: {
      return [makeFolderGroup('main', folderList)]; // Experimental page for now
    }
    default:
  }
  let folderGroups = [];
  let dataOnlyUserGroups = [];

  const titleGroup = makeFolderGroup('forTitle', folderList.slice(0, formUser ? folderList.length : titleSectionIndex));
  folderGroups = [...folderGroups, titleGroup];
  const hasCreateFolderButtonSection = currentPage === LISTING_TYPES.TASK && folderGroups.find(folderGroup => folderGroup.subfolders?.find(subfolder => subfolder.id === DEFAULT_FOLDER_IDS.CREATE));

  if (!hasCreateFolderButtonSection && createButtonSectionIdx !== -1) {
    const userFolderGroup = makeFolderGroup(DEFAULT_FOLDER_IDS.USER_FOLDER_GROUP, orderedUserFolderList);
    const createFolderGroup = makeFolderGroup('forCreate', folderList.slice(createButtonSectionIdx, createButtonSectionIdx + 1));
    folderGroups = [...folderGroups, userFolderGroup, createFolderGroup];
  }
  const titleSeparator = folderList.find(({ id }) => id.toLowerCase().includes('separator') || id.toLowerCase().includes('seperator'));
  folderGroups[folderGroups.length - 1].subfolders = [...folderGroups[folderGroups.length - 1].subfolders, titleSeparator];
  const sharedSectionIndex = folderList.findIndex(({ id }) => id.includes('shared'));
  const assignedSectionIndex = folderList.findIndex(({ id }) => id.includes(DEFAULT_FOLDER_IDS.ASSIGNED));
  const favoritesSectionIndex = folderList.findIndex(({ id }) => id === DEFAULT_FOLDER_IDS.FAVORITES);
  const filledFormsSectionIndex = window.location.href.indexOf('filledforms=1') > -1 ? folderList.findIndex(({ id }) => id === DEFAULT_FOLDER_IDS.FILLED_FORMS) : -1;
  const teamsTitleIndex = folderList.findIndex(({ id }) => id === DEFAULT_FOLDER_IDS.TEAMS_TITLE);

  if (teamsTitleIndex !== -1) {
    const createTeamButtonIndex = folderList.findIndex(({ id }) => id === DEFAULT_FOLDER_IDS.CREATE_TEAM);
    const teamsSeperatorIndex = folderList.findIndex(({ id }) => id === DEFAULT_FOLDER_IDS.TEAMS_SEPERATOR);

    const teamsTitleGroup = makeFolderGroup('forTeamsTitle', folderList.slice(teamsTitleIndex, teamsTitleIndex + 1));
    const teamsGroup = makeFolderGroup(DEFAULT_FOLDER_IDS.TEAM_FOLDER_GROUP, folderList.slice(teamsTitleIndex + 1, createTeamButtonIndex));
    const createTeamButtonGroup = makeFolderGroup(DEFAULT_FOLDER_IDS.CREATE_TEAM_FOLDER, folderList.slice(createTeamButtonIndex, teamsSeperatorIndex + 1));
    dataOnlyUserGroups = teamsGroup.subfolders?.length > 0 ? [teamsTitleGroup, teamsGroup, createTeamButtonGroup] : [];
    folderGroups = [...folderGroups, teamsTitleGroup, teamsGroup, createTeamButtonGroup];
  }

  const sharedTitleGroup = makeFolderGroup('forTitleShared', folderList.slice(sharedSectionIndex, sharedSectionIndex + 1));
  // To get assigned section's top separator we did assignedSectionIndex - 1
  const sharedGroup = makeFolderGroup('forSharedFolders', folderList.slice(sharedSectionIndex + 1, assignedSectionIndex === -1 ? favoritesSectionIndex : assignedSectionIndex - 1));
  const assignedGroup = makeFolderGroup('forAssigned', folderList.slice(assignedSectionIndex - 1, filledFormsSectionIndex === -1 ? favoritesSectionIndex - 2 : filledFormsSectionIndex));
  const filledFormsGroup = makeFolderGroup('forFilledForms', folderList.slice(filledFormsSectionIndex, favoritesSectionIndex === -1 ? filledFormsSectionIndex + 1 : favoritesSectionIndex));
  const favoritesGroup = makeFolderGroup('forActions', folderList.slice(favoritesSectionIndex));
  folderGroups = [
    ...folderGroups,
    ...formUser ? [] : [sharedTitleGroup],
    ...(sharedSectionIndex === -1 ? [] : [sharedGroup]),
    ...(assignedSectionIndex === -1 ? [] : [assignedGroup]),
    ...(filledFormsSectionIndex === -1 ? [] : [filledFormsGroup]),
    ...(favoritesSectionIndex === -1 ? [] : [favoritesGroup])
  ];

  if (isDataOnlyUser) {
    return [...dataOnlyUserGroups, sharedTitleGroup, sharedGroup];
  }
  return folderGroups.filter(f => f);
});

const getFolderListToMap = createSelector([getFolderGroups, getFolders, getCurrentPage], (folderGroups, folders, currentPage) => {
  if (folderGroups.length > 1) return folderGroups;

  const hiddenFolders = [];
  const requiredFolders = [];
  if (currentPage === LISTING_TYPES.SHEET) {
    if (!hasUserPermission(ALL_PERMISSIONS.CREATE_FORM)) {
      hiddenFolders.push(ALL_ASSETS_ID[LISTING_TYPES.SHEET]);
      requiredFolders.push(DEFAULT_FOLDER_IDS.SHARED_SHEETS);
    }
    if (!hasUserPermission(ALL_PERMISSIONS.EDIT_FORM)) {
      hiddenFolders.push(DEFAULT_FOLDER_IDS.FAVORITES, DEFAULT_FOLDER_IDS.ARCHIVE, DEFAULT_FOLDER_IDS.TRASH);
    }
  }

  return [{ id: 'main', subfolders: folders.filter(folder => hiddenFolders.indexOf(folder.id) === -1 && (folder.count !== 0 || requiredFolders.indexOf(folder.id) > -1)) }];
});

const getRootFolderID = createSelector(getFolderLayout, folderLayout => Array.from(folderLayout?.keys())[0]);

const canViewToolbarActions = createSelector(getDataOnlyUser, getSelectedItems, getSelectedFolder, (isDataOnlyUser, selectedItemsObj, selectedFolder) => {
  const hasSelected = Object.keys(selectedItemsObj).length !== 0;
  const shouldRenderBtnList = !isDataOnlyUser && hasSelected && !BULK_ACTION_RESTRICTEDS.includes(selectedFolder);
  return shouldRenderBtnList;
});

const isSharedWithMeFolder = createSelector(getFolders, getSelectedFolder, getUserName, (folders, selectedFolderID = '', username = '') => {
  if (selectedFolderID === DEFAULT_FOLDER_IDS.SHARED_WITH_ME) {
    return true;
  }
  const selectedFolderObj = flattenFolders(folders).find(({ id }) => id === selectedFolderID);
  const isOwnerOfFolder = selectedFolderObj?.owner === username;
  if (!isOwnerOfFolder && selectedFolderObj?.isShared) {
    return true;
  }
  return false;
});

const isOwnerOfSelectedFolder = createSelector(getFolders, getSelectedFolder, getUserName, (folders, selectedFolderID = '', username = '') => {
  const specialOwnedFolders = [
    ...STATUS_FOLDERS,
    DEFAULT_FOLDER_IDS.ASSIGNED,
    DEFAULT_FOLDER_IDS.DRAFTS,
    DEFAULT_FOLDER_IDS.SHARED_WITH_ME,
    ALL_ASSETS_ID[LISTING_TYPES.FORM],
    ALL_ASSETS_ID[LISTING_TYPES.MIX]
  ];
  if (specialOwnedFolders.includes(selectedFolderID)) {
    return true;
  }
  const selectedFolderObj = flattenFolders(folders).find(({ id }) => id === selectedFolderID);
  const isOwnerOfFolder = selectedFolderObj?.owner === username;
  if (!isOwnerOfFolder && selectedFolderObj?.isShared) {
    return true;
  }
  if (!isOwnerOfFolder || selectedFolderID?.toLowerCase().includes('share')) {
    return false;
  }
  return true;
});

const getShareRelatedFolders = createSelector(getFolders, getUserName, (folders, username) => {
  return folders.filter(folder => {
    const { owner, username: folderUsername, id } = folder;
    const genericOwner = owner || folderUsername;
    if ((genericOwner && genericOwner !== username) || id?.toLowerCase?.().includes('share')) return true;
    return false;
  });
});

const getTeamFolders = createSelector(getFolders, folders => {
  return folders.filter(folder => folder.folderType === FOLDER_TYPES.TEAM);
});

const getTeamRelatedFolders = createSelector([getFolders, getTeamFolders], (folders, teamFolders) => {
  const allTeamsSectionIndex = folders.findIndex(({ id }) => id === DEFAULT_FOLDER_IDS.TEAMS_TITLE);
  const teamsSeperatorIndex = folders.findIndex(({ id }) => id === DEFAULT_FOLDER_IDS.TEAMS_SEPERATOR);

  if (teamFolders.length === 0 || allTeamsSectionIndex === -1 || !teamsSeperatorIndex === -1) return [];

  return folders.slice(allTeamsSectionIndex, teamsSeperatorIndex + 1);
});

const getTeam = state => state.team;
const getTeamRoles = createSelector(getTeam, team => team.teamRoles);
const getServerTeamSettings = createSelector(getTeam, team => team.serverTeamSettings);
const getIsTeamCreationDisabled = createSelector([getServerTeamSettings, isFormUser], (serverTeamSettings, formUser) => {
  if (formUser === true) {
    return true;
  }
  return isServerTeamSettingsEnabled() ? serverTeamSettings.teamCreation !== '1' : false;
});
const getIsTeamBrowsingDisabled = createSelector([getServerTeamSettings, isFormUser], (serverTeamSettings, formUser) => {
  if (formUser === true) {
    return true;
  }
  return isServerTeamSettingsEnabled() ? serverTeamSettings.teamBrowsing !== '1' : true;
});
const getIsTeamCreateButtonInvisible = createSelector(
  [getIsTeamCreationDisabled, getAccountType, isFormUser],
  (isTeamCreationDisabled, accountType, formUser) => {
    if (formUser === true) {
      return true;
    }
    return !canCreateTeam(isTeamCreationDisabled, accountType);
  }
);
const getTeams = createSelector(getTeam, team => team.teams);
const getIsPreventFetchingMoveJobs = createSelector(getTeam, team => team.preventFetchingMoveJobs);
const findTeamByID = (id = '') => createSelector(getTeams, (teams = []) => teams.find(team => team.id === id));
const getCurrentTeamID = createSelector(getTeam, team => team.currentTeamID);
const getCurrentTeam = createSelector([getCurrentTeamID, getTeams], (currentTeamID = '', teams = []) => teams.find(team => team.id === currentTeamID));
const getIsTeamsFetched = createSelector(getTeam, team => team.isTeamsFetched);
const getCurrentTeamMembers = createSelector(getCurrentTeam, team => team?.members || []);
const getUserRolesForTeam = createSelector(getCurrentTeam, team => team?.userRoles || []);
const getInitialFolderID = (folderList, defaultFolder, getFirstTeam) => createSelector([getCurrentPage, getCurrentTeamID, getCurrentTeam], (currentPage, currentTeamID, currentTeam) => {
  const folderFromURL = new URLSearchParams(window.location.search).get('selectedFolder');
  if (folderFromURL && folderList.some(({ id }) => id === folderFromURL)) return folderFromURL;

  const lastSelectedFolder = currentPage === LISTING_TYPES.TEAM_PAGE && currentTeamID && !currentTeam?.subfolders?.length ? currentTeamID : getLastSelectedFolder(currentPage);
  if (currentPage === LISTING_TYPES.TEAM_PAGE && (!lastSelectedFolder || lastSelectedFolder === 'initialTeamFolder' || !(folderList.find(({ id }) => id === lastSelectedFolder)))) return currentTeamID;
  if (lastSelectedFolder && folderList.some(({ id }) => id === lastSelectedFolder)) return lastSelectedFolder;

  if (!getFirstTeam) {
    return defaultFolder;
  }
  // Select the first team if exists
  const { id } = folderList.find(({ folderType }) => folderType === FOLDER_TYPES.TEAM) || {};
  return id || defaultFolder;
});
const getTeamPermissions = createSelector([getUser, getCurrentTeamID], (user, currentTeamID) => ((currentTeamID && user?.teamPermissions) ? user.teamPermissions : {}));
const getTeamPermissionFetcher = createSelector([getTeamPermissions, getCurrentTeamID], (teamPermissions, currentTeamID) => {
  return (permissionName, valueOnNonTeamContext = false) => {
    if (!currentTeamID) return valueOnNonTeamContext;
    return Boolean(teamPermissions[permissionName]);
  };
});
const getTeamPermissionsByID = (id = '') => createSelector([getCurrentTeamID, getTeamPermissions, findTeamByID(id)], (currentTeamID, currentTeamPermissions, team) => {
  if (id === currentTeamID) return currentTeamPermissions;

  return team?.permissions || {};
});
const getTeamFilter = createSelector([getUI, getCurrentPage, getCurrentTeamID], (ui, currentPage, currentTeamID) => (currentPage === LISTING_TYPES.TEAM_PAGE ? currentTeamID : ui.teamFilter));
const isTeamPage = createSelector([getCurrentPage], currentPage => currentPage === LISTING_TYPES.TEAM_PAGE);
const getIsTeamMember = createSelector([getTeam, getCurrentPage, getCurrentTeam, getUserName], (team, currentPage, currentTeam = {}, username = '') => {
  if (currentPage === LISTING_TYPES.TEAM_PAGE) return team.isTeamMember;

  return !!currentTeam?.members?.find(member => member.username === username);
});
const getSelectedItemsListingType = createSelector([getSelectedItemsWithInfo, getAssetFilterType, getIsMixAssetFilter], (selectedItems, assetFilterType, isMixAssetFilter) => {
  if (!isMixAssetFilter || selectedItems.length === 0) {
    return assetFilterType;
  }
  const allSame = selectedItems.every(item => item.assetType === selectedItems[0].assetType);
  if (allSame) return ASSET_TYPE_MAP[selectedItems[0].assetType];
  return assetFilterType;
});
const getShowNoAccessToTeamView = createSelector([getCurrentPage, getTeam], (currentPage, team) => currentPage === LISTING_TYPES.TEAM_PAGE && team?.showNoAccessView);
const getShowTeamNotFoundView = createSelector([getCurrentPage, getTeam], (currentPage, team) => currentPage === LISTING_TYPES.TEAM_PAGE && team?.showTeamNotFoundView);
const getTeamNotificationCount = createSelector([getTeam], team => team.teamNotificationCount || 0);
const getIsTeamFolder = createSelector([getFolderType], folderType => [FOLDER_TYPES.TEAM, FOLDER_TYPES.ALL_TEAM_RESOURCES].includes(folderType));
const getLastActivityLogForItem = (id, type) => createSelector([getItemByIDAndType(id, type), getUserName, getCurrentPage, getCurrentTeamID], (item, sessionUsername, currentPage, currentTeamID) => {
  if (!item) return '';

  const {
    createdBy, deletedBy, updatedBy, created_at: createdAt, updated_at: updatedAt, assetType
  } = item;

  if (Object.keys(ASSET_TYPE_MAP).indexOf(assetType) === -1 || (currentPage === LISTING_TYPES.MIX && !currentTeamID)) {
    // It is not a team asset
    return '';
  }

  const infoConfig = [
    { logOwner: createdBy, date: createdAt, action: 'Created' },
    { logOwner: deletedBy, date: updatedAt, action: 'Deleted' },
    { logOwner: updatedBy, date: updatedAt, action: 'Last edited' }
  ];

  const userLanguage = useSelector(getUserLanguage);
  // Return the first info that is found on item (according to above order)
  for (let i = 0; i < infoConfig.length; i++) {
    const {
      logOwner: { username: logOwnerUsername, name: logOwnerName } = {},
      date,
      action
    } = infoConfig[i] || {};

    if (logOwnerUsername) {
      let text;
      if (logOwnerUsername === sessionUsername) {
        text = translationRenderer(`${action} on [1[{date}]] by me`)({
          renderer1: () => <span>{prettyDate(date, userLanguage)}</span>
        });
      } else {
        text = translationRenderer(`${action} on [1[{date}]] by [2[{user}]]`)({
          renderer1: () => <span>{prettyDate(date, userLanguage)}</span>,
          renderer2: () => <span>{logOwnerName || logOwnerUsername}</span>
        });
      }
      return text;
    }
  }
  return '';
});

const getIsReorderTeamsAllowed = createSelector([getTeam], team => team.isReorderTeamsAllowed);

const getMovingJobs = createSelector(getListState, listState => listState.movingJobs);

const getSelectedFolderIDForCreation = createSelector([getSelectedFolderObject, getCurrentTeamID], (selectedFolder, currentTeamID) => {
  const { id, isShared } = selectedFolder || {};
  if (!id) return '';

  return (!!isShared || ASSET_CREATION_DISABLED_FOLDERS.indexOf(id) > -1 || (currentTeamID && id === currentTeamID)) ? '' : id;
});

const getUserAllowBoards = createSelector(getUserCredentials, credentials => credentials.allowBoards);

const getUserAllowAIAgent = createSelector(getUserCredentials, credentials => credentials.allowAIAgentFormFiller);
const getUserBetaAcceptedAIAgent = createSelector(getUserCredentials, credentials => credentials.AIAgentBetaAccepted === '1');

const getIsNewAppPickerActive = createSelector([getUserCredentials, isTeamPage, getCurrentPage], (credentials, isTeamWorkspace, currentPage) => {
  return currentPage === LISTING_TYPES.MIX || (!isTeamWorkspace && credentials?.mixedListingBetaUser === '1' && currentPage !== LISTING_TYPES.AGENT);
});

const getIsMultipleAssetFilterVisible = createSelector([
  getIsAssetFiltersDisabled, getIsMixStructureEnabled, getIsMixEnabledPage, getCurrentPage
], (isAssetFiltersDisabled, isMixStructureEnabled, isMixEnabledPage, currentPage) => {
  const isPageEnabled = isMixStructureEnabled ? isMixEnabledPage : (currentPage === LISTING_TYPES.TEAM_PAGE && showAssetFilterOnTeamPage());
  return !isAssetFiltersDisabled && isPageEnabled;
});

const getIsResetAssetFilterVisible = createSelector([getIsMultipleAssetFilterVisible, getAssetFilter], (isMultipleAssetFilterVisible, assetFilter) => {
  return isMultipleAssetFilterVisible && isArray(assetFilter) && assetFilter.length === 0;
});

const getIsEmbedMainStepTestUser = createSelector(getUser, user => user.isEmbedMainStepTestUser);

const getIsCreationModalTestUser = createSelector(getUser, user => user.isCreationModalTestUser);

const getFetchedUserForms = createSelector(getUser, user => user.formsWithProperties);

const getFetchedTeamForms = createSelector(getUser, user => user.teamFormsWithProperties);

export const SELECTORS = {
  getList,
  getIsListInitialState,
  getItemByID,
  getItemsByList,
  getBaseList,
  getTotalListCount,
  getListCount,
  getDeletedItems,
  getIsPreventFetchingMoveJobs,
  getNewCreatedItems,
  getFilter,
  getIsFilterSet,
  getFilterID,
  getFilterForm,
  getOrderBy,
  getFolders,
  getFolderType,
  getIsMobileApp,
  isAllSelected,
  getSelectionStatus,
  getLastOpenedInboxPanelId,
  getIsInboxPanelOpen,
  getIsTeamActivityPanelOpen,
  getSelectedItems,
  getLastSelectedListingItemId,
  hasMoveDisabledSelectedItems,
  getShowMultipleSelectWithShiftToast,
  isAnyUnreadItemSelected,
  isAnyEnabledItemSelected,
  isAnyDisabledItemSelected,
  isAnyFavoriteItemSelected,
  isAnyArchivedItemSelected,
  isAnyEncryptedFormSelected,
  getCurrentPage,
  getAssetFilter,
  getAssetFilterType,
  getIsMixStructureEnabled,
  getSelectedFolder,
  getFeatures,
  isActiveFeature,
  getUser,
  getUserForms,
  getUserIsAppFirst,
  getUserSystemPlans,
  getUserSystemPlansForLimitDialog,
  getUserAccountTypeName,
  getUserAllowDigest,
  getNewUserCampaignAvailability,
  getBlackFridayCampaignAvailability,
  getEndOfYearCampaignAvailability,
  getSummerSaleCampaignAvailability,
  getNonprofitCampaignAvailability,
  getEducationCampaignAvailability,
  getOverquotaCampaignAvailability,
  getOverquotaCampaignExpirationDate,
  getBackToPaidCampaignAvailability,
  getBackToPaidCampaignExpirationDate,
  getSpringSaleCampaignAvailability,
  getCampaignUtm,
  getNewUserCampaignExpirationDate,
  getNewUserCampaignAssetType,
  getLaunchAvailability,
  getUserCredentials,
  getIsNewAppPickerActive,
  getUserLogAbTestAction,
  getUserEmail,
  getIsHipaa,
  getUserPermissions,
  getIsNewSheetsUser,
  getMobileViewFoldersVisibility,
  getFolderByID,
  getFolderContextItems,
  getFolderLayout,
  getFolderLayoutBackup,
  getFlattenedFolders,
  getUtmSourceSuffix,
  canAccessMixedListing,
  getUserName,
  getDataOnlyUser,
  getStoreRecommendation,
  getAppRecommendation,
  getIsEnableNowButtonAvailable,
  canViewToolbarActions,
  getRootFolderID,
  getUserType,
  isOwnerOfSelectedFolder,
  getSelectedFolderObject,
  getShareRelatedFolders,
  getTeamFolders,
  isSharedWithMeFolder,
  getSelectedItemsWithInfo,
  selectedFormAccessType,
  getTeam,
  getTeamRoles,
  getTeams,
  findTeamByID,
  getCurrentTeamID,
  getCurrentTeam,
  getCurrentTeamMembers,
  getUserRolesForTeam,
  getInitialFolderID,
  getTeamPermissions,
  getTeamPermissionFetcher,
  getTeamFilter,
  isTeamPage,
  getIsTeamMember,
  getSelectedItemsListingType,
  getShowNoAccessToTeamView,
  getShowTeamNotFoundView,
  getIsAddToFolderMenuVisible,
  getIsMoveToTeamMenuVisible,
  getTeamNotificationCount,
  getIsTeamFolder,
  getLastActivityLogForItem,
  getTeamRelatedFolders,
  getIsTeamCreationDisabled,
  getIsTeamBrowsingDisabled,
  getIsTeamCreateButtonInvisible,
  getServerTeamSettings,
  getMovingJobs,
  getIsGuestUser,
  getAccountType,
  getSelectedFolderIDForCreation,
  isFormUser,
  getUserLanguage,
  getUserReactivationData,
  getIsReorderTeamsAllowed,
  getIsReorderFoldersAllowed,
  isFolderFeatureActive,
  getTeamFolderMenuItems,
  getAllFolderMenuItems,
  getTeamPermissionsByID,
  getIsAllFoldersVisible,
  getIsMobileDevice,
  getIsTeamsFetched,
  getExpandedFolders,
  getFolderListToMap,
  getVisibleFlattenedFolders,
  hasPurgeDisabledSelectedItems,
  getIsMixAssetFilter,
  getIsMixEnabledPage,
  getIsAssetFiltersDisabled,
  getIsAllAssetFilterTypesSelected,
  getVisibleAssetFilterOptions,
  getUserAllowBoards,
  getIsMultipleAssetFilterVisible,
  getIsResetAssetFilterVisible,
  getUserAllowAIAgent,
  getUserBetaAcceptedAIAgent,
  getIsEmbedMainStepTestUser,
  getSelectedItemIDs,
  getItemByIDAndType,
  getGeneratedShareByAssetType,
  getIsCreationModalTestUser,
  getFetchedUserForms,
  getFetchedTeamForms
};
