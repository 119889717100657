import React, { useEffect, useState } from 'react';
import { ABTestManager } from '@jotforminc/abtest-manager';
import { useSelector } from 'react-redux';
import { t, translationRenderer } from '@jotforminc/translation';
import { handleCustomNavigation, getUrlParameter } from '@jotforminc/utils';
import { Hooks } from '@jotforminc/uikit';
import { ListingsLimitWarnings } from '@jotforminc/limit-assets';
import { IconExclamationTriangleFilled } from '@jotforminc/svg-icons';
import { StorageHelper } from '@jotforminc/storage-helper';
import { SELECTORS } from '../../store/selectors';
import { LISTING_TYPES } from '../../constants';
import { setLocalStorageWithExpiry, isProduction } from '../../utils';

const LimitWarnings = () => {
  const user = useSelector(SELECTORS.getUser);
  const currentPage = useSelector(SELECTORS.getCurrentPage);

  const getCurrentListingUTMSource = () => {
    switch (currentPage) {
      case LISTING_TYPES.PORTAL:
        return 'myapps';
      default:
        return 'myforms';
    }
  };

  const username = user?.credentials?.username;
  let messageHeader = '';
  let messageContent = '';
  let limitType = '';
  let estimationDate = '';

  const HEADER_DANGER = 'You have reached [1[{messageContent}]] limit.';
  const HEADER_WARNING = 'You have almost reached [1[{messageContent}]] limit.';
  const HEADER_ESTIMATION = 'You will likely reach your [1[{messageContent}]] limit by [2[{estimationDate}]].';

  const [LimitWarningsArr, setLimitWarningsArr] = useState(null);
  const [isTestVariant, setIsTestVariant] = useState(false);

  const handleListingsShowFormCountLimitABTest = async () => {
    // A/B Test :: listingsShowFormCountLimit - Waiting analysis.
    const listingsShowFormCountLimitABTest = new ABTestManager({
      user,
      isTestEnabled: false,
      testName: 'listingsShowFormCountLimit',
      controlVariantCode: '10031',
      testVariantCode: '10041',
      cacheVariantCodeAtLocalStorage: true
    });

    const { userLimitWarning: { formCount = {} } = {} } = window;

    if (formCount?.display !== 'hide' && formCount?.message && formCount?.data?.accountLimit) {
      try {
        const showFormCount = await listingsShowFormCountLimitABTest.isTestVariant();
        setIsTestVariant(showFormCount);
      } catch (error) {
        console.log(`${listingsShowFormCountLimitABTest.testName} :: Cannot get test variant`, error);
        setIsTestVariant(false);
      }
    }
  };

  useEffect(() => {
    handleListingsShowFormCountLimitABTest();
  }, []);

  const handleAlignmentABTest = async () => {
    if (window.innerWidth <= 768) {
      if (getUrlParameter('lwma') === '1') {
        window.limitWarningAlignment = 'middle_alt';
        return;
      }

      const alignmentABTest = new ABTestManager({
        user,
        isTestEnabled: true,
        testName: 'listingsLimitWarningsMobileAlignment',
        controlVariantCode: '16261',
        testVariantCode: '16271',
        cacheVariantCodeAtLocalStorage: true,
        urlParam: 'lwma'
      });

      try {
        const alignment = await alignmentABTest.isTestVariant();
        if (alignment) {
          window.limitWarningAlignment = 'middle_alt';
        }
      } catch (error) {
        console.log(`${alignmentABTest.testName} :: Cannot get test variant`, error);
      }
    }
  };

  useEffect(() => {
    handleAlignmentABTest();
  }, []);

  useEffect(() => {
    if (username) {
      const {
        userLimitWarning: {
          submissions, payments, signedDocuments, workflowRuns, tickets, total_submissions: totalSubmission, uploads, views, formCount
        }
      } = window;

      const tempArr = [submissions, payments, signedDocuments, workflowRuns, tickets, totalSubmission, uploads, views];
      setLimitWarningsArr(isTestVariant ? [...tempArr, formCount] : tempArr);
    }
  }, [username]);

  let _currentLimit = null;
  if (LimitWarningsArr) {
    const limitWarning = LimitWarningsArr
      .filter(limit => limit && limit.display !== 'hide')
      .sort((a, b) => b.data.percent - a.data.percent)
      .find(limit => limit.message && limit.data?.accountLimit);

    if (limitWarning) {
      _currentLimit = limitWarning;
      if (_currentLimit?.color === 'red') {
        messageHeader = HEADER_DANGER;
        limitType = 'full';
      }
      if (_currentLimit?.color === 'orange') {
        messageHeader = HEADER_WARNING;
        limitType = 'almostFull';
      }
      if (_currentLimit?.color === 'yellow') {
        messageHeader = HEADER_ESTIMATION;
        limitType = 'estimation';
        estimationDate = _currentLimit?.estimationDate;
      }
      messageContent = _currentLimit?.message;
    }
  }

  const filterCurrentLimit = () => {
    if (!_currentLimit) return _currentLimit;

    // You can create your visibility condition as listing specific
    const FILTER = {
      [LISTING_TYPES.PORTAL]: {
        conditionChecker: () => {
          return _currentLimit?.reason === 'payments';
        }
      }
    };

    return FILTER[currentPage] ? (FILTER[currentPage].conditionChecker() && _currentLimit) : _currentLimit;
  };

  const currentLimit = filterCurrentLimit();

  // eslint-disable-next-line max-len
  const link = `/pricing/?utm_source=${getCurrentListingUTMSource()}_warnings${window.isLimitWarningsNewDesign ? '_v2' : ''}&utm_medium=banner&utm_term=${currentLimit?.term}&utm_content=${currentLimit?.content}&utm_campaign=${currentLimit?.campaign}`;

  Hooks.useEffectIgnoreFirst(() => {
    if (window?.JotFormActions && limitType && isProduction()) {
      const isLimitWarningActionSent = StorageHelper.getLocalStorageItem({ key: `limitWarningSeen_${limitType}` });

      if (isLimitWarningActionSent) return;

      const params = {
        actor: username,
        action: `${limitType}_${currentLimit?.reason}`,
        target: currentLimit?.term
      };

      window?.JotFormActions(`${getCurrentListingUTMSource()}_warnings${window.isLimitWarningsNewDesign ? '_v2' : ''}`)?.tick(params);

      const ttl = 1000 * 60 * 60 * 24;
      setLocalStorageWithExpiry({
        key: `limitWarningSeen_${limitType}`, value: '1', ttl: ttl
      });
    }
  }, [limitType]);

  const prepareLeftAlignmentHTML = () => {
    return (
      <div className="lsApp-sidebar-warning">
        <div className={`sidebar-upgrade-wrapper ${currentLimit?.color} ${currentLimit?.display}`}>
          <p>
            {limitType === 'estimation' ? (
              <>
                {translationRenderer(messageHeader)({
                  renderer1: () => (<b>{t(messageContent)}</b>),
                  renderer2: () => (<b>{t(estimationDate)}</b>)
                })}
              </>
            ) : (
              <>
                {translationRenderer(messageHeader)({
                  renderer1: () => (<b>{t(messageContent)}</b>)
                })}
              </>
            )}
          </p>
          <button
            id="upgradeNow" type="button" className="upgrade-now locale"
            data-testid="upgradeNowButton-left"
            data-campaign={`${currentLimit?.campaign}`}
            data-content={`${currentLimit?.content}`}
            data-term={`${currentLimit?.term}`}
            onClick={() => handleCustomNavigation(link, '_self')}

          >
            {t('Upgrade Now')}
          </button>
        </div>
      </div>
    );
  };

  const prepareMiddleAlignmentHTML = () => {
    return (
      <div className={`infobox-wrapper ${currentLimit?.display}`}>
        <p className={`infobox ${currentLimit?.color}`}>
          <div className="infobox-icon">
            <IconExclamationTriangleFilled />
          </div>
          <span>
            {limitType === 'estimation' ? (
              <>
                {translationRenderer(messageHeader)({
                  renderer1: () => (<b>{t(messageContent)}</b>),
                  renderer2: () => (<b>{t(estimationDate)}</b>)
                })}
              </>
            ) : (
              <>
                {translationRenderer(messageHeader)({
                  renderer1: () => (<b>{t(messageContent)}</b>)
                })}
              </>
            )}
          </span>
          <span className='locale'>
            {'\u00A0\u00A0'}
            <a
              id="upgradeNow"
              data-campaign={`${currentLimit?.campaign}`}
              data-content={`${currentLimit?.content}`}
              data-term={`${currentLimit?.term}`}
              target="_self"
              href={`../pricing/?utm_source=myforms_warnings&utm_medium=banner&utm_term=${currentLimit?.term}&utm_content=${currentLimit?.content}&utm_campaign=${currentLimit?.campaign}`}
              onClick={e => {
                e.preventDefault();
                handleCustomNavigation(
                  `/pricing/?utm_source=myforms_warnings&utm_medium=banner&utm_term=${currentLimit?.term}&utm_content=${currentLimit?.content}&utm_campaign=${currentLimit?.campaign}`,
                  '_self'
                );
              }}
            >
              <span
                id="upgradeNow"
                type="button"
                data-testid="upgradeNowButton-middle"
                className="upgrade-now locale"
              >
                {t('Upgrade Now')}
              </span>
            </a>
          </span>
        </p>
      </div>
    );
  };

  if (window.isLimitWarningsNewDesign) {
    return (
      <ListingsLimitWarnings
        currentLimit={currentLimit}
        limitType={limitType}
        messageHeader={messageHeader}
        messageContent={messageContent}
        estimationDate={estimationDate}
        handleCustomNavigation={handleCustomNavigation}
        link={link}
        alignment={window.limitWarningAlignment}
        username={username}
      />
    );
  }

  const aligmentType = () => {
    if (window.limitWarningAlignment === 'middle_alt') {
      return prepareMiddleAlignmentHTML();
    } if (window.limitWarningAlignment === 'left_alt') {
      return prepareLeftAlignmentHTML();
    }
  };

  return (
    user && currentLimit && aligmentType()
  );
};

export default LimitWarnings;
