import React from 'react';
import { func, node } from 'prop-types';
import { t } from '@jotforminc/translation';
import { ALL_TEXTS } from '@jotforminc/enterprise-promotions-utils';
import CloseIcon from '../../../../assets/svg/close_revised.svg';

export const DialogRenderer = ({ children }) => (<div className="jfBasicModal lunchAndLearnModal p-0 sm:p-4">{children}</div>);

DialogRenderer.propTypes = { children: node };
DialogRenderer.defaultProps = { children: null };

export const ContentRenderer = ({ children, ...props }) => (
  <div {...props} className="jfBasicModal-content-wrapper">
    <div className="jfBasicModal-content esw-content relative bg-white shadow-lg sm:radius-lg sm:overflow-auto radius-md">
      {children}
    </div>
  </div>
);

ContentRenderer.propTypes = { children: node };
ContentRenderer.defaultProps = { children: null };

export const HeaderRenderer = ({ onCloseClick, ...props }) => (
  <button
    {...props}
    type="button"
    className="
      cursor-pointer z-1 absolute right-3 top-3 flex items-center justify-center w-8 h-8 radius-full
      duration-300 bg-navy-75 hover:bg-navy-100 focus:bg-navy-75 focus:outline-2 outline-solid outline-navy-50 outline-opacity-50 border-0
    "
    aria-label={t(ALL_TEXTS.CLOSE_MODAL)}
    onClick={onCloseClick}
  >
    <CloseIcon className="w-4 h-auto" />
  </button>
);

HeaderRenderer.propTypes = { onCloseClick: func };
HeaderRenderer.defaultProps = { onCloseClick: f => f };
